import React, {useEffect, useState, useCallback,useRef} from "react";
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { Row, Col } from "react-bootstrap";
import Autocomplete from "react-google-autocomplete";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import postPicture from "../../../assets/PostPicture.png"

import { UsedAppNBCContext } from "../../AppNBC.Provider";
import useFetchWithMsal from "../../hooks/useFetchWithMsal";
import { protectedEndPointAPI, protectedVariable } from "../../Helpers/ProtectedCustomClass";

const AzurePostModal = (props) => {

    const appNBCContext = UsedAppNBCContext();
    // eslint-disable-next-line no-unused-vars
    const { error, execute, executeFormData, executeBlob } = useFetchWithMsal();

    const { setOpenCreatePost, reloadData, setIsAfterCreatedPost } = props;
    const { register, handleSubmit, setValue, formState: { errors }, setError, clearErrors} = useForm({
        mode: 'onChange'
    });

    const { id } = useParams();
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState([]);
    const [listCategories, setListCategories] = useState([]);

    const [incident, setIncident] = useState({
        id:0,
        category: 1,
        locationinput: "",
        onlyagency: false,
        draft: false,
        addressId: "-"
    });

    const [images, setImages] = useState([]);
    const [imagesError, setImageError] = useState(false);
    const [imagesSaved, setImagesSaved] = useState([]);
    const [filesSaved, setFilesSaved] = useState([]);
    const [location, setLocation] = useState({
    });

    const [isChangeLocation, setIsChangeLocation] = useState(false);
    const [isKeyPressEnter,setIsKeyPressEnter] = useState(false);
    const [isChangeCategory, setIsChangeCategory] = useState(false);

    const [isInitialLoadDone, setIsInitialLoadDone] = useState(false);

    const uploadInput = useRef(null);
    const cleanData = () => {
        setIncident({
            id: 0,
            category: 1,
            locationinput: "",
            onlyagency: false,
            draft: false,
            addressId: "-"
        });
        setLocation({});
        setImages([]);
    }

    const onChangeLocation = e => {
        setLocation({
            ...location, e
        });
        setValue('locationinput', e.formatted_address);
        setIsChangeLocation(true);
        clearErrors("locationinput");
    }

    const onChangeCategory = e => {
        setIsChangeCategory(true);
        setIncident({
            ...incident,
            category: e.target.value
        });
    }

    const isItemChecked = useCallback((itemId) => {
        if(incident.id > 0) {
            return (incident.category === itemId);
        } else {
            if(!isChangeCategory) {
                if(itemId === 1) return true;
            }
        }
    },[incident,isChangeCategory])

    const onChangeInputMap = useCallback((e) => {
        if (e.target.value === "" || e.target.value==null) {
            setError('locationinput', { type: 'required' }); 
        } else {
            //It means if the user input any text on the location field, the user should select on pop-up list box.
            setError('locationinput', { type: 'freetextlocation'});
        }
    },[setError])

    const onBlurInputMap = useCallback((e) => {
        if (e.target.value === "" || e.target.value==null) {
            setError('locationinput', { type: 'required' });
        }
    },[setError])

    const onChangeOnlyAgency = e => {
        setIncident({
            ...incident,
            onlyagency: e.target.checked
        });
    }

    const onChangeDraft = e => {
        setIncident({
            ...incident,
            draft: e.target.checked
        });
    }

    const RemoveImageSaved = useCallback((id) => {
        if(!isKeyPressEnter) {
            var newimages = imagesSaved.filter(function (image) {
                return image !== id
            });

            setImagesSaved(newimages);

            if (newimages.length === 0)
                setValue('image',null);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isKeyPressEnter])

    const RemoveFilesSaved = useCallback((id) => {
        if(!isKeyPressEnter) {
            var newFiles = filesSaved.filter(function (file) {
                return file !== id
            });

            setFilesSaved(newFiles);

            if (newFiles.length === 0)
                setValue('image',null);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isKeyPressEnter])

    const RemoveImage = id => {
        var newimages = images.filter(function (image) {
            return image !== id
        });
        setImages(newimages);
        if (newimages.length === 0)
            setValue('image',null);
    }

    const SelectInput = () => {
        uploadInput.current.click();
    }

    const Cancel = () => {
        try {
            setOpenCreatePost(false);
        } catch (ex) {
            console.log(ex);
        }
        cleanData();
        setTimeout(function () { history.push("/home"); }, 100);
    }

    const keyDownHandler = event => {
        setIsKeyPressEnter(event.key === 'Enter' ? true : false);
    };

    const SavePost = useCallback((data) => {
        if(appNBCContext.dbUserId !== null) {
            if(isKeyPressEnter) {
                setIsKeyPressEnter(false);
                return;
            }

            if (!loading) {

                const _requestFormData = new FormData()

                for (var i = 0; i < images.length; i++) {

                    //Just added theses properties to work on model
                    _requestFormData.append(`Files[${i}].Id`, 0);  
                    _requestFormData.append(`Files[${i}].FileName`, "-");  
                    _requestFormData.append(`Files[${i}].FileType`, "-");  
                    _requestFormData.append(`Files[${i}].URL`, "-"); 
                    _requestFormData.append(`Files[${i}].IncidentId`, 0); 
                    //End
                    _requestFormData.append(`Files[${i}].File`, images[i]);  //this one works

                }

                for (var iCounter2 = 0; iCounter2 < imagesSaved.length; iCounter2++) {
                    //Just added theses properties to work on model
                    _requestFormData.append(`ImagesSaved[${i}].Id`, 0);  
                    _requestFormData.append(`ImagesSaved[${i}].FileName`, "-");  
                    _requestFormData.append(`ImagesSaved[${i}].FileType`, "-");  
                    _requestFormData.append(`ImagesSaved[${i}].URL`, "-"); 
                    _requestFormData.append(`ImagesSaved[${i}].IncidentId`, 0); 
                    //End
                    _requestFormData.append(`ImagesSaved[${iCounter2}].File`, imagesSaved[iCounter2]);
                }

                for (var iCounter3 = 0; iCounter3 < filesSaved.length; iCounter2++) {
                      //Just added theses properties to work on model
                      _requestFormData.append(`FilesSaved[${i}].Id`, 0);  
                      _requestFormData.append(`FilesSaved[${i}].FileName`, "-");  
                      _requestFormData.append(`FilesSaved[${i}].FileType`, "-");  
                      _requestFormData.append(`FilesSaved[${i}].URL`, "-"); 
                      _requestFormData.append(`FilesSaved[${i}].IncidentId`, 0); 
                      //End
                    _requestFormData.append(`FilesSaved[${iCounter2}]`, filesSaved[iCounter3]);
                }

                setImageError(false);

                _requestFormData.append("Id", incident.id);
                _requestFormData.append("UserId", appNBCContext.dbUserId);
                _requestFormData.append("IncidentNum", data.num);
    
                if(location.e) {
                    _requestFormData.append("AddressId", location.e.place_id);
                } else {
                    //If the user edit the post but not changing the selected option under location. The addressId should remain.
                    if(!isChangeLocation) {
                        if(incident.addressId !== "-") {
                            _requestFormData.append("AddressId", incident.addressId);
                        }
                    }
                }

                _requestFormData.append("NameAddress", location.e ? location.e.formatted_address : data.locationinput);
                _requestFormData.append("Latitude", location.e ? location.e.geometry.location.lat() : 0);
                _requestFormData.append("Longitude", location.e ? location.e.geometry.location.lng() : 0);
                _requestFormData.append("OnlyAgency", incident.onlyagency);
                _requestFormData.append("Draft", incident.draft);
                _requestFormData.append("Date", data.date);
                _requestFormData.append("Description", data.description);
                _requestFormData.append("IncidentTypeId", data.type);
                _requestFormData.append("IncidentCategoryId", data.category);

                executeFormData("POST", protectedEndPointAPI.home.incident.information.save, _requestFormData)
                .then((response) => {
                    if(response.success) {
                        reloadData();
                        setLoading(false);
                        NotificationManager.success('Success', 'Successfully save the incide post', 5000);
                        try {
                            setIsAfterCreatedPost(true);
                            setOpenCreatePost(false);
                        } catch (ex) {
                            console.log(ex);
                        }
                        setTimeout(function () { history.push("/home"); }, 100);

                    }
                });




            }

        }


    },[appNBCContext.dbUserId, isKeyPressEnter, loading, images, imagesSaved, filesSaved, incident.id, incident.onlyagency, incident.draft, incident.addressId, location.e, executeFormData, isChangeLocation, reloadData, setIsAfterCreatedPost, setOpenCreatePost, history]);

    const getIncident = useCallback(async () => {
        if(appNBCContext.dbUserId !== null) {

            let _requestBody = id;

             execute("PUT", protectedEndPointAPI.home.incident.information.base, _requestBody)
             .then((response) => {
                var _data = response.data;
                if(_data !== null){
                    setValue('num', _data.incidentNum);
                    setValue('type', _data.incidentTypeId);
                    setValue('description', _data.description);
                    setValue('date', _data.date);
                    setValue('locationinput', _data.addressId === "-" ? "" : _data.nameAddress);
                    setValue('category', _data.incidentCategoryId);
                    setValue('addressId', _data.addressId);
                    setIncident({
                        ...incident,
                        id:_data.id,
                        category: _data.incidentCategoryId,
                        onlyagency: _data.onlyAgency,
                        locationinput: _data.addressId === "-" ? "" : _data.nameAddress,
                        draft: _data.draft,
                        addressId: _data.addressId
                    });
                    setImagesSaved(_data.imagesSaved);
                    setFilesSaved(_data.filesSaved);
                }
            });

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[appNBCContext.dbUserId, incident, execute,id])

    useEffect(() => {
        if(!isInitialLoadDone) {
            if(appNBCContext.dbUserId !== null) {

                execute("GET", protectedEndPointAPI.home.incident.type.list)
                .then((response) => {
                    var _data = response.data;
                    if(_data !== null) setList(response.data);;
                })
                .catch((error) => {
                    console.log(error);
                });

                execute("GET", protectedEndPointAPI.home.incident.category.list)
                .then((response) => {
                    var _data = response.data;
                    if(_data !== null) {
                        if (_data.length > 0) {
                            let idc = _data[0].id;
                            setIncident({
                                ...incident,
                                category: 
                                  idc
                            });
                        }
                        setListCategories(_data);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

                if (id !== undefined && id > 0 && incident.id ===0) {
                    getIncident();
                }

                setIsInitialLoadDone(true);
            }

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[appNBCContext.dbUserId, execute, id, incident, isInitialLoadDone])

    useEffect(() => {
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, []);

    return (
        <>
            <div className="card-container">
                <div className="card-post">
                    <form onSubmit={handleSubmit(SavePost)} >
                        <div className="card-body">
                            <Row>
                                <Col lg={2}>
                                    <Row>
                                        {
                                            <>
                                                <img alt="" src={postPicture} onClick={() => {SelectInput();}} />

                                                <input
                                                    type="file"
                                                    id="input-file" className="input-file"
                                                    accept="image/*"
                                                    name="myImage"
                                                    multiple
                                                    ref={uploadInput}

                                                    onChange={(event) => {
                                                        var arrayFiles = [];
                                                        if (images && images.length > 0) {
                                                            arrayFiles = images;
                                                        }
                                                       
                                                        Array.from(event.target.files).forEach(file => arrayFiles.push(file));
                                                        setImages(arrayFiles);
                                                        clearErrors("image");


                                                    }}
                                                />

                                                <button type="button" className="buttonWithoutBorder" variant="outline-light" onClick={() => {
                                                    SelectInput();
                                                }}>
                                                    Upload new file
                                                </button>
                                                <div className="errordiv">
                                                    {imagesError && imagesError === true && <span className="text-danger">This field is required</span>}

                                                </div>
                                            </>
                                        }
                                           
                                        {
                                            <>
                                                {images &&  images.length > 0 && (
                                                    <>
                                                        {images.map((item) => {
                                                            return <>
                                                                <div className="row" key={item}>
                                                                    <img alt="Not Found" width={"250px"} src={URL.createObjectURL(item)} />

                                                                    <button type="button" className="btn btn-danger" onClick={() => RemoveImage(item)}>Remove</button>
                                                                </div>
                                                            </>

                                                        })
                                                        }
                                                    </>
                                                )}
                                                {imagesSaved.length > 0 && (
                                                    <div>
                                                        {imagesSaved.map((item, index) => (
                                                            <div className="row" key={item}>
                                                                <img alt="Not Found" width={"250px"} src={item} />
                                                                <button className="btn btn-danger" onClick={() => RemoveImageSaved(item)} >Remove</button>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                                 {filesSaved.length > 0 && (
                                                    <div>
                                                        {filesSaved.map((item, index) => (
                                                            <div className="row" key={item}>
                                                                <img alt="Not Found" width={"250px"} src={item} />
                                                                <button className="btn btn-danger" onClick={() => RemoveFilesSaved(item)} >Remove</button>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </>
                                        }
                                    </Row>
                                    
                                </Col>
                                <Col lg={10}>
                                    <Row>
                                        <div>
                                            {listCategories.map((item) => (
                                                <>
                                                    <div key={item.id} className="typeBtnRadio">
                                                        <input checked={isItemChecked(item.id)} value={item.id} {...register("category", { required: true })} type="radio" onClick={onChangeCategory} />
                                                        <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                {item.name}
                                                        </label>
                                                    </div>
                                                </>
                                            ))}
                                        </div>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput1" className="form-label">Incident number</label>
                                                <input type="text" className={errors.num ? 'form-control is-invalid' : 'form-control'} {...register("num", { required: true, maxLength: 80 })} placeholder="Enter the number" aria-label="Username" aria-describedby="basic-addon1" />

                                            </div>
                                            <div className="errordiv">
                                                {errors.num && errors.num.type === "required" && <span className="text-danger">This field is required</span>}
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className="mb-3">
                                                <label className="form-label">Incident date</label>

                                                <input type="datetime-local" className={errors.date ? 'form-control  is-invalid' : 'form-control'} {...register("date", { required: true })} placeholder="Incident Date and Time" aria-label="Username" aria-describedby="basic-addon1" />
                                            </div>
                                            <div className="errordiv">
                                                {errors.date && errors.date.type === "required" && <span className="text-danger">This field is required</span>}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                        <div className="mb-3">
                                                <label htmlFor="ss" className="form-label">Location</label>
                                        
                                            <Autocomplete
                                                apiKey={protectedVariable.GoogleMapAPIKey}
                                                {...register("locationinput", { required: true})}
                                                className={errors.locationinput ? 'form-control is-invalid' : 'form-control'}
                                                placeholder="Enter the location"
                                               
                                                options={{
                                                    
                                                    types: ["address"],
                                                    componentRestrictions: { country: "us" },
                                                }}
                                                defaultValue={incident.locationinput}
                                                onChange={onChangeInputMap}
                                                onBlur={onBlurInputMap}
                                                onPlaceSelected={(place) => {
                                                    onChangeLocation(place);
                                                    // console.log(place);
                                                }}
                                            />

                                        </div>
                                       
                                        <div className="errordiv">
                                            {errors.locationinput && errors.locationinput.type === "required" && <span className="text-danger">This field is required</span>}
                                            {errors.locationinput && errors.locationinput.type === "freetextlocation" && <span className="text-danger">Select an address in the option list</span>}
                                        </div>
                                        </Col>
                                        <Col>
                                                <div className="mb-3">
                                                <label className="form-label">Incident type</label>

                                                    <select defaultValue="" className={errors.type ? 'form-control is-invalid' : 'form-control'}  {...register("type", { required: true })}>
                                                        <option value="" disabled hidden>Incident Type</option>
                                                        {list.map((item) => (
                                                            <option key={item.id} value={item.id}>{item.name}</option>
                                                        ))}
                                                    </select>

                                                </div>
                                                <div className="errordiv">
                                                    {errors.type && errors.type.type === "required" && <span className="text-danger">This field is required</span>}
                                                </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="mb-3">
                                                <label className="form-label">Description</label>

                                                <textarea className={errors.description ? 'form-control  textareaform is-invalid' : 'form-control textareaform'} {...register("description", { required: true })} placeholder="Description" aria-label="Username" aria-describedby="basic-addon1" />
                                            </div>
                                            <div className="errordiv">
                                                {errors.description && errors.description.type === "required" && <span className="text-danger">This field is required</span>}
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <div className="col-md-6 col-sm-12">
                                                <div className="form-check">
                                                    <input type="checkbox" className="form-check-input nomt" id="exampleCheck1" value={incident.draft} checked={incident.draft} onChange={onChangeDraft} />
                                                    <label className="form-check-label" htmlFor="exampleCheck1">Save as draft</label>
                                                </div>
                                                <div className="form-check">
                                                    <input type="checkbox" className="form-check-input nomt" id="exampleCheck1" value={incident.onlyagency} checked={incident.onlyagency} onChange={onChangeOnlyAgency} />
                                                    <label className="form-check-label" htmlFor="exampleCheck1">Just Share with my agency</label>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                               
                                            </div>
                                            <div className="row">
                                            <div className="col-md-4">
                                                <button type="submit" className="btn btn-primary btnsubmit"   disabled={loading} >  
                                                {loading && (
                                                    <span className="spinner-border spinner-border-sm"></span>
                                                    )} Submit
                                                    </button>
                                                </div>
                                                <div className="col-md-4">
                                                <button type="button" className="btn btn-secondary btnsubmit" onClick={() =>  Cancel()} disabled={loading}>
                                                    Cancel
                                                </button>
                                            </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </form>
                </div>
            </div>
            <NotificationContainer />
        </>
      
    );
}

export default AzurePostModal;