import React, {useEffect, useState, useCallback} from "react";
import Avatar from 'react-avatar';
import { useHistory } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import Pagination from "../../../components/Common/pagination";
import StatusBadge from "../../../components/Officer/StatusBadge"
import { PostShimmeringEffect
    , NoResultFoundContainer 
} from "../../../components/Department/Department.Style";
import MultiSelectWithSelectAll from "../../../components/Common/multiselect";

import { UsedAppNBCContext } from "../../AppNBC.Provider";
import useFetchWithMsal from "../../hooks/useFetchWithMsal";
import { protectedEndPointAPI } from "../../Helpers/ProtectedCustomClass";

import './AzureOfficer.css';
import { InitialDepartmentData, InitialDivisionData } from "./AzureOfficer.Style";

library.add(faSearch);
const AzureOfficer = (props) => {

    const appNBCContext = UsedAppNBCContext();
    // eslint-disable-next-line no-unused-vars
    const { error, execute } = useFetchWithMsal();

    let history = useHistory();

    const [isLoading, setIsLoading] = useState(true);

    // eslint-disable-next-line no-unused-vars
    const [departmentValue, setDepartmentValue] = useState("");

    // eslint-disable-next-line no-unused-vars
    const [divisionValue, setDivisionValue] = useState("");

    const [departmentData, setDepartmentData] = useState(InitialDepartmentData);
    const [divisionData, setDivisionData] = useState(InitialDivisionData);
    const [selectedMultiDepartment, setSelectedMultiDepartment] = useState("");
    const [selectedMultiDivision, setSelectedMultiDivision] = useState("");

    const [searchValue, setSearchValue] = useState("");
    const [isExecuteSearchPost, setIsExecuteSearchPost] = useState(false);
    const [isTextSearchExecuted, setIsTextSearchExecuted] = useState(false);

    const [listData, setListData] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [totalPages, setTotalPages] = useState(0);
    const [totalData, setTotalData] = useState(0);

    const [currentPage, setCurrentPage] = useState(1);

    // Use this kind of approach in Pagination especially the isPaginationLoaded that it needs to dispose the component once the totalData has been updated, selected previous page number is selected is shown
    const [isExecutePageChange, setIsExecutePageChange] = useState(false);
    const [isPaginationShow, setIsPaginationShow] = useState(false);
    const [isPaginationLoaded, setIsPaginationLoaded] = useState(false);

    const handleMultiDepartmentOnChange = (values) => {
        if(values.length > 0) {
            setIsPaginationShow(false);
            setIsPaginationLoaded(false);

            const flatValues = values.map(item => item.value);

            var zeroExist = flatValues.filter(x => x === 0);
            const concatValues = zeroExist.length > 0 ? "" : flatValues.join("|");
            setSelectedMultiDepartment(concatValues);

            setCurrentPage(1);
            setIsExecutePageChange(true);
        }
    };

    const handleMultiDivisionOnChange = (values) => {
        if(values.length > 0) {
            setIsPaginationShow(false);
            setIsPaginationLoaded(false);

            const flatValues = values.map(item => item.value);

            var zeroExist = flatValues.filter(x => x === 0);
            const concatValues = zeroExist.length > 0 ? "" : flatValues.join("|");
            setSelectedMultiDivision(concatValues);

            setCurrentPage(1);
            setIsExecutePageChange(true);

        }
    };

    const ChangeSearch = (e) => {
        setSearchValue(e.target.value);
        setIsTextSearchExecuted(true);
        setIsExecuteSearchPost(false);
    }

    const ChangePage = useCallback((e) => {
        if(isPaginationLoaded) {
            setCurrentPage(e);
            setIsExecutePageChange(true);

        } else {
            setIsPaginationLoaded(true);
        }
        
    },[isPaginationLoaded])

    const RedirectMessage = (state, userId, name, type, urlPic, phone, email, address, typechat) => {
        if (state != null && (state === "Suspended" || state === "Removed")) {
            NotificationManager.info('Information', 'The account cannot be contacted because it was deleted or suspended.', 5000);
            return;
        }

        let img = urlPic;
        if (props.Incident !== undefined && (props.Incident.filesIncidents != null && props.Incident.filesIncidents !== undefined && props.Incident.filesIncidents.length > 0)) {
            img = props.Incident.filesIncidents[0].file.path;
        }
        
        history.push({
            pathname: `/message`,
            search: `?userId=${userId}&name=${name}&type=${props.Incident !== undefined ? props.Incident.type : type}&typechat=${typechat}&incident=${props.Incident !== undefined ? props.Incident.id : ""}&address=${props.Incident !== undefined ? props.Incident.address : ""}`,
            state: {
                urlPic: img,
                userId: userId,
                name: name,
                type: (props.Incident !== undefined ? props.Incident.type : type),
                typechat: typechat,
                incident: props.Incident !== undefined ? props.Incident.id : "",
                address: props.Incident !== undefined ? props.Incident.address : "",
                phone: phone,
                email: email,
                userAddress: address
            }

        });

    }

    const getData = useCallback(async (pageNo, division = 0, department = 0,search = "", multidepartment ="",multidivision ="") => {
        if(appNBCContext.dbUserId !== null) {
         
            let _requestBody = {
                Take: 10,
                Page: pageNo,
                UserId: appNBCContext.dbUserId,
                IsSuperAdmin: appNBCContext.isSuperAdmin,
                IsAdmin: appNBCContext.isAdmin,
                DivisionId: division === "" ? 0 : division,
                AgencyId: department === "" ? 0 : department,
                SearchCriteria: search,
                MultiDepartment: multidepartment,
                MultiDivision: multidivision,
            }
    
            return execute("PUT", protectedEndPointAPI.officer.getlistforagency, _requestBody);
        }
    },[execute,appNBCContext])


    useEffect(() => {
        if(props.isExecuteRefreshAzureOfficer === true)  setIsExecutePageChange(true); 
    },[props.isExecuteRefreshAzureOfficer]);

     //Initialize data
     useEffect(() => {
        if(appNBCContext.dbUserId !== null) {
            let _requestBody = {
                UserId: appNBCContext.dbUserId,
                IsSuperAdmin: appNBCContext.isSuperAdmin,
                IsAdmin: appNBCContext.isAdmin
            }

            execute("PUT", protectedEndPointAPI.officer.department.list, _requestBody).then((response) => {
                var _data = response.data;
                if(_data !== null) {
                    let tempResult = [];
                    tempResult.push(InitialDepartmentData[0]);
                    
                    _data.forEach(item => {
                        tempResult.push({value: item.id, label: item.name});
                    })
                    setDepartmentData(tempResult);
                };
            });

            execute("GET", protectedEndPointAPI.officer.division.list).then((response) => {
                var _data = response.data;
                if(_data !== null) {
                    let tempResult = [];
                    tempResult.push(InitialDivisionData[0]);
                    
                    response.data.forEach(item => {
                        tempResult.push({value: item.id, label: item.name});
                    })
                    setDivisionData(tempResult);
                };
            });
            
            getData(1).then((response) => {
                if(response !== undefined) {
                   
                    const { totalPages, data, totalData } = response.data;

                    setListData(data);
                    setTotalPages(totalPages);
                    setTotalData(totalData);
                }
            });

            setIsLoading(false);
            setIsPaginationShow(true);
        }
     },[appNBCContext]);

     //Reload data
    useEffect(() => {
        if(isExecutePageChange) {
            setIsLoading(true);
            setIsExecutePageChange(false); 
            getData(currentPage,divisionValue, departmentValue, searchValue,selectedMultiDepartment,selectedMultiDivision)
            .then((response) => {
                if(response !== undefined) {
                    const { totalPages, data, totalData } = response.data;
                    setListData(data);
                    setTotalPages(totalPages);
                    setTotalData(totalData);
                }
               
            });
            setIsLoading(false);
            setIsPaginationShow(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[currentPage,isExecutePageChange,divisionValue,departmentValue,searchValue,selectedMultiDepartment,selectedMultiDivision]);

    //Set some delay when typing
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            setIsExecuteSearchPost(false);
            setIsExecuteSearchPost(true);
        }, 1500)

        return () => clearTimeout(delayDebounceFn)
    }, [searchValue])

    useEffect(() => {
        if(isExecuteSearchPost === true && isTextSearchExecuted === true) {
            setIsPaginationShow(false);
            setIsPaginationLoaded(false);
            setCurrentPage(1);
            setIsExecutePageChange(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isExecuteSearchPost,isTextSearchExecuted])

    return (
        <>
            <div>
                <div className="row">
                    <div className="col-4">
                    <MultiSelectWithSelectAll options={departmentData} placeholder={InitialDepartmentData[0].label} onSelectedValueChanged={handleMultiDepartmentOnChange} />
                    </div>
                    <div className="col-4">
                    <MultiSelectWithSelectAll options={divisionData} placeholder={InitialDivisionData[0].label} onSelectedValueChanged={handleMultiDivisionOnChange} />
                    </div>
                    <div className="col-4">
                        <div className="input-group mb-12">
                            <span className="input-group-prepend">
                                <div className="input-group-text  border-right-0"> <FontAwesomeIcon icon={faSearch} /></div>
                            </span>
                            <input type="text" className="form-control" placeholder="Search officers..." onChange={ChangeSearch} value={searchValue} />
                            <span className="input-group-append"></span>
                        </div>
                    </div>
                </div>

                {
                        isLoading ? 
                        <div>
                            <div className="row inline-elem">
                                <div className="col-6" style={{ marginBottom: "25px" }}><PostShimmeringEffect /></div>
                                <div className="col-6" style={{ marginBottom: "25px" }}><PostShimmeringEffect /></div>
                            </div>
                            <div className="row inline-elem">
                                <div className="col-6" style={{ marginBottom: "25px" }}><PostShimmeringEffect /></div>
                                <div className="col-6" style={{ marginBottom: "25px" }}><PostShimmeringEffect /></div>
                            </div>
                        </div>
                        : 
                        <div>
                            <div className="row">
                                {listData.length > 0 ? 
                                <>
                                    {listData.map((item) => (
                                        <div className="col-6" key={['idoffice', item.id]} style={{marginTop: "20px"}}>
                                            <div className="row  card-officer">
                                                <div className="col-3 card no-mgr" style={{marginRight: "20px"}}>
                                                    <div className="row" style={{flex: "1", alignItems:"center", justifyContent:"center", marginRight:"10px", marginTop:"10px"}}>
                                                        {appNBCContext.isFileEncryptionEnable ? 
                                                            (item.urlPic && <Avatar src={`data:image/jpeg;base64,${item.profilePicBase64String}`} size="80" round  className="avatarofficer" />) 
                                                            : 
                                                            (item.urlPic && <Avatar src={item.urlPic} size="80" round  className="avatarofficer" />)
                                                        }
                                                        {!item.urlPic && <Avatar name={item.name} size="80" round />}
                                                    </div>
                                                    <div className="row" style={{flex: "1", alignItems:"center", justifyContent:"center", fontFamily: "Work Sans"}}>
                                                        <button type="button" 
                                                                disabled={item.deleted ? true : item.statusApp === "Suspended" ? true : false}
                                                                className="btn btn-outline-primary btn-lg btn-bloc col-lg-10"  
                                                                onClick={() => RedirectMessage(item.statusApp, item.id, item.name, item.division, appNBCContext.isFileEncryptionEnable ? item.profilePicBase64String : item.urlPic, item.phone, item.email, item.address, "user")}>
                                                            <div style={{fontSize: "14px"}}>Message</div>
                                                        </button>
                                                    </div>
                                                </div>
                                                
                                                <div className="col-8 card bg-white no-mgl">
                                                        <div className="row" style={{textAlign: "right", paddingRight: "15px"}}><span>{StatusBadge(item.deleted ? 3 : item.statusApp === "Suspended" ? 2 : 1)}</span></div>
                                                        <div className="row"> <label className="titlename">{item.name}</label></div>
                                                        <div className="row"> <label className="lblinfo">Division:  <span className="txtinfo">{item.division}</span></label></div>
                                                        <div className="row"> <label className="lblinfo">Department:  <span className="txtinfo">{item.department}</span></label></div>
                                                        <div className="row"> <label className="lblinfo">Phone:   <span className="txtinfo"><a href={`tel:${item.phone ?? ""}`}>{item.phone}</a></span></label></div>
                                                        <div className="row"> <label className="lblinfo">Email: <span className="txtinfo"><a href={`mailto:${item.email}`}>{item.email}</a></span></label> </div>
                                                        <div className="row"> <label className="lblinfo">Address: <span className="txtinfo">{item.address}</span></label> </div>
                                                </div>
                                            </div>
                                        
                                        </div>
                                    ))}
                                </> 
                                : 
                                <NoResultFoundContainer message={"No results found"} />
                                }
                            </div>                            
                        </div>
                }

                  <div className="row" >
                    {isPaginationShow &&  <Pagination
                        totalRows={totalData}
                        pageChangeHandler={ChangePage}
                        rowsPerPage={10}
                        />
                    
                    }
                  </div>


            </div>
        </>
    );




}

export default AzureOfficer;