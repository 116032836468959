import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  AdvancedMarker,
  APIProvider,
  InfoWindow,
  Map,
  Pin,
  useMap,
} from "@vis.gl/react-google-maps";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import iconnopreview from "../../../assets/nopreview.svg";
import { protectedVariable } from "../../Helpers/ProtectedCustomClass";
import { UsedAppNBCContext } from "../../AppNBC.Provider";


const Markers = ({ points }) => {
    const appNBCContext = UsedAppNBCContext();

    const map = useMap();
    const [markers, setMarkers] = useState({});
    const clusterer = useRef(null);
  
    const [activeMarker, setActiveMarker] = useState(null);
    const handleActiveMarker = (xxmarker) => {
      if (xxmarker === activeMarker) {
        return;
      }
      setActiveMarker(xxmarker);
    };
  

    useEffect(() => {
      if (!map) return;
      if (!clusterer.current) {
        clusterer.current = new MarkerClusterer({ map });
      }
    }, [map]);

    useEffect(() => {
      if (map) {
        if(points.length > 0) {
          const bounds = new window.google.maps.LatLngBounds();
          // eslint-disable-next-line array-callback-return
          points.map(item => {
            bounds.extend({
              lat: item.lat,
              lng: item.lng,
            });
          });
          map.fitBounds(bounds);
        }
        
      }
    }, [map, points]);
  
    useEffect(() => {
      clusterer.current?.clearMarkers();
      clusterer.current?.addMarkers(Object.values(markers));
    }, [markers]);
  
    const setMarkerRef = (marker, key) => {
      if (marker && markers[key]) return;
      if (!marker && !markers[key]) return;
  
      setMarkers((prev) => {
        if (marker) {
          return { ...prev, [key]: marker };
        } else {
          const newMarkers = { ...prev };
          delete newMarkers[key];
          return newMarkers;
        }
      });
    };

    const renderSwitchImage = useCallback((item) => {
        return <img style={{ width: "150px", height: "150px" }} 
                    src={appNBCContext.isFileEncryptionEnable ? 
                        item.imageBase64String !== "" ? `data:image/jpeg;base64,${item.imageBase64String}` : iconnopreview
                    : 
                        item.imagePath !== "" ? item.imagePath : iconnopreview
                    } 
                    alt="" />
    },[appNBCContext]);
  
    return (
      <>
        {points.map((point) => (
          <AdvancedMarker
            position={point}
            key={point.key}
            ref={(marker) => setMarkerRef(marker, point.key)}
            onClick={() => handleActiveMarker(point)}
          >
            <Pin background={"red"} borderColor={"red"} glyphColor={"orange"} />
            {activeMarker !== null ? (
              activeMarker.key === point.key ? (
                <InfoWindow position={point} onCloseClick={() => setActiveMarker(null)}>
                    <div>
                        <div>
                          {point.canEdit && !appNBCContext.isSuperAdmin ? (
                              <a href={"/post/edit/" + point.incidentId} target="_blank" rel="noreferrer">
                                <label className="incidenTitle2" style={{cursor: "pointer"}}>Incident: {point.key}</label>
                              </a>
                            ) : ( <a href={"/post/view?id=" + point.incidentId + "&pagesource="+ protectedVariable.PageSource.Home} target="_blank" rel="noreferrer">
                              <label className="incidenTitle2" style={{cursor: "pointer"}}>Incident: {point.key}</label>
                          </a>)
                          
                          }
                         
                        </div>
                        <br/>
                        <div>{renderSwitchImage(point)}</div>
                    </div>
                </InfoWindow>
              ) : (
                <></>
              )
            ) : (
              <></>
            )}
          </AdvancedMarker>
        ))}
      </>
    );
};

const GoogleMapIncidentPost = (props) => {
    const appNBCContext = UsedAppNBCContext();
    const [defaultCenter, setDefaultCenter] = useState(null);

    useEffect(() => {
        if(appNBCContext.agencyGeoLoc !=null) {
            setDefaultCenter(appNBCContext.agencyGeoLoc);
        }
    },[appNBCContext])

    // onCameraChanged={(ev) =>console.log("camera changed:",ev.detail.center,"zoom:",ev.detail.zoom)}
    return (
        <>
        {defaultCenter != null && (
            <div className="row" style={{ height: "800px", width: "1300px",  
                                          justifyContent: "center", alignContent:"center"}}>
                <APIProvider apiKey={protectedVariable.GoogleMapAPIKey}>
                    <Map defaultZoom={protectedVariable.GoogleMapDefaultZoom}
                        defaultCenter={defaultCenter}
                        mapId={protectedVariable.GoogleMapId}
                        >
                        <Markers points={props.data} />
                    </Map>
                </APIProvider>
            </div>
        )
        }
        </>

       
      );
}

export default GoogleMapIncidentPost;