import AzureAdminAgencyApp from "./AzureAdminAgency"
import AzureManageAdminsApp from "./AzureManageAdmins"
import AzureAgencyApp from "./AzureAgency"
import AzureAgencyViewApp from "./AzureAgency.View"

export const AzureAgency = () => {
    return (<AzureAgencyApp />);
}

export const AzureAgencyView = () => {
    return (<AzureAgencyViewApp />);
}

export const AzureAdminAgency = () => {
    return (<AzureAdminAgencyApp />);
}

export const AzureManageAdmins = () => {
    return (<AzureManageAdminsApp />);
}