import React, {useEffect, useState, useCallback} from "react";
import { Button, Input, Label, Modal, Row} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { Col, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'

import ModalAlert from "../../../components/Common/ModalAlert";


import { UsedAppNBCContext } from "../../AppNBC.Provider";
import useFetchWithMsal from "../../hooks/useFetchWithMsal";
import { protectedEndPointAPI } from "../../Helpers/ProtectedCustomClass";

import AzureManageAdmins from "./AzureManageAdmins";
import { AzureOfficer, AzureRequestedAgencies } from "../AzureOfficer";
import "./AzureAdminAgency.css";

const AzureAdminAgency = () => {

    const appNBCContext = UsedAppNBCContext();
    // eslint-disable-next-line no-unused-vars
    const { error, execute } = useFetchWithMsal();

    const [modal, setModal] = React.useState(false);
    const [modalDelete, setModalDelete] = React.useState({
        show: false,
        id: "",
        name:""
    });
    const [modalChief, setmodalChief] = useState(false);
    const [modalInvitation, setmodalmodalInvitation] = useState(false);
    const [filter, setFilter] = useState("");
    const [agencyId, setAgencyId] = useState(false);
    const [validation, setValidation] = useState({ error: false, message: "" });
    const [showUsers, setShowUsers] = useState(false);
    const [manageAgency, setmanageAgency] = useState(0);

    const [currentPage, setCurrentPage] = useState(1);

    const [pageData, setPageData] = useState({
        rowData: [],
        isLoading: false,
        totalPages: 0,
        totalData: 0,
    });

    const toggle = (agency) => {
        setValidation({
            error: false,
            message: ""
        });
        setModal(!modal);
        setAgencyId(agency);
        setFilter("");
    }

    const toggleDelete = (agency) => {
        setModalDelete({
            ...modalDelete,
            show: !modalDelete.show,
            id: agency.id,
            name:agency.name
        });
       
    }

    const toggleDeleteModal = (shomwod) => {
        setModalDelete({
            ...modalDelete,
            show: shomwod,
            id: "",
            name: ""
        });
    }

    const toggleInvitation = () => {
        setmodalmodalInvitation(false);
        setFilter("");
    };


    const toggleAddedShief = () => {
        setmodalChief(false);
        setFilter("");
    };

    const toggleManageAdmins = () => {
        setShowUsers(false);
    }

    const onFilterChanged = (e) => {
        setFilter(e.target.value);
    }

    const manageAdminUsers = async (agency) => {
        setmanageAgency(agency.id);
        setShowUsers(true);
    }

    const getData = useCallback(async (pageNo = 1) => {
        if(appNBCContext.dbUserId !== null) {
            let _requestBody = {
                UserId: appNBCContext.dbUserId,
                IsSuperAdmin: appNBCContext.isSuperAdmin,
                IsAdmin: appNBCContext.isAdmin,
                Page: pageNo,
                Take: 15
            }

            execute("PUT", protectedEndPointAPI.agencies.list.get, _requestBody).then((response) => {
                var _data = response.data;
                if(_data !== null)  return _data;
            });
        }
            
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[execute, appNBCContext])

    const DeleteAgency = useCallback((id) => {
        if(appNBCContext.dbUserId !== null) {
            let _requestBody = id

            execute("POST", protectedEndPointAPI.agencies.delete, _requestBody).then((response) => {
                var _data = response.data;
                if(_data !== null) { 
                    toggleDeleteModal(false);
                    getData(1).then((info) => {
                        const { totalPages, data, totalData } = info;
                        setPageData({
                            isLoading: false,
                            rowData: data,
                            totalPages,
                            totalData,
                        });
                    });
                }
            });

        }
            
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[execute, appNBCContext])

    const sendUserInvitation = async () => {
        // eslint-disable-next-line
        const regexemail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (regexemail.test(filter) === true)
        {
            // var addOfficerViewModel = {
            //     Email: filter,
            //     Agency: agencyId,
            //     UserId: filter
            // };
            // const responseUser = await api.post(
            //     `/authenticate/sendInvitation`, addOfficerViewModel
            // ).then((response) => {

            //     if (response !== undefined && response.data.message !== undefined) {
            //         setValidation({
            //             error: true,
            //             message: response.data.message
            //         })
            //     } else {
            //         setValidation({
            //             error: false,
            //             message: ""
            //         })
            //         setModal(false);
            //         setmodalmodalInvitation(true);
            //     }
                
            // });
        }
    }

    const [isExecuteRefreshAzureOfficer, setIsExecuteRefreshAzureOfficer] = useState(false);
    const RefreshAzureOfficer = () => {
        setIsExecuteRefreshAzureOfficer(true);
        setIsExecuteRefreshAzureOfficer(false);
    };

    useEffect(() => {
        setPageData((prevState) => ({
            ...prevState,
            rowData: [],
            isLoading: true,
        }));
        getData(currentPage).then((response) => {
            if(response !== undefined) {
                const { totalPages, data, totalData } = response;
                setPageData({
                    isLoading: false,
                    rowData: data,
                    totalPages,
                    totalData,
                });
            }
           
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);



    return (
        <>
            <div><AzureRequestedAgencies refreshAzureOfficer={RefreshAzureOfficer}  /></div>

            <div className="AgenciesDiv">
                <label className="titlediv">Officers</label>
                <AzureOfficer isExecuteRefreshAzureOfficer={isExecuteRefreshAzureOfficer}/>
            </div>
            
            <Modal size="sm" isOpen={modal}>
                <ModalHeader>
                    Add new officer 
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xl="3">
                            <Label>Email officer</Label>
                        </Col>
                        <Col>
                            <div className="input-group mb-3">
                                <Input autoComplete="off" onChange={onFilterChanged} value={filter ?? ""} type="text" className="form-control" />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        {(validation !== undefined && validation.error ===true) && <p className="alert alert-danger">{validation.message}</p> }
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={sendUserInvitation}
                    >
                        Send invitation
                    </Button>
                    {' '}
                    <Button onClick={toggle}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
            <ModalAlert Toggle={toggleAddedShief} Message="User added as a chief" Show={modalChief} />
            <ModalAlert Toggle={toggleInvitation} Message="An invitation was sent" Show={modalInvitation} />
            <Modal size="sm" isOpen={modalDelete.show}>
                <ModalHeader>
                    Delete Agency
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <label >Are you sure you want to delete the {modalDelete.name} agency?</label>
                    </Row>
                   
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() =>DeleteAgency(modalDelete.id)}
                    >
                        Delete
                    </Button>
                    {' '}
                    <Button onClick={toggleDelete}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal  isOpen={showUsers}>
                <ModalBody>
                    <AzureManageAdmins Agency={manageAgency} />
                </ModalBody>
                <ModalFooter>
                    <Button onClick={toggleManageAdmins} outline>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        
        </>
    );
}

export default AzureAdminAgency;