
import React, { useState, useRef } from 'react';
import ImageUpLoader from '../Common/ImageLoader';
import ImageBinaryDataLoader from '../Common/ImageBinaryDataLoader';
import { UsedAppNBCContext } from '../../v2/AppNBC.Provider';
import './ProfilePictureUpload.css';
import Avatar from 'react-avatar';

const ProfilePicture = (props) => {

    const appNBCContext = UsedAppNBCContext();

    const [profilePicChanged, setProfilePicChanged] = useState(false);
    const [profilePic, setProfilePic] = useState(null);
    const [binaryData, setBinaryData] = useState(null);

    const uploadInput = useRef(null);

    if (props.selectedImage !== null && props.selectedImage !== undefined && (profilePic === null || profilePic === undefined)) {
        setProfilePic(props.selectedImage);
    }

    if (props.binaryData !== null && props.binaryData !== undefined && (binaryData === null || binaryData === undefined)) {
        setBinaryData(props.binaryData);
    }

    const remove = () => {
        //setProfilePic(null);
        //props.pictureChanged(null);
        uploadInput.current.click();
    }
    return <>
        {appNBCContext.isFileEncryptionEnable ? 
            (profilePic && <ImageBinaryDataLoader removeAction={remove} isProfilePicChange={profilePicChanged} selectedImage={profilePic} binaryData={props.binaryData} />) 
            : 
            (profilePic && <ImageUpLoader removeAction={remove} selectedImage={profilePic} />)  
        }
        {
            (profilePic === null || profilePic === "") &&
            <>
                <label id="inputTagLabel" for="inputTag">
                    <Avatar name={props.name} size="128" round />
                </label>
            </>
        }
       
        <input
            id="inputTag"
            type="file"
            ref={uploadInput}
            name="myImage"
            className="inputfile"
            onChange={(event) => {
                setProfilePic(event.target.files[0]);
                props.pictureChanged(event.target.files[0])
                setProfilePicChanged(true);
            }} />
        <button type="button" className="buttonWithoutBorder" variant="outline-light" onClick={() => {
            remove();
        }}>
            Upload new photo
        </button>
    </>
}

export default ProfilePicture;
