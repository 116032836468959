import React, { useState } from 'react';
import Select from 'react-select';

// const options = [
//     { label: 'Option 1', value: '1' },
//     { label: 'Option 2', value: '2' },
//     // ... other options
//     { label: 'Select All', value: 'all' },
//   ];

//value == 0 means All
const MultiSelectWithSelectAll = ({options, defaultValue, placeholder, defaultAllValue, onSelectedValueChanged}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [searchField, setSearchField] = useState('');

  const filterOption = ({ label, value }, string) => {

    const _selectAlllValue = defaultAllValue !== null ? defaultAllValue : 0;

    if (value === _selectAlllValue) {
      return true; // Display "Select All" option
    } else if (string) {
      return label.toLowerCase().includes(string.toLowerCase()) || value.toString().includes(string);
    } else {
      return true;
    }
  };

  const onChange = (selectedOptions, { option }) => {
    let newOptions = selectedOptions;

    const _selectAlllValue = defaultAllValue !== null ? defaultAllValue : 0;

    if(option && option.value === _selectAlllValue) {
         newOptions = newOptions
        .filter((newOpt) => newOpt.value === _selectAlllValue);
    }

    setSelectedOptions(newOptions);
    onSelectedValueChanged(newOptions);
  };

  const onInputChange = (searchString, { action }) => {
    if (action === 'input-change') {
      setSearchField(searchString);
    }
  };

  return (
    <Select
      isMulti
      options={options}
      value={selectedOptions}
      onChange={onChange}
      onInputChange={onInputChange}
      filterOption={filterOption}
      isClearable
      placeholder={ placeholder ? placeholder : "Select ..."}
    />
  );
};

export default MultiSelectWithSelectAll;
