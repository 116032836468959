import React, {useState, useCallback} from "react";
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useForm } from 'react-hook-form';
import { NotificationManager } from 'react-notifications';
import { UsedAppNBCContext } from "../../AppNBC.Provider";
import useFetchWithMsal from "../../hooks/useFetchWithMsal";
import { protectedEndPointAPI } from "../../Helpers/ProtectedCustomClass";

const AzureBookmarkNewModal = (props) => {

    const appNBCContext = UsedAppNBCContext();
    // eslint-disable-next-line no-unused-vars
    const { error, execute } = useFetchWithMsal();


    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        mode: 'onChange'
    });
    const [loading, setLoading] = useState(false);
    const [exists, setExists] = useState(false);

    const SaveInformation = useCallback((data) => {
        if(appNBCContext.dbUserId !== null) {

            setLoading(true);
            let _requestBody = {
                UserId: appNBCContext.dbUserId,
                IsSuperAdmin: appNBCContext.isSuperAdmin,
                IsAdmin: appNBCContext.isAdmin,
                BookmarkName: data.name,
                FolderId: 0,
            }

            execute("POST", protectedEndPointAPI.home.incident.bookmark.save, _requestBody)
                .then((response) => {
                    var _data = response.data;
                    if(_data !== null) {
                        if (_data === 0) {
                            reset({ name: "" });
                        
                            setExists(false);
                            
                            NotificationManager.success('Success', 'Folder added', 5000);
                            props.closeModal();

                        }
                        if (_data === -1) {
                            setExists(true);
                        }
                    }
                }).finally(() => {
                    setLoading(false);
                }) ;
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[execute,appNBCContext])

    return (
        <>
            <Modal size="lg" isOpen={props.show}>

                <ModalHeader>
                    <h4> Add new folder</h4>
                </ModalHeader>
                <ModalBody>
                    <Form onSubmit={handleSubmit(SaveInformation)}>
                        <div className="row accountinp">
                            <div className="col-3">
                                <label>Name:*</label>
                            </div>
                            <div className="col-6">
                                <input type="text" className={errors.name ? 'form-control is-invalid' : 'form-control'} {...register("name", { required: true, maxLength: 200 })} placeholder="Name..." />
                                {errors.name && errors.name.type === "required" && <span className="text-danger">This is required</span>}
                                {exists && <p className="alert alert-danger">The folder name already exists.</p>}
                            </div>
                        </div>
                        <ModalFooter>
                            <Button
                                type="submit"
                                color="primary"
                                disabled={loading}
                            >
                                { "Add"}
                                {loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                            </Button>
                            {' '}
                            <Button onClick={() => {
                                props.closeModal()
                            }}>
                                Cancel
                            </Button>
                        </ModalFooter>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    );
}

export default AzureBookmarkNewModal;