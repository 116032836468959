import { useRef } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import Table from "../../../components/Common/table";
import Pagination from "../../../components/Common/pagination";
import Avatar from 'react-avatar';
import { StyledBadge } from "./AzureChatNewModal.Style";

const AzureChatNewModal = (props) => {

    const checkNewuserRef = useRef([]);

    let user;
    const closeModal = () => {
        props.CloseNewChatModal();
    }

    const createChatClick = () => {
        if (user && Object.keys(user).length > 0) {
            props.CreateNewChat(user);
        }
    }

    const { rowData, totalData } = props.data;

    const handleChangeChk = (e, item) =>
    {

        if (checkNewuserRef && checkNewuserRef.current) {
            for (let i = 0; i < checkNewuserRef.current.length; i++) {
                if (checkNewuserRef.current[i] === e.target) {
                    checkNewuserRef.current[i].checked = e.target.checked;
                    
                }
                else if ( checkNewuserRef.current[i] !== e.target && (checkNewuserRef.current[i].checked === true || checkNewuserRef.current[i].checked === false)) {
                    checkNewuserRef.current[i].checked = false;
                }
            }
        }

        user=item;

    }
   
    const columns = [
       
        {
            Header: "",
            accessor: "email",
            className: "flex-col",
            Cell: cellInfo =>
                <>
                    <Row>
                        <Col lg={2}>
                            {(cellInfo.data[cellInfo.row.index].picture === null || cellInfo.data[cellInfo.row.index].picture === undefined) &&
                                <Avatar className="withoutMargin" name={cellInfo.data[cellInfo.row.index].fullName} size="50" variant="rounded" />
                            }
                            {cellInfo.data[cellInfo.row.index].picture &&


                                <StyledBadge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    variant="dot"
                                >
                                    <Avatar src={cellInfo.data[cellInfo.row.index].picture.path} size="50" round />
                                </StyledBadge>
                            }

                        </Col>
                        <Col>
                            <label>{cellInfo.data[cellInfo.row.index].fullName}</label> &nbsp;
                            <span>
                                {cellInfo.data[cellInfo.row.index].division.name}

                            </span>
                            {cellInfo.data[cellInfo.row.index].address &&
                                <div>
                                    {cellInfo.data[cellInfo.row.index].address.address1}
                                </div>
                            }
                        </Col>
                        <Col lg={2}>
                            <input onChange={(e) => {
                                handleChangeChk(e, e.target.checked ? cellInfo.data[cellInfo.row.index] : {});
                            }} ref={ref => { checkNewuserRef.current[cellInfo.row.index] = ref }} type="checkbox" />
                        </Col>
                    </Row>
                </>
        }
    ];

    return (
        <>
            <Modal show={props.show} onHide={() => { closeModal(); } }>
                <Modal.Header closeButton>
                    <Modal.Title>New chat</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="newChatModal">
                        <Table columns={columns} data={rowData}></Table>
                    </div>
                    <Pagination
                        totalRows={totalData}
                        pageChangeHandler={props.setCurrentPage}
                        rowsPerPage={15}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={closeModal}>Cancel</button>
                    <button onClick={createChatClick} className="btn btn btn-primary btncreate">Create</button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AzureChatNewModal;