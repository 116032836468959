import React, {useEffect, useState, useCallback,useRef} from "react";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCalendarDays, faShield, faBars, faUserGroup, faClock, faLocationDot, faFolderOpen, faMagnifyingGlass,faAngleDown} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {
    ButtonFontStyle,
    CenterAllStyle,
    DatePickerField, 
    // DepartmentDropDownField, 
    HeaderLabel, 
    IncidentPostContainer, 
    InitialDateRangeState,
    NoResultFoundContainer,
    PostShimmeringEffect,
    PrimaryFontFamily,
    SearchOfficerField, 
} from "../../../components/Department/Department.Style";
import CustomPicker from "../../../components/Common/CustomPicker"
import GoogleMapIncidentPost from "../GoogleMap/GoogleMapIncidentPost";
import { AzureLoaderPost } from "../AzurePost/AzureStagePage";
import * as _ from "lodash";

import MultiSelectWithSelectAll from "../../../components/Common/multiselect";

import { UsedAppNBCContext } from "../../AppNBC.Provider";
import useFetchWithMsal from "../../hooks/useFetchWithMsal";
import { protectedEndPointAPI } from "../../Helpers/ProtectedCustomClass";

library.add(faCalendarDays, faShield, faBars, faUserGroup, faClock, faLocationDot, faFolderOpen, faMagnifyingGlass, faAngleDown);

const initialIncidentTypeData = [
    { value: 0, label: 'All' }
]


const AzureIncidentMapView = (props) => {

    const appNBCContext = UsedAppNBCContext();
    // eslint-disable-next-line no-unused-vars
    const { error, execute } = useFetchWithMsal();

    const [isLoading, setIsLoading] = useState(true);
    //const [isExecuteFetch, setIsExecuteFetch] = useState(false);
    const [isInitialListDataLoaded, setIsInitialListDataLoaded] = useState(false);

    const [data, setData] = useState([]); 
    const [incidentTypeData, setIncidentTypeData] = useState(initialIncidentTypeData);

    const [searchCriteria, setSearchCriteria] = useState("");
    const [dateRangeState, setDateRangeState] = useState({startDate: "", endDate: ""});

    const [isShowEverything, setIsShowEverything] = useState(false);

    let incidentTypeInitialVal = 0;
    // eslint-disable-next-line no-unused-vars
    const [selectedIncidentType, setSelectedIncidentType] = useState(incidentTypeInitialVal);
    const [selectedMultiIncidentType, setSelectedMultiIncidentType] = useState("");

    const [isExecuteSearchPost, setIsExecuteSearchPost] = useState(false);
    const [isTextSearchExecuted, setIsTextSearchExecuted] = useState(false);

    //const [timer, setTimer] = useState(null);

    const handleSearchCriteraFieldOnChange = (e) => {
        setSearchCriteria(e.nativeEvent.srcElement.value);
        setIsTextSearchExecuted(true);
        setIsExecuteSearchPost(false);
    };

    const handleDateRangeFieldOnChange = (e) => {
        setDateRangeState({
            startDate: e.start,
            endDate: e.end,
        });
        setIsTextSearchExecuted(true);
        setIsExecuteSearchPost(false);
    };

    const handleMultiIncidentTypeOnChange = (values) => {
        if(values.length > 0) {
            const flatValues = values.map(item => item.value);

            var zeroExist = flatValues.filter(x => x === 0);
            const concatValues = zeroExist.length > 0 ? "" : flatValues.join("|");

            setSelectedMultiIncidentType(concatValues);
            setIsTextSearchExecuted(true);
            setIsExecuteSearchPost(false);
        } else {
            setSelectedMultiIncidentType("");
            setIsTextSearchExecuted(true);
            setIsExecuteSearchPost(false);
        }
    };

    const handleShowEverythingOnChange = (e) => {
        setIsShowEverything(e.target.checked);
        setIsTextSearchExecuted(true);
        setIsExecuteSearchPost(false);
    }

    const SearchPost = useCallback(() => {
        if(appNBCContext.dbUserId !== null) {
            setIsLoading(true);

            const {startDate, endDate } = dateRangeState;

            let _requestBody = {
                UserId: appNBCContext.dbUserId,
                IsSuperAdmin: appNBCContext.isSuperAdmin,
                IsAdmin: appNBCContext.isAdmin,
                SearchCriteria: searchCriteria,
                DateStart: startDate !== "" ? startDate.format("YYYY-MM-DD") : "", 
                DateEnd: endDate !== "" ? endDate.format("YYYY-MM-DD") : "",
                MultiIncidentType: selectedMultiIncidentType,
                IsShowEverything: isShowEverything
            }

            execute("PUT", protectedEndPointAPI.home.incident.map.search.list,_requestBody)
                .then((response) => {
                    var _data = response.data.data;
                    if(_data !== null) { 
                        if(_data.length > 0) {
                            let _listMapData = [];
                            _data.forEach((element) => {
                                if(element.address !== "") {
                                    _listMapData.push({...renderFeedMap(element)});
                                }
                            })
                            setData(_listMapData);
                            setIsLoading(false);
                        } else {
                            setData([]);
                            setIsLoading(false);
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setIsLoading(false);
                });

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[appNBCContext,searchCriteria,dateRangeState,selectedMultiIncidentType,isShowEverything])

    const renderFeedMap = useCallback((element) => {
        return {
            incidentId: element.id,
            key: element.incidentNum,
            imagePath: element.azureIncidentImages !== undefined ? 
                        element.azureIncidentImages.length > 0 ? 
                            element.azureIncidentImages[0].url  : ""
                       : "",
            imageBase64String: appNBCContext.isFileEncryptionEnable ? 
                               element.azureIncidentImages !== undefined ? 
                               element.azureIncidentImages.length > 0 ?
                                element.azureIncidentImages[0].fileBase64String : ""
                                : "" 
                               : "",
            lat: element.addressLatitude, 
            lng: element.addressLongitude,
            canEdit: element.canEdit,
          }
    },[appNBCContext])

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            setIsExecuteSearchPost(false);
            setIsExecuteSearchPost(true);
        }, 1500)
    
        return () => clearTimeout(delayDebounceFn)
    }, [searchCriteria,dateRangeState,selectedMultiIncidentType,isShowEverything])

    useEffect(() => {
        if(isExecuteSearchPost === true && isTextSearchExecuted === true) {
            SearchPost();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isExecuteSearchPost,isTextSearchExecuted])


    //Initial state of incident type data
    useEffect(() => {
        //[Start] - Populate incident type combo box
        execute("GET", protectedEndPointAPI.home.incident.type.v2.list)
            .then((response) => {
                var _data = response.data.data;
                
                if(_data !== null) { 
                    if(_data.length > 0) {
                        let sortData = _.sortBy(_data,"label")
                        sortData.unshift(initialIncidentTypeData[0]); //push the "All" at the beginning of the array
                        setIncidentTypeData(sortData);
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
        //[End] - Populate incident type combo box

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {
        if(!isInitialListDataLoaded) {
            if(props.initialListData !== undefined) {
                if(props.initialListData !== null) {
                   if(props.initialListData.length > 0) {
                        setData(props.initialListData);
                        setIsInitialListDataLoaded(true);
                   } 
                }
            }
            setIsLoading(false);
        }
    },[isInitialListDataLoaded, props.initialListData])



    return(<>
        <Container fluid>
            <Row>
                <Col>
                    <div className="input-group mb-12">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="basic-addon1">
                                <FontAwesomeIcon className="svgIconGrey" icon={faMagnifyingGlass} />
                            </span>
                        </div>
                        <input type="text" className="form-control" placeholder="Keywords search..." onChange={handleSearchCriteraFieldOnChange} value={searchCriteria} />
                    </div>
                </Col>
                <Col>
                    <CustomPicker SearchFilterDate={handleDateRangeFieldOnChange} />
                </Col>
                <Col xs lg="2">
                    <input type="checkbox" id="chckShowEverything" value={isShowEverything} checked={isShowEverything} onChange={handleShowEverythingOnChange} style={{cursor: "pointer", marginRight: "3px"}} />
                    <label className="form-check-label" htmlFor="chckShowEverything">Show Everything</label>
                </Col>
                <Col>
                    <MultiSelectWithSelectAll options={incidentTypeData} onSelectedValueChanged={handleMultiIncidentTypeOnChange} placeholder={"Select Case Type(s)..."}  />
                </Col>
            </Row>
            <Row>&nbsp;</Row>
            <Row>
                {isLoading ? (<AzureLoaderPost />) : ( <GoogleMapIncidentPost data={data} />)}
            </Row>
        </Container>
    </>)
}

export default AzureIncidentMapView;