import AzurePostApp from "./AzurePost"
import AzurePostEditApp from "./AzurePost.Edit";
import AzurePostViewApp from "./AzurePost.View";
import AzureSharePostApp from "./AzureSharePost"
import AzurePostModalApp from "./AzurePostModal";

export const AzurePost = () => {
    return (<AzurePostApp />);
}

export const AzurePostEdit = () => {
    return (<AzurePostEditApp />);
}

export const AzurePostView = () => {
    return (<AzurePostViewApp />);
}

export const AzurePostModal = () => {
    return (<AzurePostModalApp />);
}

export const AzureSharePost = () => {
    return (<AzureSharePostApp />);
}

