import React from "react";
import { Row } from "reactstrap";
import NoData from "../assets/any_incident.png"

export const AppInnerLoader = () => {
    return (
        <div style={{alignContent: "center", alignItems: "center", justifyContent: "center",display: "flex", flexDirection: "row"}}>
            <div class="spinner-border text-muted"></div>
        </div>
    )
}


export const UserNotYetSyncInner = () => {
    return (
        <> 
            <Row><br /></Row>
            <Row> <img src={NoData} className="anypostimg" alt="" /> </Row>
            <Row><br /></Row>
            <Row>
                <label className="lblnochats">Your user data is not yet synchronize. Please contact your administrator.</label>
            </Row>
            <Row><br /></Row>
        </>
    )
}

export const UserInactiveSyncInner = () => {
    return (
        <> 
            <Row><br /></Row>
            <Row> <img src={NoData} className="anypostimg" alt="" /> </Row>
            <Row><br /></Row>
            <Row>
                <label className="lblnochats">Your user account has been deactivated. Please contact your administrator.</label>
            </Row>
            <Row><br /></Row>
        </>
    )
}