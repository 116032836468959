import React, { useState, useEffect, useCallback } from "react";
import {  useHistory } from 'react-router-dom';
import Avatar from 'react-avatar';
import { ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import { PopoverBody, UncontrolledPopover,Button, Modal, Row, Col } from 'reactstrap';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { faEdit, faTrash, faSearch } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Pagination from "../../../components/Common/pagination";
import exportFromJSON from 'export-from-json';

import "./AzureAccount.css";
import ModalProfileCard from "../../../components/Profile/ModalProfileCard";
import dotsMore from "../../../assets/dotsMore.png";
import iconexport from "../../../assets/export.png";
import AzureAccountsOption from "./AzureAccounts.Option";
import AzureAccountsModal from "./AzureAccounts.Modal";
import { 
    NoResultFoundContainer
    ,PostShimmeringEffect 
} 
from "../../../components/Department/Department.Style";
import StatusBadge from "../../../components/Officer/StatusBadge";
import MultiSelectWithSelectAll from "../../../components/Common/multiselect";

import { InitialDepartmentData
    ,InitialDivisionData
    ,InitialStatusData } from "./AzureAccounts.Style";

import { UsedAppNBCContext } from "../../AppNBC.Provider";
import useFetchWithMsal from "../../hooks/useFetchWithMsal";
import { protectedEndPointAPI } from "../../Helpers/ProtectedCustomClass";

library.add(faEdit, faTrash, faSearch);


const AzureAccounts = (props) => {

    const appNBCContext = UsedAppNBCContext();
    // eslint-disable-next-line no-unused-vars
    const { error, execute } = useFetchWithMsal();

    let history = useHistory();
    const fileName = 'download'
    const exportType = 'xls'

    let superAdminAllowedSuspend = false;
    let allowedSuspend = true;
    let isSuperAdmin = false;

    if(appNBCContext.isSuperAdmin) {
        superAdminAllowedSuspend = true;
    }

    const [division, setDivision] = useState("");
    const [status, setStatus] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [isExportListLoading, setIsExportListLoading] = useState(false);
    const [modalInformation, setModalInformation] = useState(false);
    const [departament, setDepartament] = useState("");

    // eslint-disable-next-line no-unused-vars
    const [selecteduser, setSelectedUser] = useState({});
    const [search, setSearch] = useState("");
    const [modalDivisionList, setModalDivisionList] = useState([]);
    const [listDepartments, setListDepartments] = useState([]);
    const [liststatuses, setListStatuses] = useState([]);
    const [openAccountModal, setOpenAccountModal] = useState(false);
    const [dataAccount, setDataAccount] = useState({});
    const [isDeleteExecuted, setIsDeleteExecuted] = useState(false);
    const [isSuspendAccountExecuted, setIsSuspendAccountExecuted] = useState(false);
    const [isUnSuspendAccountExecuted, setIsUnSuspendAccountExecuted] = useState(false);
    const [isRestoreExecuted, setIsRestoreExecuted] = useState(false);

    const [departmentData, setDepartmentData] = useState(InitialDepartmentData);
    const [divisionData, setDivisionData] = useState(InitialDivisionData);
    const [statusData, setStatusData] = useState(InitialStatusData);
    const [selectedMultiDepartment, setSelectedMultiDepartment] = useState("");
    const [selectedMultiDivision, setSelectedMultiDivision] = useState("");
    const [selectedMultiStatus, setSelectedMultiStatus] = useState("-2"); //all statuses
    const [isInitialLoadDataDone, setIsInitialLoadDataDone] = useState(false);
    const [isHandlePageExecutedDone, setIsHandlePageExecutedDone] = useState(false);
    const [isAfterCreateEditOfficer, setIsAfterCreateEditOfficer]= useState(false);

    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
    const [isModalSuspendOpen, setIsModalSuspendOpen] = useState(false);
    const [isModalUnSuspendAccountOpen, setIsModalUnSuspendAccountOpen] = useState(false);
    const [isModalRestoreOpen, setIsModalRestoreOpen] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);

    const [pageData, setPageData] = useState({
        rowData: [],
        isLoading: false,
        totalPages: 0,
        totalData: 0,
    });

    const [modalDelete, setModalDelete] = useState({
        show: false,
        id: "",
        name: ""
    });

    const [modalSuspend, setModalSuspend] = useState({
        show: false,
        id: "",
        name: ""
    });

    const [modalUnSuspend, setModalUnSuspend] = useState({
        show: false,
        id: "",
        name: ""
    });

    const [modalRestoreAccount, setModalRestoreAccount] = useState({
        show: false,
        id: "",
        name: ""
    });

    const toggleDeleteShown = (account) => {
        setModalDelete({
            ...modalDelete,
            show: true,
            id: account.id,
            name: account.name
        });
        setIsModalDeleteOpen(true);
    }

    const toggleSuspend = (account) => {
        setModalSuspend({
            ...modalSuspend,
            show: !modalSuspend.show,
            id: account.id,
            name: account.name
        });
    }

    const toggleSuspendShown = (account) => {
        setModalSuspend({
            ...modalSuspend,
            show: true,
            id: account.id,
            name: account.name
        });
        setIsModalSuspendOpen(true);
    }

    const toggleUnSuspend = (account) => {
        setModalUnSuspend({
            ...modalUnSuspend,
            show: !modalUnSuspend.show,
            id: account.id,
            name: account.name
        });

    }
    const toggleUnSuspendShown = (account) => {
        setModalUnSuspend({
            ...modalUnSuspend,
            show: true,
            id: account.id,
            name: account.name
        });
        setIsModalUnSuspendAccountOpen(true);
    }

    const toggleRestoreAccount = (account) => {
        setModalRestoreAccount({
            ...modalRestoreAccount,
            show: !modalRestoreAccount.show,
            id: account.id,
            name: account.name
        });
    }

    const toggleRestoreAccountShown = (account) => {
        setModalRestoreAccount({
            ...modalRestoreAccount,
            show: true,
            id: account.id,
            name: account.name
        });
        setIsModalRestoreOpen(true);
    }

    const RedirectMessage = (state, userId, name, type, urlPic,phone, email, address, typechat) => {
        if (state != null && (state === "Suspended" || state === "Removed")) {
            NotificationManager.info('Information', 'The account cannot be contacted because it was deleted or suspended.', 5000);
            return;
        }

        let img = urlPic;
        if (props.Incident !== undefined && (props.Incident.filesIncidents != null && props.Incident.filesIncidents !== undefined && props.Incident.filesIncidents.length > 0)) {
            img = props.Incident.filesIncidents[0].file.path;
        }

        history.push({
            pathname: `/message`,
            search: `?userId=${userId}&name=${name}&type=${props.Incident !== undefined ? props.Incident.type : type}&typechat=${typechat}&incident=${props.Incident !== undefined ? props.Incident.id : ""}&address=${props.Incident !== undefined ? props.Incident.address : ""}`,
            state: {
                urlPic: img,
                userId: userId,
                name: name,
                type: (props.Incident !== undefined ? props.Incident.type : type),
                typechat: typechat,
                incident: props.Incident !== undefined ? props.Incident.id : "",
                address: props.Incident !== undefined ? props.Incident.address : "",
                phone: phone,
                email: email,
                userAddress: address
            }

        });

    }

    const DeleteAccount = useCallback((id) => {
        
        if(appNBCContext.dbUserId !== null) {
            let _requestBody = {
                UserId: id,
                IsSuperAdmin: appNBCContext.isSuperAdmin,
                IsAdmin: appNBCContext.isAdmin,
            }

            execute("POST", protectedEndPointAPI.accounts.delete, _requestBody).then((response) => {
                setIsModalDeleteOpen(false);
                setIsDeleteExecuted(true);
                var _data = response.data;
                if(_data !== null) { 
                    NotificationManager.success('Success', 'THE ACCOUNT WAS DEACTIVATED', 5000);
                }
            });

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[appNBCContext, execute])

    const SuspendAccount = useCallback((id) => {
        if(appNBCContext.dbUserId !== null) {
            let _requestBody = {
                UserId: id,
                IsSuperAdmin: appNBCContext.isSuperAdmin,
                IsAdmin: appNBCContext.isAdmin,
            }

            execute("POST", protectedEndPointAPI.accounts.suspend, _requestBody).then((response) => {
                var _data = response.data;
                setIsModalSuspendOpen(false);
                setIsSuspendAccountExecuted(true);
                if(_data !== null) { 
                    NotificationManager.success('Success', 'THE ACCOUNT WAS SUSPENDED', 5000);
                }
            });

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[appNBCContext, execute])

    const UnSuspendAccount = useCallback((id) => {
        if(appNBCContext.dbUserId !== null) {
            let _requestBody = {
                UserId: id,
                IsSuperAdmin: appNBCContext.isSuperAdmin,
                IsAdmin: appNBCContext.isAdmin,
            }

            execute("POST", protectedEndPointAPI.accounts.unsuspend, _requestBody).then((response) => {
                var _data = response.data;
                setIsModalUnSuspendAccountOpen(false);
                setIsUnSuspendAccountExecuted(true);
                if(_data !== null) { 
                    NotificationManager.success('Success', 'THE ACCOUNT WAS UNSUSPENDED', 5000);
                }
            });

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[appNBCContext, execute])

    const RestoreAccount = useCallback((id) => {
        if(appNBCContext.dbUserId !== null) {
            let _requestBody = {
                UserId: id,
                IsSuperAdmin: appNBCContext.isSuperAdmin,
                IsAdmin: appNBCContext.isAdmin,
            }

            execute("POST", protectedEndPointAPI.accounts.restore, _requestBody).then((response) => {
                var _data = response.data;
                setIsModalRestoreOpen(false);
                setIsRestoreExecuted(true);
                if(_data !== null) { 
                    NotificationManager.success('Success', 'THE ACCOUNT WAS RESTORED', 5000);
                }
            });

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[appNBCContext, execute])

    const EditFunction = data => {
        setOpenAccountModal(true);
        setDataAccount({...data, isView: false});
    }

    const ViewFunction = data => {
        setOpenAccountModal(true);
        setDataAccount({...data, isView: true});
    }

    const AddOfficer = data => {
        setDataAccount({});
        setOpenAccountModal(true);

    }

    const CloseModal = () => {
        setOpenAccountModal(false);
    }

    const toggleModal = () => {
        setModalInformation(!modalInformation);
    };

    const [isExecuteSearchPost, setIsExecuteSearchPost] = useState(false);
    const [isTextSearchExecuted, setIsTextSearchExecuted] = useState(false);

    const ChangeSearch = (e) => {
        setSearch(e.target.value);
        setIsTextSearchExecuted(true);
        setIsExecuteSearchPost(false);
    }

    const handleMultiDepartmentOnChange = useCallback((values) => {
        if(values.length > 0) {
            const flatValues = values.map(item => item.value);

            var zeroExist = flatValues.filter(x => x === 0);
            const concatValues = zeroExist.length > 0 ? "" : flatValues.join("|");
            setSelectedMultiDepartment(concatValues);

            setIsLoading(true);
            getData(currentPage, division, "", status, search,concatValues,selectedMultiDivision,selectedMultiStatus).then((info) => {
                if(info !== undefined) {
                    const { totalPages, data, totalData } = info.data;
                    setPageData({
                        isLoading: false,
                        rowData: data,
                        totalPages,
                        totalData,
                    });
                }
                setIsLoading(false);
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[currentPage,division,search,status,selectedMultiDivision,selectedMultiStatus]);

    const handleMultiDivisionOnChange = useCallback((values) => {
        if(values.length > 0) {
            const flatValues = values.map(item => item.value);
            var zeroExist = flatValues.filter(x => x === 0);
            const concatValues = zeroExist.length > 0 ? "" : flatValues.join("|");
            setSelectedMultiDivision(concatValues);

            setIsLoading(true);
            getData(currentPage, division, "", status, search,selectedMultiDepartment,concatValues,selectedMultiStatus)
            .then((info) => {
                if(info !== undefined) {
                    const { totalPages, data, totalData } = info.data;
                    setPageData({
                        isLoading: false,
                        rowData: data,
                        totalPages,
                        totalData,
                    });
                }
                setIsLoading(false);
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[currentPage,division,search,status,selectedMultiDepartment,selectedMultiStatus]);

    const handleMultiStatusOnChange = useCallback((values) => {
        if(values.length > 0) {
            const flatValues = values.map(item => item.value);
            // var zeroExist = flatValues.filter(x => x === 0);
            const concatValues = flatValues.join("|");
            setSelectedMultiStatus(concatValues);

            setIsLoading(true);
            getData(currentPage, division, "", status, search,selectedMultiDepartment,selectedMultiDivision,concatValues).then((info) => {
                if(info !== undefined) {
                    const { totalPages, data, totalData } = info.data;
                    setPageData({
                        isLoading: false,
                        rowData: data,
                        totalPages,
                        totalData,
                    });

                }
                setIsLoading(false);
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[currentPage,division,search,status,selectedMultiDepartment,selectedMultiDivision]);

    const handlePageChange = (e) => {
        if(isInitialLoadDataDone) {
            setCurrentPage(e);
            setIsHandlePageExecutedDone(true);
        }
    }

    const AfterCreateEditOfficer = (isSuccess, isEdit) => {
        let _msgInfoSuccess = isEdit ? 'OFFICER INFORMATION UPDATED' : 'NEW OFFICER CREATED';
        let _msgInfoError = isEdit ? 'ERROR OCCURS WHEN OFFICER INFORMATION UPDATED.' : 'ERROR OCCURS WHEN OFFICER INFORMATION CREATED';
        CloseModal();
        if(isSuccess) {
            NotificationManager.success('Success', _msgInfoSuccess, 5000);
        } else {
            NotificationManager.error(_msgInfoError, 5000);
        }
        setIsAfterCreateEditOfficer(true);
    }

    const getData = useCallback(async (pageNo = 1, division = "", department = "", status ="", search = ""
                                        , multidepartment ="",multidivision ="", multistatus="-2") => {
        if(appNBCContext.dbUserId !== null) {
            let _requestBody = {
                UserId: appNBCContext.dbUserId,
                IsSuperAdmin: appNBCContext.isSuperAdmin,
                IsAdmin: appNBCContext.isAdmin,
                Page: pageNo,
                Take: 10,
                DivisionId: division === "" ? 0 : division,
                DepartmentId: department === "" ? 0 : division,
                AppStatus: status === "" ? 0 : status,
                SearchCriteria: search,
                MultiDepartment: multidepartment,
                MultiDivision: multidivision,
                MultiStatus: multistatus
            }
            return  execute("PUT", protectedEndPointAPI.accounts.information.list.base, _requestBody);

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[appNBCContext, execute])

    const ExportToExcel = useCallback(async () => {
        if(appNBCContext.dbUserId !== null) {
            let _requestBody = {
                UserId: appNBCContext.dbUserId,
                IsSuperAdmin: appNBCContext.isSuperAdmin,
                IsAdmin: appNBCContext.isAdmin,
                DivisionId: division,
                DepartmentId: departament,
                AppStatus: status,
                SearchCriteria: search,
                MultiDepartment: selectedMultiDepartment,
                MultiDivision: selectedMultiDivision,
                MultiStatus: selectedMultiStatus
            }

            execute("PUT", protectedEndPointAPI.accounts.information.list.export, _requestBody).then((response) => {
                var _data = response.data;
                if(_data !== null)  {
                    var _innerData = _data.data;
                    exportFromJSON({ _innerData, fileName, exportType })
                }
            });

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[appNBCContext, execute,division,departament,status,search,selectedMultiDepartment,selectedMultiDivision,selectedMultiStatus])

    const reloadData = useCallback(() => {
        setIsLoading(true);
        getData(currentPage, division, departament, status, search,selectedMultiDepartment,selectedMultiDivision,selectedMultiStatus)
        .then((info) => {
            if(info !== undefined) {
                const { totalPages, data, totalData } = info.data;
                setPageData({
                    isLoading: false,
                    rowData: data,
                    totalPages,
                    totalData,
                });
            }
            setIsLoading(false);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[currentPage, division, departament, status, search,selectedMultiDepartment,selectedMultiDivision,selectedMultiStatus])


    //Set some delay when typing
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            setIsExecuteSearchPost(false);
            setIsExecuteSearchPost(true);
        }, 1500)
    
        return () => clearTimeout(delayDebounceFn)
      }, [search])

      useEffect(() => {
        if(isExecuteSearchPost === true && isTextSearchExecuted === true) {
            reloadData();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isExecuteSearchPost,isTextSearchExecuted,isDeleteExecuted])

    useEffect(() => {
        if(isDeleteExecuted) {
            setIsDeleteExecuted(false);
            reloadData();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isDeleteExecuted]);

    useEffect(() => {
        if(isSuspendAccountExecuted) {
            setIsSuspendAccountExecuted(false);
            reloadData();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isSuspendAccountExecuted]);

    useEffect(() => {
        if(isUnSuspendAccountExecuted) {
            setIsUnSuspendAccountExecuted(false);
            reloadData();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isUnSuspendAccountExecuted]);

    useEffect(() => {
        if(isRestoreExecuted) {
            setIsRestoreExecuted(false);
            reloadData();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isRestoreExecuted]);

    useEffect(() => {
        if(isAfterCreateEditOfficer) {
            setIsAfterCreateEditOfficer(false);
            reloadData();
            setDataAccount({});
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isAfterCreateEditOfficer] );


    useEffect(() => {
        if(isHandlePageExecutedDone && isInitialLoadDataDone) {
            setPageData((prevState) => ({
                ...prevState,
                rowData: [],
                isLoading: true,
            }));
            setIsLoading(true);
            getData(currentPage, division, departament, status, search,selectedMultiDepartment,selectedMultiDivision,selectedMultiStatus)
            .then((info) => {
                if(info !== undefined) {
                    const { totalPages, data, totalData } = info.data;
            
                    setPageData({
                        isLoading: false,
                        rowData: data,
                        totalPages,
                        totalData,
                    });

                }
                
                setIsLoading(false);
            });
            setIsHandlePageExecutedDone(false);
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage,isHandlePageExecutedDone,isInitialLoadDataDone,division, departament, status, search,selectedMultiDepartment,selectedMultiDivision,selectedMultiStatus]);


    useEffect(() => {
        setPageData((prevState) => ({
            ...prevState,
            rowData: [],
            isLoading: true,
        }));
        setIsLoading(true);

        execute("GET", protectedEndPointAPI.accounts.division.list).then((response) => {
            var _data = response.data;
            if(_data !== null)  {
                let tempResult = [];
                tempResult.push(InitialDivisionData[0]);
                
                let tempResultForModal = []

                _data.forEach(item => {
                    tempResult.push({value: item.id, label: item.name});
                    tempResultForModal.push({value: item.id, label: item.name});
                })
                setDivisionData(tempResult);
                setModalDivisionList(tempResultForModal);
            };
        });

        execute("GET", protectedEndPointAPI.accounts.status.list).then((response) => {
            var _data = response.data;
            if(_data !== null)  {
                let _data = response.data
                    _data.splice(1, 0, {id: -1, name: "Removed"});
                    var _newData = _data.filter(item => item.id !== 1); //Remove suspended on the selection

                    let tempResult = [];
                    tempResult.push(InitialStatusData[0]);

                    _newData.forEach(item => {
                        tempResult.push({value: item.id, label: item.name});
                    })

                    setStatusData(tempResult);
            };
        });

        let _requestBody = {
            UserId: appNBCContext.dbUserId,
            IsSuperAdmin: appNBCContext.isSuperAdmin,
            IsAdmin: appNBCContext.isAdmin,
        }

        execute("PUT", protectedEndPointAPI.accounts.department.list, _requestBody).then((response) => {
            var _data = response.data;
            if(_data !== null)  {
                let tempResult = [];
                tempResult.push(InitialDepartmentData[0]);

                let tempResultForModal = []
                
                _data.forEach(item => {
                    tempResult.push({value: item.id, label: item.name});
                    tempResultForModal.push({value: item.id, label: item.name});
                })
                setDepartmentData(tempResult);
                setListDepartments(tempResultForModal);
            };
        });

        getData(1).then((info) => {

            if(info !== undefined) {
                const { totalPages, data, totalData } = info.data;
            
                setPageData({
                    isLoading: false,
                    rowData: data,
                    totalPages,
                    totalData,
                });
    

            }
            
            
            setIsLoading(false);
        });
        setIsInitialLoadDataDone(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div>
                <div className="row">
                    <div className="col-10"> 
                        <h3 className="legendpost">Accounts</h3>
                    </div>
                    {isExportListLoading && (
                    <div id="exportButtonContainer" className="col-2"  >
                        <label className="lblexport" >Exporting list...&nbsp;</label> 
                        <span className="spinner-border spinner-border-sm"></span>
                    </div>)}
                    {!isExportListLoading && (
                    <div id="exportButtonContainer" className="col-2" onClick={ExportToExcel} >
                        <label className="lblexport" >Export list &nbsp;</label> 
                        <img alt="" src={iconexport} className="iconexport" />
                    </div>)}
                </div>
                <div className="row rowaccount">
                    {appNBCContext.isSuperAdmin ? 
                    (
                        departmentData.length > 1 &&
                        <div className="col-3">
                            <MultiSelectWithSelectAll options={departmentData} placeholder={InitialDepartmentData[0].label} onSelectedValueChanged={handleMultiDepartmentOnChange} />
                        </div>
                    ) 
                    : 
                    (<></>)}

                    <div className="col-2">
                        <MultiSelectWithSelectAll options={divisionData} placeholder={InitialDivisionData[0].label} onSelectedValueChanged={handleMultiDivisionOnChange} />
                    </div>
                    <div className="col-2">
                        <MultiSelectWithSelectAll options={statusData} placeholder={InitialStatusData[0].label} onSelectedValueChanged={handleMultiStatusOnChange} defaultAllValue={InitialStatusData[0].value} />
                    </div>

                    <div className="col-3">


                        <div className="input-group mb-12">
                            <span className="input-group-prepend">
                                <div className="input-group-text  border-right-0"> <FontAwesomeIcon icon={faSearch} /></div>
                            </span>
                            <input type="text" className="form-control" placeholder="Search officers..." onChange={ChangeSearch} value={search} />

                            <span className="input-group-append">

                            </span>
                        </div>
                    </div>
                    {/** NBC CJIS - 4952 - Remove adding officer within the app. **/}
                    {/* <div className="col-2">
                        <button className="btn btn-primary" onClick={AddOfficer}>+ Add Officer</button>
                    </div> */}


                </div>

                <div>
                    <br/><br/>
                    <div className="row">
                        {isLoading &&

                            <div>
                                <div style={{ marginBottom: "25px" }}><PostShimmeringEffect /></div>
                                <div style={{ marginBottom: "25px" }}><PostShimmeringEffect /></div>
                            </div>

                        }
                        
                        {!isLoading &&
                        <>
                        {pageData.rowData.map((item) => (
                            <div className="col-6" key={['idoffice', item.id]}>
                                <div className="row  card-account">

                                    <div className="col-3 card no-mgr"  style={{marginRight: "10px"}}>
                                        <div className="row" style={{flex: "1", alignItems:"center", justifyContent:"center", marginRight:"20px", marginTop:"20px"}}>

                                            {appNBCContext.isFileEncryptionEnable ? 
                                                  (item.urlPic && <Avatar src={`data:image/jpeg;base64,${item.profilePicBase64String}`} size="80" round className="avataraccountimg" />) 
                                                : (item.urlPic && <Avatar src={item.urlPic} size="80" round className="avataraccountimg" />)
                                            }

                                            {!item.urlPic &&
                                                <Avatar name={item.name} size="80" round className="avataraccounttext" />

                                            }
                                        </div>
                                        <div className="row" style={{flex: "1", alignItems:"center", justifyContent:"center", fontFamily: "Work Sans"}}>
                                            <button type="button" 
                                                    disabled={item.deleted ? true : item.statusApp === "Suspended" ? true : false}
                                                    className="btn btn-outline-primary btn-lg btn-bloc col-lg-10" 
                                                    onClick={() => RedirectMessage(item.statusApp,item.id, item.name,item.division,item.urlPic,item.phone, item.email, item.address, "user")}>
                                                <div style={{fontSize: "14px"}}>Message</div>
                                            </button>
                                        </div>
                                    </div>

                                    <div className="col-8 card bg-white no-mgl">
                                        {/**
                                         * Just return the value to "2" when statusApp is Suspended
                                         * Currently, as instructed, even if the status is Suspended it should shows "Remove" status
                                         * **/}
                                        <div className="row" style={{textAlign: "right", paddingRight: "15px"}}><span>{StatusBadge(item.deleted ? 3 : item.statusApp === "Suspended" ? 3 : 1)}</span></div>
                                        <div className="row">
                                            <div className="col-6"> <label className="titlename">{item.name}</label></div>
                                            <div className="col-4"></div>
                                            <div className="col-2">
                                            <button id={`but${item.id}`} className="btn btn-default btnMore" style={{border: "0px"}}><img alt="" src={dotsMore} /></button>
                                            <UncontrolledPopover
                                                trigger="legacy"
                                                placement="bottom"
                                                target={`but${item.id}`}
                                            >
                                                {
                                                    !superAdminAllowedSuspend ? allowedSuspend = false : allowedSuspend = true
                                                }
                                                <PopoverBody>
                                                    <AzureAccountsOption 
                                                        data={item} 
                                                        EditFunction={EditFunction} 
                                                        ViewFunction={ViewFunction} 
                                                        DeleteFunction={toggleDeleteShown}
                                                        SuspendFunction={toggleSuspendShown} 
                                                        UnSuspendFunction={toggleUnSuspendShown}
                                                        RestoreAccount={toggleRestoreAccountShown} 
                                                        AllowedSuspend={allowedSuspend} >
                                                    </AzureAccountsOption>
                                                </PopoverBody>
                                            </UncontrolledPopover>
                                        </div></div>
                        
                                        <div className="row">  <label className="lblinfo">Division:  <span className="txtinfo">{item.division}</span></label></div>
                                        <div className="row">  <label className="lblinfo">Department:  <span className="txtinfo">{item.department}</span></label></div>
                                        <div className="row">   <label className="lblinfo">Phone:   <span className="txtinfo"><a href={`tel:${item.phone ?? ""}`}>{item.phone}</a></span></label></div>
                                        <div className="row">  <label className="lblinfo">Email: <span className="txtinfo"><a href={`mailto:${item.email}`}>{item.email}</a></span></label> </div>
                                        <div className="row">  <label className="lblinfo">Address: <span className="txtinfo">{item.address}</span></label> </div>




                                    </div>
                                </div>
                                <br/>
                            </div>
                        ))}

                        {pageData.totalData === 0 &&

                                <NoResultFoundContainer message={"No results found. Try another keywords..."} />
                            }
                            </>
                        }
                    </div>
                </div>
                <Pagination
                    totalRows={pageData.totalData}
                    pageChangeHandler={handlePageChange}
                    rowsPerPage={10}
                />
            </div>
            <Modal size="lg" isOpen={modalInformation} className="modalprofile">
                <ModalBody>
                    <Row>

                        <Col sm="12" className="containerprofile">
                            <ModalProfileCard user={selecteduser} ></ModalProfileCard>
                        </Col>

                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={toggleModal}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal size="md" isOpen={openAccountModal}>
                <AzureAccountsModal reloadData={reloadData} account={dataAccount} departments={listDepartments} divisions={modalDivisionList} closeModal={CloseModal} AfterCreateEditOfficer={AfterCreateEditOfficer} />
            </Modal>
            <Modal size="md" isOpen={isModalDeleteOpen}>
                <ModalHeader>
                    Deactivate Account
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <label >Are you sure you want to deactivate this account?</label>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => DeleteAccount(modalDelete.id)}
                    >
                        Deactivate
                    </Button>
                    {' '}
                    <Button onClick={() => setIsModalDeleteOpen(false)}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal size="md" isOpen={isModalSuspendOpen}>
                <ModalHeader>
                    Suspend Account
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <label >Are you sure you want to suspend this account?</label>
                    </Row>

                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => SuspendAccount(modalSuspend.id)}
                    >
                        Suspend
                    </Button>
                    {' '}
                    <Button onClick={() => setIsModalSuspendOpen(false)}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal size="md" isOpen={isModalUnSuspendAccountOpen}>
                <ModalHeader>
                    Unsuspend Account
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <label >Are you sure you want to unsuspend this account?</label>
                    </Row>

                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => UnSuspendAccount(modalUnSuspend.id)}
                    >
                        Unsuspend
                    </Button>
                    {' '}
                    <Button onClick={() => setIsModalUnSuspendAccountOpen(false)}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>

           

            <Modal size="md" isOpen={isModalRestoreOpen}>
                <ModalHeader>
                    Restore Account
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <label >Are you sure you want to restore this account?</label>
                    </Row>

                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => RestoreAccount(modalRestoreAccount.id)}
                    >
                        Restore
                    </Button>
                    {' '}
                    <Button onClick={() => setIsModalRestoreOpen(false)}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>


           

            <NotificationContainer />
        </>
    );
}

export default AzureAccounts