import { protectedResources } from "../../../authConfig";

export const protectedEndPointAPI = {
    base: protectedResources.api.endpoint,

    get accounts() {
      return {
        base: `${this.base}/accounts`,
        get delete() {
          return `${this.base}/delete`
        },
        get department() {
          return {
            base: `${this.base}/department`,
            get image() {
              return {
                base: `${this.base}/image`,
                get save() {
                  return `${this.base}/save`;
                },
              }
            },
            get list() {
              return `${this.base}/list`;
            },
          }
        },
        get division() {
          return {
            base: `${this.base}/division`,
            get list() {
              return `${this.base}/list`;
            },
          }
        },
        get status() {
          return {
            base: `${this.base}/status`,
            get list() {
              return `${this.base}/list`;
            },
          }
        },
        get officers() {
          return {
            base: `${this.base}/officers`,
            get status() {
              return {
                base: `${this.base}/status`,
                get get() {
                  return `${this.base}/get`;
                },
              }
            },
          }
        },
        get privacy() {
          return { 
            base: `${this.base}/privacy`,
            get save() {
              return `${this.base}/save`;
            },
          };
        },
        get profile() {
          return { 
            base: `${this.base}/profile`,
            get save() {
              return `${this.base}/save`;
            },
            get information() {
              return {
                base: `${this.base}/information`,
                get get() {
                  return `${this.base}/get`;
                },
              }
            },
          };
        },
        get suspend() {
          return `${this.base}/suspend`
        },
        get unsuspend() {
          return `${this.base}/unsuspend`
        },
        get restore() {
          return `${this.base}/restore`
        },
        get save() {
          return `${this.base}/save`
        },
        get sync() {
          return `${this.base}/sync`
        },
        get bookmark() {
          return {
            base: `${this.base}/bookmark`,
            get delete() {
              return `${this.base}/delete`;
            },
          }
        },
        get information() {
          return {
            base: `${this.base}/information`,
            get list() {
              return {
                base :`${this.base}/list`,
                get export() {return `${this.base}/export`}
              }
            },
          }
        },
        get officer() {
          return {
            base: `${this.base}/officer`,
            get new() {
              return `${this.base}/new`
            },
            get update() {
              return `${this.base}/update`
            }
          }
        },
      };
    },

    get agencies() {
      return {
        base: `${this.base}/agencies`,
        get user() {
          return { 
            base: `${this.base}/user`,
            get getlist() {
              return `${this.base}/list/get`
            },
            get update() {
              return `${this.base}/update`
            },
            get updatelist() {
              return `${this.base}/list/update`
            },
          };
        },
        get delete() {
          return `${this.base}/delete`
        },
        get restore() {
          return `${this.base}/restore`
        },
        get list() {
          return {
            base: `${this.base}/list`,
            get get() {return `${this.base}/get`},
            get exist() {return `${this.base}/exist`},
          }
        },
        get mylist() {
          return `${this.base}/list/my`
        },
        get request() {
          return {
            base: `${this.base}/request`,
            get delete() {
              return `${this.base}/delete`
            },
          }
        },
        get subscribe() {
          return `${this.base}/subscribe`
        },
        get unsubscribe() {
          return `${this.base}/unsubscribe`
        },
        get information() {
          return {
            base: `${this.base}/information`,
            get save() {
              return `${this.base}/save`
            }
          }
        },
        get profile() {
          return {
            base: `${this.base}/profile`,
            get image() {
              return {
                base: `${this.base}/image`,
                get save() {return `${this.base}/save`}
                
              }
              
            }
          }
        },
      
        get azuregroups() {
          return {
            base: `${this.base}/azuregoups`,
            get sync() {
              return `${this.base}/sync`
            }
          }
        },
      
      };
    },

    get department() {
      return {
        base: `${this.base}/department`,
        get search() {
          return { 
            base: `${this.base}/search`,
            get list() {
              return `${this.base}/list`;
            },
          };
        },
        get agency() {
          return { 
            base: `${this.base}/agency`,
            get list() {
              return `${this.base}/list`;
            },
          };
        },
        get incident() {
          return { 
            base: `${this.base}/incident`,
            get delete() {
              return `${this.base}/delete`;
            },
          };
        },

      };
    },

    get home() {
      return {
        base: `${this.base}/home`,
        get incident() {
          return {
            base: `${this.base}/incident`,

            get getlistbypage() {
              return `${this.base}/list/getbypage`;
            },

            get getfolderlist() {
              return `${this.base}/folder/list`;
            },

            get getbyid() {
              return `${this.base}/get/byid`;
            },

            get notification() {
              return { 
                base: `${this.base}/notification`,
                get getlistbyid() {
                  return `${this.base}/list/getbyid`;
                },
                get set() {
                  return `${this.base}/set`;
                },
                get settings() {
                  return { 
                    base: `${this.base}/settings`, 
                    get get() {return `${this.base}/get`}}
                },
              };
            },

            get search() {
              return { 
                base: `${this.base}/search`,
                get mypost() {
                  return `${this.base}/mypost`;
                },
                get mydepartment() {
                  return `${this.base}/mydepartment`;
                },
                get list() {
                  return `${this.base}/list`;
                },
                get mypostlist() {
                  return `${this.base}/mypost/list`;
                },
                get mydepartmentlist() {
                  return `${this.base}/mydepartment/list`;
                },
                get relatedlist() {
                  return `${this.base}/related/list`;
                },
              };
            },

            get delete() {
              return { 
                base: `${this.base}/delete`,
              };
            },

            get related() {
              return { 
                base: `${this.base}/related`,
                get delete() {
                  return `${this.base}/delete`;
                },
              };
            },

            get report() {
              return { 
                base: `${this.base}/report`,
                get post() {
                  return `${this.base}/post`;
                },
              };
            },

            get share() {
              return { 
                base: `${this.base}/share`,
                get post() {
                  return `${this.base}/post`;
                },
              };
            },

            get bookmark() {
              return { 
                base: `${this.base}/bookmark`,
                get related() {
                  return {
                    base: `${this.base}/related`,
                    get list() {
                      return `${this.base}/list`;
                    },
                    get save() {
                      return `${this.base}/save`;
                    },
                  }
                },
                get save() {
                  return `${this.base}/save`;
                },
              };
            },

            get information() {
              return { 
                base: `${this.base}/information`,
                get save() {
                  return `${this.base}/save`;
                },
              };
            },

            get type() {
              return { 
                base: `${this.base}/type`,
                get v2() {
                  return {
                    base: `${this.base}/v2`,
                    get list() {
                      return `${this.base}/list`;
                    },
                  }
                },
                get list() {
                  return `${this.base}/list`;
                },
              };
            },

            get category() {
              return { 
                base: `${this.base}/category`,
                get list() {
                  return `${this.base}/list`;
                },
              };
            },

            get map() {
              return { 
                base: `${this.base}/map`,
                get search() {
                  return {
                    base: `${this.base}/search`,
                    get list() {
                      return `${this.base}/list`;
                    },
                  }
                },
              };
            },

          };
        },

        get folder() {
          return {
            base: `${this.base}/folder`,
            get getlist() {
              return `${this.base}/list`;
            },
          }
        }

      }
    },

    get message() {
      return {
        base: `${this.base}/message`,
        get chat() {
          return {
            base: `${this.base}/chat`,
            get list() {return `${this.base}/list`},
            get sendmessage() {return `${this.base}/sendmessage`},
            get getgroupchat() {return `${this.base}/getgroupchat`},
            get getmessages() {return `${this.base}/getmessages`} 
          }
        }
      }
    },

    get officer() {
      return {
        base: `${this.base}/officer`,
        get getuserid() {
          return `${this.base}/getuserid`;
        },
        get getlist() {
          return `${this.base}/getlist`;
        },
        get getlistforagency() {
          return `${this.base}/list/foragency`;
        },
        get department() {
          return {
            base: `${this.base}/department`,
            get list() {return `${this.base}/list`;}
          }
        },
        get division() {
          return {
            base: `${this.base}/division`,
            get list() {return `${this.base}/list`;}
          }
        },
        get agency() {
          return {
            base: `${this.base}/agency`,
            get email() {
              return {
                base: `${this.base}/email`,
                get list() {return `${this.base}/list`;}
              }
           }
          }
        }
      };
    },

    get profile() {
      return {
        base: `${this.base}/profile`,
        get information() {
          return {
            base: `${this.base}/information`,
            get save() {
              return `${this.base}/save`
            },
            get privacy() {
              return {
                base :`${this.base}/privacy`,
                get save() {return `${this.base}/save`}
              }
            }
          }
        },
        get division() {
          return {
            base: `${this.base}/division`,
            get list() {
              return `${this.base}/list`;
            },
          }
        },
        get exportdata() {
          return `${this.base}/exportdata`;
        },
        get summary() {
          return `${this.base}/summary`;
        },
      }
    },

    get reportedposts() {
      return {
        base: `${this.base}/reportedposts`,
        get search() {
          return { 
            base: `${this.base}/search`,
            get list() {
              return `${this.base}/list`;
            },
          };
        },
        get update() {
          return `${this.base}/update`;
        },
      };
    },

    get joinrequests() {
      return {
        base: `${this.base}/joinrequests`,
        get accept() {
          return {
            base: `${this.base}/accept`,
            get bulk() {return  `${this.base}/bulk`}
          }
        },
        get deny() {
          return `${this.base}/deny`;
        },
        get list() {
          return `${this.base}/list`;
        },
        get division() {
          return {
            base: `${this.base}/division`,
            get list() {return `${this.base}/list`}
          }
        },
        get status() {
          return {
            base: `${this.base}/status`,
            get list() {return `${this.base}/list`}
          }
        },
        get agencies() {
          return {
            base: `${this.base}/agencies`,
            get admin() {
              return {
                base: `${this.base}/admin`,
                get list() {return `${this.base}/list`}
            }
          }
          }
        },
      };
    },

    get notification() {
      return {
        base: `${this.base}/notification`,
        get countnotif() {return `${this.base}/countnotif`},
        get allnotif() {return `${this.base}/allnotif`},
        get read() {return `${this.base}/read`},
        get list() {return `${this.base}/list`},
      }
    }


};

export const protectedMSGraphEndPointAPI = {
  base: "https://graph.microsoft.com",
  version: "v1.0",

  get me() {
    return {
      base: `${this.base}/${this.version}/me`,
      get checkMemberGroups() {return `${this.base}/checkMemberGroups`}
    }
  },

  get groups() {
    return `${this.base}/${this.version}/groups`
  }

}

export const protectedVariable = {
  GoogleMapAPIKey: "AIzaSyAjq5S_xqu415goj_Gh6hnMjKR3UCCPZmE", //Please update as well the API key in index.html under public root folder
  GoogleMapId: "f4a206a6bf01c3cd",
  GoogleMapDefaultZoom: 13,
  PageSource: {
    Home: 1,
    Message: 2,
    MyDepartments: 3,
    Accounts: 4,
    JoinRequests: 5,
    ReportedPosts: 6,
    Agencies: 7
  }
}
