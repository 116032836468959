import React from "react";
import CardPostOfficersCardInfo from "./CardPost.OfficersCard.Info";


const CardPostOfficersCard = (props) => {

    return (
    <>
        <h2>Officers</h2><hr></hr><br/> 
        <div className="row">
            {props.listofficer.map((user) => (
                <CardPostOfficersCardInfo data={user}></CardPostOfficersCardInfo>
            )
            )}
        </div>
    </>);

}


export default CardPostOfficersCard;