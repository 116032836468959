import React, { useCallback, useEffect, useState, useRef} from "react";
import Autocomplete from "react-google-autocomplete";
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Row, Col } from "react-bootstrap";
import { NotificationContainer } from 'react-notifications';
import './AzurePost.View.css';

import iconpdf from "../../../assets/pdficon.png"
import WordIcon from "../../../assets/wordIcon";
import iconexcel from "../../../assets/excelicon.png";
import iconpower from "../../../assets/powericon.png";
import iconrarzip from "../../../assets/iconrarzip.png";
import icontxt from "../../../assets/txticon.png";
import iconnopreview from "../../../assets/nopreview.svg";

import postPicture from "../../../assets/PostPicture.png";
import useFetchWithMsal from "../../hooks/useFetchWithMsal";
import { UsedAppNBCContext } from "../../AppNBC.Provider";
import { protectedEndPointAPI, protectedVariable } from "../../Helpers/ProtectedCustomClass";


const AzurePostView = (props) => {
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get('id');
    const pageSource = queryParams.get('pagesource');

    const appNBCContext = UsedAppNBCContext();
    // eslint-disable-next-line no-unused-vars
    const { error, execute, executeFormData, executeBlob } = useFetchWithMsal();

    const { setOpenCreatePost, setIsAfterCreatedPost } = props;
    const { register, handleSubmit, setValue, formState: { errors }, setError, clearErrors} = useForm({
        mode: 'onChange'
    });

    let history = useHistory();

    const [loading, setLoading] = useState(false);
    const [list, setList] = useState([]);
    const [listCategories, setListCategories] = useState([]);
    const [incident, setIncident] = useState({
        id:0,
        category: 1,
        locationinput: "",
        onlyagency: false,
        draft: false,
        addressId: "-"
    });

    const [images, setImages] = useState([]);
    const [imagesError, setImageError] = useState(false);
    const [imagesSaved, setImagesSaved] = useState([]);
    const [filesSaved, setFilesSaved] = useState([]);
    const [location, setLocation] = useState({
    });

    const [isChangeLocation, setIsChangeLocation] = useState(false);
    const [isKeyPressEnter,setIsKeyPressEnter] = useState(false);
    const [isChangeCategory, setIsChangeCategory] = useState(false);

    const [isInitialLoadDone, setIsInitialLoadDone] = useState(false);

    const uploadInput = useRef(null);
    const cleanData = () => {
        setIncident({
            id: 0,
            category: 1,
            locationinput: "",
            onlyagency: false,
            draft: false,
            addressId: "-"
        });
        setLocation({});
        setImages([]);
    }

    const renderSwitchFileData = (item) => {
        switch (item.fileType) {
            case "application/pdf": 
                return <img width={"250px"} 
                            src={iconpdf} 
                            aria-hidden 
                            alt="PDF" 
                            style={{cursor: "pointer"}}  />;
                            
            case "officedocument.word":
                return <img width={"250px"} 
                            src={WordIcon} 
                            aria-hidden 
                            alt="WORD" 
                            style={{cursor: "pointer"}}  />;

            case "officedocument.spreadsheetml":
                return <img width={"250px"} 
                            src={iconexcel} 
                            aria-hidden 
                            alt="EXCEL" 
                            style={{cursor: "pointer"}}  />;
            
            case "officedocument.presentationm":
                return <img width={"250px"} 
                            src={iconpower} 
                            aria-hidden 
                            alt="POWER POINT" 
                            style={{cursor: "pointer"}}  />;
        
            case "text/plain":
                return <img width={"250px"} 
                            src={icontxt} 
                            aria-hidden 
                            alt="TEXT" 
                            style={{cursor: "pointer"}}  />;
    
            case "application/x-zip-compressed":
                return <img width={"250px"} 
                            src={iconrarzip} 
                            aria-hidden 
                            alt="ZIP" 
                            style={{cursor: "pointer"}}  />;
            
            default:
                return <img width={"250px"} src={iconnopreview} aria-hidden alt="FILE" />;
        }
    }


    const onChangeLocation = e => {
        setLocation({
            ...location, e
        });
        setValue('locationinput', e.formatted_address);
        setIsChangeLocation(true);
        clearErrors("locationinput");
    }

    const onChangeCategory = e => {
        setIsChangeCategory(true);
        setIncident({
            ...incident,
            category: e.target.value
        });
    }

    const isItemChecked = useCallback((itemId) => {
        if(id > 0) {
            return (Number.parseInt(incident.category, 10) ===  Number.parseInt(itemId, 10));
        } else {
            if(!isChangeCategory) {
                if(itemId === 1) return true;
            }
        }
    },[incident,id,isChangeCategory])

    const onChangeInputMap = useCallback((e) => {
        if (e.target.value === "" || e.target.value==null) {
            setError('locationinput', { type: 'required' });
        } else {
            //It means if the user input any text on the location field, the user should select on pop-up list box.
            setError('locationinput', { type: 'freetextlocation'});
        }
    },[setError])

    const RedirectToPreviousPage = useCallback(() => {
        try {
            switch(parseInt(pageSource)) {
                case protectedVariable.PageSource.Message:
                    history.push("/message");
                    break;
                default:
                    history.push("/home");
                    break;
              }
        } catch (ex) {
            console.log(ex);
        }

    },[history,pageSource])

   
    const getIncident = useCallback(async () => {
        if(appNBCContext.dbUserId !== null) {

            let _requestBody = id;

             execute("PUT", protectedEndPointAPI.home.incident.information.base, _requestBody)
             .then((response) => {
                var _data = response.data;
                if(_data !== null){
                    setValue('num', _data.incidentNum);
                    setValue('type', _data.incidentTypeId);
                    setValue('description', _data.description);
                    setValue('date', _data.date);
                    setValue('locationinput', _data.addressId === "-" ? "" : _data.nameAddress);
                    setValue('category', _data.incidentCategoryId);
                    setValue('addressId', _data.addressId);
                    setIncident({
                        ...incident,
                        id:_data.id,
                        category: _data.incidentCategoryId,
                        onlyagency: _data.onlyAgency,
                        locationinput: _data.addressId === "-" ? "" : _data.nameAddress,
                        draft: _data.draft,
                        addressId: _data.addressId
                    });

                    setImagesSaved(_data.imagesSaved);
                    setFilesSaved(_data.filesSaved);
                }
            });

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[appNBCContext.dbUserId, incident, execute,id])

    useEffect(() => {
        if(!isInitialLoadDone) {
            if(appNBCContext.dbUserId !== null) {

                execute("GET", protectedEndPointAPI.home.incident.type.list)
                .then((response) => {
                    var _data = response.data;
                    if(_data !== null) setList(response.data);;
                })
                .catch((error) => {
                    console.log(error);
                });

                execute("GET", protectedEndPointAPI.home.incident.category.list)
                .then((response) => {
                    var _data = response.data;
                    if(_data !== null) {
                        if (_data.length > 0) {
                            let idc = _data[0].id;
                            setIncident({
                                ...incident,
                                category: 
                                  idc
                            });
                        }
                        setListCategories(_data);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

                if (id !== undefined && id > 0) {
                    getIncident();
                }

                setIsInitialLoadDone(true);
            }

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[appNBCContext.dbUserId, execute, id, incident, isInitialLoadDone])

    



    return (
        <>
            <div className="card-container">
                <div className="card-post">
                    <form  >
                        <div className="card-body">
                            <Row>
                                <Col lg={2}>
                                    <Row>
                                        {
                                            <>
                                                <img alt="" src={postPicture} />
                                            </>
                                        }
                                        <div><br/></div>
                                        
                                        {
                                            <>
                                                {images &&  images.length > 0 && (
                                                    <div>
                                                        {images.map((item) => {
                                                            return <>
                                                                <div className="row" key={item}>
                                                                    <img alt="Not Found" width={"250px"} src={URL.createObjectURL(item)} />
                                                                </div>
                                                                <br/>
                                                            </>
                                                        })
                                                        }
                                                    </div>
                                                )}

                                                {appNBCContext.isFileEncryptionEnable ? 
                                                    (imagesSaved.length > 0 && (
                                                        <div>
                                                            {imagesSaved.map((item, index) => (
                                                            <> <div className="row" key={item}>
                                                                    <img alt="Not Found" width={"250px"} src={`data:image/jpeg;base64,${item.fileBase64String}`}  />
                                                                    </div> <br/>
                                                                </>
                                                            ))}
                                                        </div>
                                                    )) 
                                                    : 
                                                    (imagesSaved.length > 0 && (
                                                        <div>
                                                            {imagesSaved.map((item, index) => (
                                                            <> <div className="row" key={item}>
                                                                    <img alt="Not Found" width={"250px"} src={item.url} />
                                                                    </div> <br/>
                                                                </>
                                                            ))}
                                                        </div>
                                                    )) 
                                                }

                                                 {filesSaved.length > 0 && (
                                                    <div>
                                                        {filesSaved.map((item, index) => (
                                                            <>
                                                                <div className="row" key={item}>
                                                                    {renderSwitchFileData(item)}
                                                                </div> <br/>
                                                            </>
                                                        ))}
                                                    </div>
                                                )}
                                            </>
                                        }
                                    </Row>
                                    
                                </Col>
                                <Col lg={10}>
                                    <Row>
                                        <div>
                                            {listCategories.map((item) => (
                                                <>
                                                    <div key={"rdo-cat-" +item.id} className="typeBtnRadio">
                                                        <input disabled  checked={isItemChecked(item.id)} value={item.id} {...register("category", { required: true })} type="radio" onClick={onChangeCategory} />
                                                        <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                {item.name}
                                                        </label>
                                                    </div>
                                                </>
                                            ))}
                                        </div>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col>
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput1" className="form-label">Incident number</label>
                                                <input disabled type="text" className={errors.num ? 'form-control is-invalid' : 'form-control'} {...register("num", { required: true, maxLength: 80 })} placeholder="Enter the number" aria-label="Username" aria-describedby="basic-addon1" />

                                            </div>
                                           
                                        </Col>
                                        <Col>
                                            <div className="mb-3">
                                                <label className="form-label">Incident date</label>
                                                <input disabled type="datetime-local" className={errors.date ? 'form-control  is-invalid' : 'form-control'} {...register("date", { required: true })} placeholder="Incident Date and Time" aria-label="Username" aria-describedby="basic-addon1" />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                        <div className="mb-3">
                                            <label htmlFor="ss" className="form-label">Location</label>
                                            <Autocomplete
                                                 disabled
                                                apiKey={protectedVariable.GoogleMapAPIKey}
                                                {...register("locationinput", { required: true })}
                                                className={errors.locationinput ? 'form-control is-invalid' : 'form-control'}
                                                placeholder="Enter the location"
                                               
                                                options={{
                                                    
                                                    types: ["address"],
                                                    componentRestrictions: { country: "us" },
                                                }}
                                                defaultValue={incident.locationinput}
                                                onChange={onChangeInputMap}
                                                onPlaceSelected={(place) => {
                                                    onChangeLocation(place);
                                                }}
                                            />
                                        </div>
                                       
                                       
                                        </Col>
                                        <Col>
                                            <div className="mb-3">
                                            <label className="form-label">Incident type</label>

                                                <select disabled defaultValue="" className={errors.type ? 'form-control is-invalid' : 'form-control'}  {...register("type", { required: true })}>
                                                    <option value="" disabled hidden>Incident Type</option>
                                                    {list.map((item) => (
                                                        <option key={item.id} value={item.id}>{item.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="mb-3">
                                                <label className="form-label">Description</label>
                                                <textarea disabled className={errors.description ? 'form-control  textareaform is-invalid' : 'form-control textareaform'} {...register("description", { required: true })} placeholder="Description" aria-label="Username" aria-describedby="basic-addon1" />
                                            </div>
                                           
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <div className="col-md-6 col-sm-12">
                                                <div className="form-check">
                                                    <input disabled type="checkbox" className="form-check-input nomt" id="exampleCheck1" value={incident.draft} checked={incident.draft} />
                                                    <label className="form-check-label" htmlFor="exampleCheck1">Save as draft</label>
                                                </div>
                                                <div className="form-check">
                                                    <input disabled type="checkbox" className="form-check-input nomt" id="exampleCheck1" value={incident.onlyagency} checked={incident.onlyagency} />
                                                    <label className="form-check-label" htmlFor="exampleCheck1">Just Share with my agency</label>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                               
                                            </div>
                                            <div className="row">
                                            <div className="col-md-4">
                                                <button onClick={() => RedirectToPreviousPage()} type="button" className="btn btn-primary btnsubmit"   disabled={loading} >  
                                                {loading && (
                                                    <span className="spinner-border spinner-border-sm"></span>
                                                    )} Back
                                                    </button>
                                                </div>
                                                <div className="col-md-4">
                                                
                                            </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </form>
                </div>
            </div>
            <NotificationContainer />
        </>
      
    );
}
export default AzurePostView;