import Skeleton from '@mui/material/Skeleton';

export const PostShimmeringEffect = () => {

    const MainContainer = () => {
        return (
            <div className="row card bg-white" style={{marginRight: "5px"}}>
                <div className="row">
                    <div className="col-12 rowimgcard">
                        <div className="row imgcardofficers">
                            <Skeleton variant="circular" width={80} height={80} />
                        </div>
                    </div>
            
                </div>
                <div className="col-12">
                    <div className="row mg03">
                        <Skeleton variant="rounded" width={100} height={24} />
                    </div>
                    <div className="row mg03">
                        <Skeleton variant="rounded" width={150} height={24} />
                    </div>
                    <div className="row mg03">
                        <Skeleton variant="rounded" width={250} height={24} />
                    </div>
                    <div className="row mg03"></div>
                    <div className="row mg03"></div>
                    <div className="row mg03"></div>
                    <div className="row mg03">
                        <Skeleton variant="rounded" width={100} height={24} />
                    </div>
                    <div className="row mg03">
                        <Skeleton variant="rounded" width={100} height={24} />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="row">
            <div className="col-4">
                <MainContainer />
            </div>
            <div className="col-4">
                <MainContainer />
            </div>
            <div className="col-4">
             <MainContainer />
            </div>
        </div>



    );
}
