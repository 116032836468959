import React, { useState,useEffect,useCallback } from 'react';
import { Link,useHistory } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import {  NavItem, NavLink, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";

import './AzureUserBar.css';
import Avatar from 'react-avatar';

import { StyledBadge, ScrollStyle } from './AzureUserBar.Style';
import belllogo from "../../../assets/bell.png"
import microsoftLogo from "../../../assets/microsoft-logo.png";
import useFetchWithMsal from '../../hooks/useFetchWithMsal';
import { UsedAppNBCContext } from '../../AppNBC.Provider';
import { protectedEndPointAPI, protectedVariable } from "../../Helpers/ProtectedCustomClass";


const AzureUserBar = () => {

    const isAuthenticated = useIsAuthenticated();
    const {instance} = useMsal();
    let history = useHistory();

    const [isInitialLoadDone, setIsInitialLoadDone] = useState(false);
    const appNBCContext = UsedAppNBCContext();

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dropdownOpenNotif, setDropdownOpenNotif] = useState(false);
    const [userFullName, setUserFullName] = useState("");

    const [items, setItems] = useState(Array.from({ length: 0 }));
    const [hasMore, setHasMore] = useState(true);
    const [countnot, setCountNot] = useState();

    const [totalN, setTotalN] = useState(0);


    // eslint-disable-next-line no-unused-vars
    const { error, execute } = useFetchWithMsal();

    const toggle = () => {
        setDropdownOpen(!dropdownOpen);
    }

    const toggleNotif = () => {
        setDropdownOpenNotif(!dropdownOpenNotif);
    }

    const getCountNotif = useCallback(() => {
        if(appNBCContext.dbUserId !== null) {
            let _requestBody = {
                UserId: appNBCContext.dbUserId,
            }

            execute("PUT", protectedEndPointAPI.notification.countnotif, _requestBody).then((response) => {
                var _data = response.data;
                if(_data !== null) { 
                    setCountNot(_data);
                }
            });

        }
            
    },[execute, appNBCContext])

    const getAllCountNotif = useCallback(() => {
        if(appNBCContext.dbUserId !== null) {
            let _requestBody = {
                UserId: appNBCContext.dbUserId,
            }

            execute("PUT", protectedEndPointAPI.notification.allnotif, _requestBody).then((response) => {
                var _data = response.data;
                if(_data !== null) { 
                    setTotalN(_data);
                }
            });

        }
        
    },[execute, appNBCContext])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const RedirectNot = (item) => {
        switch (item.generalNotificationType) {
            case 0: 
                history.push("/post/view?id=" + item.incidentId + "&pagesource=" + protectedVariable.PageSource.Home);
                break;
            case 1:
                history.push("/joinrequests");
                break;
            case 2:
                var _user = item.chatToUser;
                if(_user != null) {
                    history.push({
                        pathname: `/message`,
                        search: `?userId=${_user.id}&name=${_user.name}&type=${_user.division}&typechat=${"user"}&incident=${""}&address=${""}`,
                        state: {
                            urlPic: _user.urlPic,
                            userId: _user.id,
                            name: _user.name,
                            type: _user.division,
                            typechat: "user",
                            incident: "",
                            address: "",
                            phone: _user.phone,
                            email: _user.email,
                            userAddress: _user.address
                        }
                    });
                }
               
                break;
            case 3:
                history.push("/post/view?id=" + item.incidentId + "&pagesource=" + protectedVariable.PageSource.Home);
                break;
            case 4:
                history.push("/reportedposts");
                break;
            case 5:
                history.push("/agencies");
                break;
            case 6:
                history.push("/agencies");
                break;
            default:
                history.push("/home");
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const ChangeStateNot = (item) => {
        let itemsx = [...items];
        let index = itemsx.findIndex(obj => {
            return obj.id === item.id;
        });
        let itemx = { ...itemsx[index] };

        itemx.notificationState = 1;

        itemsx[index] = itemx;
        itemsx.splice(index, 1); // remove read notif

        setItems(itemsx);
    }

    const ReadNotification = useCallback((item) => {
        if(appNBCContext.dbUserId !== null) {

            if (item.notificationState === 0) {

                let _requestBody = {
                    UserId: appNBCContext.dbUserId,
                    Id: item.id
                }

                execute("POST", protectedEndPointAPI.notification.read, _requestBody)
                .then((response) => {
                    //var _data = response.data;
                    ChangeStateNot(item);
                    RedirectNot(item);
                    getCountNotif();
                });
            } else {
                RedirectNot(item);
                getCountNotif();
            }
        }
        
    },[appNBCContext.dbUserId, execute, ChangeStateNot, RedirectNot, getCountNotif])

    const fetchMoreData = useCallback(() => {
        if(appNBCContext.dbUserId !== null) {

            if (items.length >= totalN) {
                setHasMore(false);
                return;
            }
            let skipl = items.length;

            let _requestBody = {
                UserId: appNBCContext.dbUserId,
                Take: 5,
                Skip: skipl
            }

            execute("PUT", protectedEndPointAPI.notification.list, _requestBody)
            .then((response) => {
                var _data = response.data;
                if(_data !== null) { 
                    var newitems = items.concat(_data);
                    setItems(newitems);
                }
            });

        } else {
            return;
        }
        
    },[appNBCContext.dbUserId, items, totalN, execute])

    const handleLogoutRedirect = (event) => {
        event.preventDefault(); // 👈️ prevent page refresh    
        instance.logoutRedirect();
      
    };

    const UserWithProfile = useCallback(() => {
        return <ul className="nomgbottom">
        <NavItem className='logoutli spanhand col-6'>
            <Dropdown isOpen={dropdownOpen} toggle={toggle} className="dropdownprofile">
                <DropdownToggle caret className="droplog">
                    <StyledBadge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant="dot">
                        {appNBCContext.imagePath !== undefined 
                        ? (
                            appNBCContext.isFileEncryptionEnable 
                                ? (<Avatar src={`data:image/jpeg;base64,${appNBCContext.imageBase64String}`} size="50" round  />) 
                                : (<Avatar src={appNBCContext.imagePath} size="50" round  />)  
                          ) 
                        : (<Avatar src={microsoftLogo} size="50" round  />)}
                    </StyledBadge>
                </DropdownToggle>
                <DropdownMenu className="menudropdown">
                    <DropdownItem tag={Link} className="text-dark" to="/Profile">My Profile</DropdownItem>
                    <DropdownItem onClick={handleLogoutRedirect}>Log Out</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </NavItem>
    </ul>
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[appNBCContext,toggle])

    const UserLogOutOnly = () => {
        return <ul className="nomgbottom">
        <NavItem className='logoutli spanhand col-6'>
            <Dropdown isOpen={dropdownOpen} toggle={toggle} className="dropdownprofile">
                <DropdownToggle caret className="droplog">
                    <StyledBadge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant="dot">
                        <Avatar src={microsoftLogo} size="50" round  />
                    </StyledBadge>
                </DropdownToggle>
                <DropdownMenu className="menudropdown">
                    <DropdownItem onClick={handleLogoutRedirect}>Log Out</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </NavItem>
    </ul>
    }

    const renderSwitchLabel = (x) => {
        switch (x.generalNotificationType) {
            case 0: 
                return <>New Incident</>;
            case 1:
                return <>New Request</>;
            case 2:
                return <>New Message</>;
            case 3:
                return <div>A post you're following has been updated.</div>;
            case 4:
                return <>New Incident Reported!</>;
            case 5:
                return <>Your Request Agency was Accepted!</>;
            case 6:
                return <>Your Request Agency was Denied!</>;
            default:
                return <>New notification!</>;
        }
    }

    useEffect(() => {
        if(instance.getActiveAccount() !== null) {
            if(userFullName === "") setUserFullName(instance.getActiveAccount().name);
        }
    },[instance,userFullName]);

    useEffect(() => {
        getCountNotif();
        getAllCountNotif();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countnot, totalN]);

    useEffect(() => {
        //load initial data on 1st render
        if(!isInitialLoadDone) {
            if(appNBCContext.dbUserId !== null) {

                let _requestBody = {
                    UserId: appNBCContext.dbUserId,
                    Take: 5,
                    Skip: 0
                }
    
                execute("PUT", protectedEndPointAPI.notification.list, _requestBody)
                .then((response) => {
                    var _data = response.data;
                    if(_data !== null) { 
                        var newitems = items.concat(_data);
                        setItems(newitems);
                    }
                });
    
            }
            setIsInitialLoadDone(true);
        }
    }, [execute,appNBCContext,items,isInitialLoadDone]);

    useEffect(() => {
        if (appNBCContext.signalRConnection !== undefined 
            && appNBCContext.signalRConnection  !== null 
            && appNBCContext.signalRConnection._connectionStarted === true) {

            appNBCContext.signalRConnection.off('GeneralNotification');
            appNBCContext.signalRConnection.on('GeneralNotification', () => {
                getCountNotif();
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appNBCContext.signalRConnection]);
    return (
        <div>
            {isAuthenticated ? (
                <div className="row">
                    <div className="col-5"></div>
                   
                    <div className="col-2">
                        <Dropdown isOpen={dropdownOpenNotif} toggle={toggleNotif} className="dropdownprofile">
                            <DropdownToggle caret className="droplog">
                                {countnot !== 0 &&
                                    <label className="lblnumber">{countnot}</label>
                                }
                            <img alt="" className="bellnot" src={belllogo} />
                        </DropdownToggle>
                            <DropdownMenu className="menudropdownnotif" >
                                <div style={ScrollStyle}>
                                    <InfiniteScroll
                                        pageStart={0}
                                        loadMore={fetchMoreData}
                                        hasMore={hasMore}
                                        loader={<div className="loader" key={0}>Loading ...</div>}
                                        useWindow={false}
                                    >
                                        {(items.length === 0 && hasMore === false) &&
                                            <label className="no-notify">You have not received any notification yet</label>
                                        }
                                        {items.map((i, index) => {
                                            return <DropdownItem 
                                            onClick={() => ReadNotification(i)} 
                                            key={index}  
                                            className={`dropdownmenunot ${i.notificationState === 1 ? "readed" : ""}`} >
                                                <label style={{cursor: "pointer"}}>{renderSwitchLabel(i)}</label>
                                            </DropdownItem>;
                                        })}
                                    </InfiniteScroll>
                                </div>

                        </DropdownMenu>
                        </Dropdown>
                    </div>
                    <div className="col-3 namenavbar nomgbottom"> <label className="namelbl"> {userFullName}</label></div>
                    <div className="col-2 nomgbottom">
                        {
                            appNBCContext.dbUserId !== null ? 
                            (
                                appNBCContext.dbUserId !== "" ? (<UserWithProfile />) : (<UserLogOutOnly />)
                            ) 
                            : 
                            ( <UserLogOutOnly /> )
                        }
                    </div>
                </div>
            ) : (
                <ul className="navbar-nav flex-grow">
                    <NavItem className='noborder'>
                        <NavLink tag={Link} className="text-dark" to="/login">Login</NavLink>
                    </NavItem>
                </ul>
            )}


        </div>
    );
}
export default AzureUserBar;