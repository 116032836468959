import React, {useEffect, useState, useCallback} from "react";
import { Button, Col, Input, Row } from "reactstrap";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { faSearch,faUndo } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalAlert from "../../../components/Common/ModalAlert";
import Pagination from "../../../components/Common/pagination";
import Table from "../../../components/Common/table"
import editpen from "../../../assets/edit.png";
import deletetrash from "../../../assets/delete.png";
import location from "../../../assets/location_full.png";
import LoaderAgency from "../../../components/Agency/Officer/LoaderAgency";
import { NoResultFoundContainer } from "../../../components/Department/Department.Style";
import { UsedAppNBCContext } from "../../AppNBC.Provider";
import useFetchWithMsal from "../../hooks/useFetchWithMsal";
import { protectedEndPointAPI } from "../../Helpers/ProtectedCustomClass";
import AzureManageAdmins from "../AzureAgencies/AzureManageAdmins";

library.add(faSearch,faUndo);
const AzureRequestedAgencies = (props) => {

    const appNBCContext = UsedAppNBCContext();
    // eslint-disable-next-line no-unused-vars
    const { error, execute } = useFetchWithMsal();

    let history = useHistory();
    const { formState: { errors }, setError, clearErrors } = useForm({
        mode: 'onChange'
    });

    // eslint-disable-next-line no-unused-vars
    const [isSuper, setIsSuper] = useState(appNBCContext.isSuperAdmin || appNBCContext.isAdmin);
    // eslint-disable-next-line no-unused-vars
    const [isAdminOnly, setIsAdminOnly] = useState(appNBCContext.isAdmin && !appNBCContext.isSuperAdmin)
    const [modal, setModal] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [modalInvitation, setmodalmodalInvitation] = useState(false);
    const [showUsers, setShowUsers] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [modalDelete, setModalDelete] = useState({
        show: false,
        id: "",
        name: ""
    });

    const [modalRestore, setModalRestore] = useState({
        show: false,
        id: "",
        name: ""
    });

    const [manageAgency, setmanageAgency] = useState(0);
    const [agencyId, setAgencyId] = useState(false);
    const [validation, setValidation] = useState({ error: false, message: "" });
    const RedirectEditAgency = (agency) => {
        history.push("/agencies/update?agencyId=" + agency.agencyId);
    }
    const [emailExist, setEmailExist] = useState([]);

    const [filter, setFilter] = useState("");
    const [filterinvitation, setFilterInvitation] = useState("");
    const [modalRequest, setModalRequest] = useState(false);
    const [modalPendingRequest, setPendingModalRequest] = useState(false);
    const [modalRemoveRequest, setRemoveModalRequest] = useState(false);
    const [modalDefaultAgency, setmodalDefaultAgency] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);

    const columns = isSuper ? (isAdminOnly ? [
        {
            Header: "Department",
            accessor: "agencyName",
        },
        {
            Header: "Location",
            accessor: "state",
            Cell: cellInfo => {
                return <>
                    <img className="locationpng" src={location} alt=""></img><label> {cellInfo.data[cellInfo.row.index].state}</label>
                </>;
            }
        },
        {
            Header: "Admins",
            accessor: "",
            Cell: cellInfo => {
                if (appNBCContext.isSuperAdmin || cellInfo.data[cellInfo.row.index].isAdmin) {
                    return <><label onClick={() => manageAdminUsers(cellInfo.data[cellInfo.row.index])} className="link">Manage</label></>
                }
                return <></>;
            }
        },
        // [START] - NBC CJIS - 4952 - Remove adding officer within the app.
        // {
        //     Header: "Officers",
        //     accessor: "",
        //     Cell: cellInfo => {
        //         if (appNBCContext.isSuperAdmin || cellInfo.data[cellInfo.row.index].isAdmin) {
        //             return <><label onClick={() => toggle(cellInfo.data[cellInfo.row.index].agencyId)} className="link">+ Add officers</label></>
        //         }
        //         return <></>;
        //     }
        // },
        // [END]
        {
            Header: "Status",
            accessor: "joined",
            Cell: cellInfo => {
                if (cellInfo.row.values.joined === true) {
                    return <>
                        <Button style={{width: "100px", fontFamily: "Work Sans", fontSize: "14px"}} color="primary" onClick={() => { handleSubscribe(cellInfo.data[cellInfo.row.index]) }}>
                            Joined
                        </Button>
                    </>
                } else {
                    if (cellInfo.data[cellInfo.row.index].request === true) {
                        if (cellInfo.data[cellInfo.row.index].stateRequest === "Pending") {
                            return <><Button style={{width: "100px", fontFamily: "Work Sans", fontSize: "14px"}} onClick={() => removeRequest(cellInfo.data[cellInfo.row.index])} >Awaiting <br/> Approval</Button></>
                        } else {
                            return <> 
                                <Button style={{width: "100px", fontFamily: "Work Sans", fontSize: "14px"}} onClick={() => { handleSubscribe(cellInfo.data[cellInfo.row.index]) }} >
                                    Join
                                </Button>
                            </>
                        }
                    } else {
                        return <> 
                            <Button style={{width: "100px", fontFamily: "Work Sans", fontSize: "14px"}} onClick={() => { handleSubscribe(cellInfo.data[cellInfo.row.index]) }} >
                                    Join
                                </Button>
                        </>
                    }
                }
            }
        },
        {
 
            Header: " ",
            accessor: "",
            Cell: cellInfo => {
                if (appNBCContext.isSuperAdmin) {
                    if (cellInfo.data[cellInfo.row.index].isAgencyDeleted === true) {
                        return <>
                            <FontAwesomeIcon icon={faUndo}  onClick={() => toggleRestore(cellInfo.data[cellInfo.row.index])} />
                        </>;
                    } else {
                        return <>
                            <img className="actionspng" src={editpen} onClick={() => RedirectEditAgency(cellInfo.data[cellInfo.row.index])} alt=""></img>
                            <img className="actionspng" src={deletetrash} onClick={() => toggleDelete(cellInfo.data[cellInfo.row.index])} alt=""></img>
                        </>;
                    }
                    
                }
                if (cellInfo.data[cellInfo.row.index].isAdmin)
                {
                    return <><img className="actionspng" src={editpen} onClick={() => RedirectEditAgency(cellInfo.data[cellInfo.row.index])} alt=""></img></>;
                }
                return <></>;
            }
        }
    ] : [
        {
            Header: "Department",
            accessor: "agencyName",
        },
        {
            Header: "Location",
            accessor: "state",
            Cell: cellInfo => {
                return <>
                    <img className="locationpng" src={location} alt=""></img><label> {cellInfo.data[cellInfo.row.index].state}</label>
                </>;
            }
        },
        {
            Header: "Admins",
            accessor: "",
            Cell: cellInfo => {
                if (appNBCContext.isSuperAdmin|| cellInfo.data[cellInfo.row.index].isAdmin) {
                    return <> <label onClick={() => manageAdminUsers(cellInfo.data[cellInfo.row.index])} className="link">Manage</label></>
                }
                return <></>;
            }
        },
        // [START] - NBC CJIS - 4952 - Remove adding officer within the app.
        // {
        //     Header: "Officers",
        //     accessor: "",
        //     Cell: cellInfo => {
        //         if (appNBCContext.isSuperAdmin || cellInfo.data[cellInfo.row.index].isAdmin) {
        //             return <><label onClick={() => toggle(cellInfo.data[cellInfo.row.index].agencyId)} className="link">+ Add officers</label></>
        //         }
        //         return <></>;
        //     }
        // },
         // [END] 
        {
            Header: " ",
            accessor: "",
            Cell: cellInfo => {
                if (appNBCContext.isSuperAdmin) {
                    if (cellInfo.data[cellInfo.row.index].isAgencyDeleted === true) {
                        return <>
                            <FontAwesomeIcon icon={faUndo}  onClick={() => toggleRestore(cellInfo.data[cellInfo.row.index])} />
                        </>;
                    } else {
                        return <>
                            <img className="actionspng" src={editpen} onClick={() => RedirectEditAgency(cellInfo.data[cellInfo.row.index])} alt=""></img>
                            <img className="actionspng" src={deletetrash} onClick={() => toggleDelete(cellInfo.data[cellInfo.row.index])} alt=""></img>
                        </>;
                    }
                   
                }
                if (cellInfo.data[cellInfo.row.index].isAdmin) {
                    return <><img className="actionspng" src={editpen} onClick={() => RedirectEditAgency(cellInfo.data[cellInfo.row.index])} alt=""></img></>;
                }
                return <></>;
            }
        }
    ]) : [
        {
            Header: "Department",
            accessor: "agencyName",
        },
        {
            Header: "Location",
            accessor: "state",
            Cell: cellInfo => {
                return <>
                    <img className="locationpng" src={location} alt=""></img><label> {cellInfo.data[cellInfo.row.index].state}</label>
                </>;
            }
        },
        {
            Header: "Status",
            accessor: "joined",
            Cell: cellInfo => {
                    if (cellInfo.row.values.joined === true) {
                        return <>
                            <Button style={{width: "100px", fontFamily: "Work Sans", fontSize: "14px"}} color="primary" onClick={() => { handleSubscribe(cellInfo.data[cellInfo.row.index]) }}>
                                Joined
                            </Button>
                        </>
                    } else {
                        if (cellInfo.data[cellInfo.row.index].request === true) {
                            if (cellInfo.data[cellInfo.row.index].stateRequest === "Pending") {
                                return <>
                                    <Button style={{width: "100px", fontFamily: "Work Sans", fontSize: "14px"}} onClick={() => removeRequest(cellInfo.data[cellInfo.row.index])} >
                                        Awaiting <br/> Approval
                                    </Button>
                                </>
                            } else {
                                return <> 
                                <Button style={{width: "100px", fontFamily: "Work Sans", fontSize: "14px"}} onClick={() => { handleSubscribe(cellInfo.data[cellInfo.row.index]) }} >
                                    Join
                                </Button>
                                </>
                            }
                        } else {
                            return <> 
                                <Button style={{width: "100px", fontFamily: "Work Sans", fontSize: "14px"}} onClick={() => { handleSubscribe(cellInfo.data[cellInfo.row.index]) }} >
                                    Join
                                </Button>
                            </>
                        }
                } 
            }
        }
    ];

    const [pageData, setPageData] = useState({
        rowData: [],
        isLoading: false,
        totalPages: 0,
        totalData: 0,
    });

    const toggleDelete = (agency) => {
        setModalDelete({
            ...modalDelete,
            show: !modalDelete.show,
            id: agency.agencyId,
            name: agency.agencyName
        });
    }

    const toggleDeleteModal = (shomwod) => {
        setModalDelete({
            ...modalDelete,
            show: shomwod,
            id: "",
            name: ""
        });
    }

    const toggleRestore= (agency) => {
        setModalRestore({
            ...modalRestore,
            show: !modalRestore.show,
            id: agency.agencyId,
            name: agency.agencyName
        });
    }

    const toggleRestoreModal = (shomwod) => {
        setModalRestore({
            ...modalRestore,
            show: shomwod,
            id: "",
            name: ""
        });
    }


    const toggle = (agency) => {
        getUserEmailExist(agency);

        setValidation({
            error: false,
            message: ""
        });
        setModal(!modal);
        setAgencyId(agency);
        setFilterInvitation("");   
        clearErrors("emailaddress");    
    }

    const toggleAddedRequest = () => {
        setModalRequest(false);
    };

    const togglePendingRequest = () => {
        setPendingModalRequest(false);
    };

    const toggleRemoveRequest = () => {
        setRemoveModalRequest(false);
    };

    const toggleModalDefaultAgency = () => {
        setmodalDefaultAgency(false);
    };

    const handleClose = () => setShowUsers(false);

    const manageAdminUsers = async (agency) => {
        setmanageAgency(agency.agencyId);
        setShowUsers(true);
    }

    const getData = useCallback(async(pageNo = 1, text) => {
        if(appNBCContext.dbUserId !== null) {
            let _requestBody = {
                UserId: appNBCContext.dbUserId,
                IsSuperAdmin: appNBCContext.isSuperAdmin,
                IsAdmin: appNBCContext.isAdmin,
                Page: pageNo,
                Take: 15,
                SearchCriteria: text
            }

            return execute("PUT", protectedEndPointAPI.agencies.mylist, _requestBody);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[execute, appNBCContext])

    const DeleteAgency = useCallback((id) => {
        if(appNBCContext.dbUserId !== null) {
            let _requestBody = id

            execute("POST", protectedEndPointAPI.agencies.delete, _requestBody).then((response) => {
                var _data = response.data;
                if(_data !== null) { 
                    toggleDeleteModal(false);
                    props.refreshAzureOfficer();
                    getData(1).then((info) => {
                        const { totalPages, data, totalData } = info.data;
                        setPageData({
                            isLoading: false,
                            rowData: data,
                            totalPages,
                            totalData,
                        });
                    });
                }
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[execute, appNBCContext])

    const RestoreAgency = useCallback((id) => {
        if(appNBCContext.dbUserId !== null) {
            let _requestBody = id

            execute("POST", protectedEndPointAPI.agencies.restore, _requestBody).then((response) => {
                var _data = response.data;
                if(_data !== null) { 
                    toggleRestoreModal(false);
                    props.refreshAzureOfficer();
                    getData(1).then((info) => {
                        const { totalPages, data, totalData } = info.data;
                        setPageData({
                            isLoading: false,
                            rowData: data,
                            totalPages,
                            totalData,
                        });
                    });
                }
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[execute, appNBCContext])

    const getUserEmailExist = useCallback((agencyId) => {
        if(appNBCContext.dbUserId !== null) {
            let _requestBody = agencyId

            execute("PUT", protectedEndPointAPI.officer.agency.email.list, _requestBody).then((response) => {
                var _data = response.data;
                if(_data !== null) setEmailExist(_data);
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[execute, appNBCContext])

    const reload = (info, request, response) => {
        
        const { totalPages, data, totalData } = info.data;
        setPageData({
            isLoading: false,
            rowData: data,
            totalPages,
            totalData,
        });
        if (request !== undefined && !request.Joined) {
            var _data = response.data;
            switch (_data) {
                case 0: 
                    setModalRequest(true);
                    break;
                case 1:
                    setPendingModalRequest(true);
                    break;
                default:
                    setPendingModalRequest(true);
            }
        }   
    }

    const removeRequest = useCallback(async(request) => {
        if(appNBCContext.dbUserId !== null) {
            let _requestBody = request.requestId

            execute("POST", protectedEndPointAPI.agencies.request.delete, _requestBody).then((response) => {
                var _data = response.data;
                if(_data !== null)  {
                    getData(currentPage, filter).then((info) => {
                        reload(info);
                        setRemoveModalRequest(true);
                        setIsLoading(false);
                    });
                }
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[execute, appNBCContext,currentPage])

    const handleSubscribe = useCallback(async(agency) => {
        if(appNBCContext.dbUserId !== null) {
            if (agency.isDefault === false) {

                let _requestBody = {
                    UserId: appNBCContext.dbUserId,
                    IsSuperAdmin: appNBCContext.isSuperAdmin,
                    IsAdmin: appNBCContext.isAdmin,
                    AgencyId: agency.agencyId,
                    AgencyName: agency.agencyName,
                    State: agency.state,
                    Joined: agency.joined,
                };

                if(agency.joined) {
                    execute("POST", protectedEndPointAPI.agencies.unsubscribe, _requestBody).then((response) => {
                        var _data = response.data;
                        if(_data !== null)  {
                            getData(currentPage, filter).then((info) => {
                                reload(info, _requestBody, response);
                            });
                        }
                    });
                } else {
                    execute("POST", protectedEndPointAPI.agencies.subscribe, _requestBody).then((response) => {
                        var _data = response.data;
                        if(_data !== null)  {
                            getData(currentPage, filter).then((info) => {
                                reload(info, _requestBody, response);
                            });
                        }
                    });
                }
                
            }
            else {
                setmodalDefaultAgency(true);
            }

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[execute, appNBCContext,currentPage,filter]);

    const RedirectAddAgency = () => {
        history.push("/agencies/new");
    }

    const [isExecuteFilterChangedFetch, setIsExecuteFilterChangedFetch] = useState(false);
    const [isTextSearchExecuted, setIsTextSearchExecuted] = useState(false);

    const onFilterChanged = (e) => {
        setFilter(e.target.value);

        setIsTextSearchExecuted(true);
        setIsExecuteFilterChangedFetch(false);
    };

    //Set some delay when typing
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            setIsExecuteFilterChangedFetch(false);
            setIsExecuteFilterChangedFetch(true);
        }, 1500)

        return () => clearTimeout(delayDebounceFn)
    }, [filter])
    
    useEffect(() => {
        if(isExecuteFilterChangedFetch === true && isTextSearchExecuted === true) {
            setIsLoading(true);
            getData(currentPage, filter).then((info) => {
            const { totalPages, data, totalData } = info.data;
                setPageData({
                isLoading: false,
                rowData: data,
                totalPages,
                totalData,
                });
                setIsLoading(false);
                setIsTextSearchExecuted(false);
                setIsExecuteFilterChangedFetch(false);
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isExecuteFilterChangedFetch, filter, currentPage,isTextSearchExecuted])

    // const onFilterChangedInvitation = useCallback((e) => {
    //     setFilterInvitation(e.target.value);

    //     if(emailExist.includes(e.target.value.toLowerCase())) {
    //         setError('emailaddress', { type: 'exist' });
    //     } else {
    //         clearErrors("emailaddress");
    //     }

    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // },[emailExist])

    useEffect(() => {
        setPageData((prevState) => ({
            ...prevState,
            rowData: [],
            isLoading: true,
        }));

        getData(currentPage, filter).then((response) => {
            if(response !== undefined) {
                const { totalPages, data, totalData } = response.data;
                setPageData({
                    isLoading: false,
                    rowData: data,
                    totalPages,
                    totalData,
                });
            }
        });
        setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);


    return <>
            <div className="AgenciesDiv">
                <Row>
                    <Col md="3">
                        {isSuper &&
                            <label className="titlediv">Agencies</label>
                        }
                        {!isSuper &&
                            <label className="titlediv">My Departments</label>
                        }
                    </Col>
                    {isSuper &&<Col md="3"></Col>}
                    {!isSuper &&<Col md="6"></Col>}

                    {isSuper &&
                        <Col md="4">
                            <div className="input-group">
                            <span className="input-group-prepend">
                                <div className="input-group-text  border-right-0"> <FontAwesomeIcon icon={faSearch} /></div>
                                </span>
                                <Input placeholder="Search..." onChange={onFilterChanged} value={filter === undefined ? "" : filter} type="text" className="form-control" />

                            <span className="input-group-append">

                                </span>
                            </div>

                        </Col>

                    }

                    {/** NBC CJIS - 4953 - Remove ability to add agencies within the app **/}
                    {/* {(isSuper && appNBCContext.isSuperAdmin) &&
                        <Col md="2">
                            <button className="btn btn-primary" onClick={RedirectAddAgency}>Add Agency</button>
                        </Col>
                    } */}
                    {!isSuper &&
                        <Col md="3">

                        <div className="input-group">
                            <span className="input-group-prepend">
                                <div className="input-group-text  border-right-0"> <FontAwesomeIcon icon={faSearch} /></div>
                                </span>
                                <Input placeholder="Search..." onChange={onFilterChanged} value={filter === undefined ? "" : filter} type="text" className="form-control" />

                                <span className="input-group-append">

                                </span>
                            </div>
                        </Col>

                    }

            </Row>
            {isLoading &&
                <LoaderAgency/>
            }
            {!isLoading &&
                <>
                <div>
                    <Table columns={columns} data={pageData.rowData}></Table>
                </div>
                <Pagination
                    totalRows={pageData.totalData}
                    pageChangeHandler={setCurrentPage}
                    rowsPerPage={15}
                />

                {pageData.totalData === 0 &&

                    <NoResultFoundContainer message={"No results found. Try another keywords..."} />
                }
                </>
            }
            </div>
        
        {/* <Modal size="lg" show={modal}>
            <form onSubmit={sendUserInvitation}>
                <ModalHeader>
                    Add new officer
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xl="3">
                            <Label>Email officer</Label>
                        </Col>
                        <Col>
                            <div className="input-group mb-3">
                                <input id="email" name="email" onChange={onFilterChangedInvitation} value={filterinvitation ?? ""} required type="email" className="form-control"  aria-label="Email officer" aria-describedby="basic-addon1" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" />
                            </div>

                            {errors.emailaddress && errors.emailaddress.type === "exist" && 
                                <div className="input-group mb-3">
                                    <span className="text-danger">Email address is already exist</span>
                                </div>
                            }
                        </Col>
                    </Row>
                    <Row>
                        {(validation !== undefined && validation.error === true) && <p className="alert alert-danger">{validation.message}</p>}
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary"type="submit" disabled={errors.emailaddress && errors.emailaddress.type === "exist"}>Send invitation</Button>
                    {' '}
                    <Button onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </form>
        </Modal> */}


        <Modal size="sm" show={modalDelete.show}>
            <ModalBody>
                <Row>
                    <label className="bodyagencies" >Are you sure you want to delete the {modalDelete.name} agency?</label>
                </Row>
            </ModalBody>
            <ModalFooter>
                
                <Button
                    className="btncancel"
                    onClick={toggleDelete}>
                    Cancel
                </Button>
                {' '}
                <Button
                    className="btnaccept"
                    color="primary"
                    onClick={() => DeleteAgency(modalDelete.id)}
                >
                    Delete
                </Button>
              
            </ModalFooter>
        </Modal>
        <Modal size="sm" show={modalRestore.show}>
            <ModalBody>
                <Row>
                    <label className="bodyagencies" >Are you sure you want to restore the {modalDelete.name} agency?</label>
                </Row>
            </ModalBody>
            <ModalFooter>
                
                <Button
                    className="btncancel"
                    onClick={toggleRestore}>
                    Cancel
                </Button>
                {' '}
                <Button
                    className="btnaccept"
                    color="primary"
                    onClick={() => RestoreAgency(modalRestore.id)}
                >
                    Restore
                </Button>
              
            </ModalFooter>
        </Modal>

        <Modal size="mb" show={showUsers} onHide={handleClose}>
            <ModalHeader closeButton>
                <h5>Manage</h5>
            </ModalHeader>
            <ModalBody>
                <AzureManageAdmins Agency={manageAgency} />
            </ModalBody>
            
        </Modal>
        {/* <ModalAlert Toggle={toggleInvitation} Message="An invitation was sent" Show={modalInvitation} /> */}
        <ModalAlert Toggle={toggleAddedRequest} Message="Your membership application is now approved. You can now view the posts of the department." Show={modalRequest} />
        <ModalAlert Toggle={togglePendingRequest} Message="Thank you for applying to join the department! Your application has been sent and will be reviewed by an administrator for approval." Show={modalPendingRequest} />
        <ModalAlert Toggle={toggleRemoveRequest} Message="Your application has been canceled." Show={modalRemoveRequest} />
        <ModalAlert Toggle={toggleModalDefaultAgency} Message="You are not allowed to sign out from your main agency" Show={modalDefaultAgency} />

    </>;
}

export default AzureRequestedAgencies;