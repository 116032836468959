import React, {useEffect, useState, useCallback} from "react";
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import Autocomplete from "react-google-autocomplete";
import InputMask from "react-input-mask";
import agencyimg from "../../../assets/agency.png"
import { faInfo, faLocationArrow, faEnvelope, faMobile,faGlobe} from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UsedAppNBCContext } from "../../AppNBC.Provider";
import useFetchWithMsal from "../../hooks/useFetchWithMsal";
import { protectedEndPointAPI, protectedVariable } from "../../Helpers/ProtectedCustomClass";

import "./AzureAgency.css"

library.add(faInfo, faLocationArrow, faEnvelope, faMobile, faGlobe);
const AzureAgency = () => {

    const queryParams = new URLSearchParams(window.location.search);
    const agencyId = queryParams.get('agencyId');

    let history = useHistory();

    const appNBCContext = UsedAppNBCContext();
    // eslint-disable-next-line no-unused-vars
    const { error, execute,executeFormData } = useFetchWithMsal();


    const [isInitialLoadDone, setIsInitialLoadDone] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const { register, handleSubmit, formState: { errors }, setError, clearErrors } = useForm({
        mode: 'onChange'
    });

    const [agency, setAgency] = useState({
        agencyName:"",
        locationinput: "",
        selectedImage: null,
        agencyId: 0,
        addressId: 0,
        latitude: 0.0,
        longitude: 0.0,
        phone: "",
        imageRemoved: false
    });

    const [agencyExist, setAgencyExist] = useState(undefined);
    const [originalAgencyName, setOriginalAgencyName] = useState("");
    const [location, setLocation] = useState({
    });

    const onChangeLocation = e => {
        setLocation({
            ...location, e
        });
    }

    const onChangeInputMap = e => {
        setAgency({
            ...agency,
            locationinput: e.target.value
        });
    }

    const onChangeName = useCallback(e => {
        if (e.target.value !== "" || e.target.value !==null) {
            if(agencyExist !== undefined) {

                let _agencyExist;

                _agencyExist = (agencyId !== undefined && agencyId > 0) 
                ? 
                _agencyExist = agencyExist.filter(function (x) { return x !== originalAgencyName; })
                :
                _agencyExist = agencyExist

                if(_agencyExist.includes(e.target.value.toLowerCase())) {
                    setError('agencyinput', { type: 'exist' });
                } else {
                    clearErrors("agencyinput");
                }
            } else {
                clearErrors("agencyinput");
            }
        } else {
            clearErrors("agencyinput");
        }

        setAgency({
            ...agency,
            agencyName: e.target.value
        });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[setError,agency.selectedImage, agencyExist,originalAgencyName])

    const onCancel = () => {
        history.push("/agencies");
    }

    const onChangePhone = e => {

        let _tempPhone = e.target.value.replace(/\D/g,'');
        if(_tempPhone.trimEnd() !== "") {
            if (  _tempPhone.length < 11) {
                setError('phone', { type: 'invalid-format' });
            } else {
                clearErrors("phone");
            }
        } else {
            clearErrors("phone");
        }

        setAgency({
            ...agency,
            phone: e.target.value
        });
    }

    const onChangeManualRequests = e => {
        setAgency({
            ...agency,
            manualrequest: !agency.manualrequest
        });
    }

    const onChangeBulkRequest = e => {
        setAgency({
            ...agency,
            bulkrequest: !agency.bulkrequest
        });
    }

    const onChangeEmail = e => {
        setAgency({
            ...agency,
            email: e.target.value
        });
    }

    const onChangeUrl = e => {
        setAgency({
            ...agency,
            url: e.target.value
        });
    }

    const getAgencyExist = useCallback(async () => {
        if(appNBCContext.dbUserId !== null) {
            execute("GET", protectedEndPointAPI.agencies.list.exist).then((response) => {
                var _data = response.data;
                if(_data !== null)  {
                    setAgencyExist(response.data.data);
                }
            });
        }
    },[execute,appNBCContext])

    const getAgency = useCallback(async () => {
        if(appNBCContext.dbUserId !== null) {
            if(!isLoading) setIsLoading(true);
            execute("PUT", protectedEndPointAPI.agencies.information.base,agencyId).then((response) => {
                var _data = response.data;
                if(_data !== null)  {
                    setAgency({
                        ...agency,
                        id: response.data.id,
                        agencyName: response.data.name,
                        locationinput: response.data.address.address1,
                        addressId: response.data.address.googlePlaceId,
                        latitude: response.data.address.latitude,
                        longitude: response.data.address.longitude,
                        agencyId: response.data.id,
                        phone: response.data.phone ? response.data.phone:"",
                        email: response.data.email ? response.data.email : "",
                        url: response.data.url ? response.data.url : "",
                        manualrequest: response.data.manageRequest,
                        img: response.data.azureAgencyProfileImage ? response.data.azureAgencyProfileImage.url : "",
                        bulkrequest: response.data.bulkRequest
                    });
                    setOriginalAgencyName(response.data.name.toLowerCase());
                    setIsLoading(false);
                }
            });
        }
    },[appNBCContext.dbUserId, execute, agencyId, agency,isLoading])

    const SaveAgency = useCallback((event) => {
        if(appNBCContext.dbUserId !== null) {

            if(agency.phone !== undefined) {
                let _tempPhone = agency.phone.replace(/\D/g,'');
                if(_tempPhone.trimEnd() !== "") {
                    if (  _tempPhone.length < 11) {
                        setError('phone', { type: 'invalid-format' });
                        return ;
                    } else {
                        clearErrors("phone");
                    }
                } else {
                    clearErrors("phone");
                }
            }

            if(!isLoading) setIsLoading(true);
            const _requestFormData = new FormData();
            _requestFormData.append("UserId", appNBCContext.dbUserId);
            _requestFormData.append("IsSuperAdmin", appNBCContext.isSuperAdmin);
            _requestFormData.append("IsAdmin", appNBCContext.isAdmin);
            _requestFormData.append("AgencyName", agency.name);
            _requestFormData.append("AgencyId", agency.agencyId);
            _requestFormData.append("State", location.e === undefined ? "" : (location.e ? location.e.address_components[2].long_name : agency.locationinput));
            _requestFormData.append("AddressId", location.e ? location.e.place_id : agency.addressId);
            _requestFormData.append("NameAddress", location.e === undefined ? agency.locationinput : (location.e ? location.e.formatted_address : agency.locationinput));
            _requestFormData.append("Latitude", location.e ? location.e.geometry.location.lat() : agency.latitude);
            _requestFormData.append("Longitude", location.e ? location.e.geometry.location.lng() : agency.longitude);
            _requestFormData.append("AgencyProfileImage", agency.selectedImage);
            _requestFormData.append("Phone", agency.phone);
            _requestFormData.append("Email", agency.email);
            _requestFormData.append("Url", agency.url);
            _requestFormData.append("ManualRequest", agency.manualrequest ? agency.manualrequest:false);
            _requestFormData.append("BulkRequest", agency.bulkrequest ? agency.bulkrequest : false);
            _requestFormData.append("IsAgencyProfileImageUpdated", agency.imageRemoved);

            executeFormData("POST", protectedEndPointAPI.agencies.information.save, _requestFormData).then((response) => {
                //var _data = response.data;
                if(response.success) {
                    //NotificationManager.success('Success', 'Successfully save the agency information', 5000); // not good on adding notification, still need to find a better approach
                    setIsLoading(false);
                    setTimeout(function () { history.push("/agencies"); }, 200);
                } else {
                    //NotificationManager.error("Error while saving the agency information", 5000); // not good on adding notification, still need to find a better approach
                    console.log(response.message);
                    setTimeout(function () { history.push("/agencies"); }, 200);
                }
                
            });


        }
    },[appNBCContext.dbUserId, appNBCContext.isSuperAdmin, appNBCContext.isAdmin, agency, location.e, executeFormData, setError, clearErrors, history, isLoading])
    
    useEffect(() => {
        if(!isInitialLoadDone) {
            getAgencyExist();
            if(agencyId !== undefined && agencyId > 0 ) {
                getAgency();
            }
            setIsInitialLoadDone(true);
        }
       
    },[isInitialLoadDone, getAgencyExist,agencyId,getAgency]);

    return (
        <>
            <div className="row">
                <div className="col-3">
                </div>
                <div className="col-6">
            <div className="card-container">
                <div className="card">
                        <form onSubmit={handleSubmit(SaveAgency)}>
                        <div className="card-body">
                            {agencyId !== undefined && agencyId > 0 &&
                                <h5 className="card-titleagency">Edit Agency</h5>
                            }
                            {agencyId === undefined && agencyId === 0 &&
                                <h5 className="card-titleagency">Add new Agency</h5>
                            }
                            <div className="row centerimg">
                                {agency.img && !agency.selectedImage &&
                                    <img alt="" src={agency.img} className="iconagency" />
                                }
                                {!agency.selectedImage && !agency.img &&
                                    <img alt="" src={agencyimg} className="iconagency" />
                                }
                                {agency.selectedImage && (
                                    <div>
                                        <img alt="Not Found" className="iconagencysaved" src={URL.createObjectURL(agency.selectedImage)} />
                                        <br />
                                        <button disabled={isLoading} className="btn btn-danger" onClick={() => setAgency({
                                            ...agency,
                                            selectedImage: null,
                                            imageRemoved: true
                                        })}>Remove</button>
                                    </div>
                                )}
                                <br /> <br />
                                <input
                                    className={errors.image ? 'form-control is-invalid' : 'form-control'}
                                    type="file"
                                    name="myImage"
                                    disabled={isLoading}

                                    {...register("image", { required: (agency.id === 0 || (agency.id !== 0 && agency.img == null)) })}
                                    onChange={(event) => {
                                        setAgency({
                                            ...agency,
                                            selectedImage: event.target.files[0],
                                            imageRemoved: true
                                        });
                                        clearErrors("image");
                                    }}
                                />
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <label className="lb">Name</label>
                                </div>
                                <div className="col-md-12 col-sm-12">
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1">  <FontAwesomeIcon icon={faInfo} /></span>
                                        </div>
                                        <input  readOnly type="text" className="form-control" onChange={onChangeName} value={agency.agencyName} placeholder="Enter Agency's name" aria-label="Username" aria-describedby="basic-addon1" />
                                    </div>
                                    {errors.agencyinput && errors.agencyinput.type === "exist" && 
                                    <div className="input-group mb-3">
                                        <span className="text-danger">Agency name is already exist</span>
                                    </div>
                                    
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <label className="lb">Location</label>
                                </div>
                                <div className="col-md-12 col-sm-12">
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1">  <FontAwesomeIcon icon={faLocationArrow} /></span>
                                        </div>
                                        <Autocomplete
                                            disabled={isLoading}
                                            apiKey={protectedVariable.GoogleMapAPIKey}
                                            className="form-control"
                                            placeholder="Enter Agency's location"
                                            defaultValue={agency.locationinput}
                                            options={{componentRestrictions: { country: "us" },}}
                                            onChange={onChangeInputMap}
                                            required
                                            onPlaceSelected={(place) => {onChangeLocation(place);}}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <label className="lb">Phone</label>
                                </div>
                                <div className="col-md-12 col-sm-12">
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1">  <FontAwesomeIcon icon={faMobile} /></span>
                                        </div>
                                        <InputMask disabled={isLoading} placeholder="Enter Agency's Phone" onChange={onChangePhone} value={agency.phone} className="form-control" mask="+1\ 999 999 9999" />
                                        
                                    </div>
                                    {errors.phone && errors.phone.type === "invalid-format" && <span className="text-danger">Invalid format</span>}
                                </div>
                            </div>
                            <div className="row">

                                <div className="col-md-12 col-sm-12">
                                    <label className="lb">Email</label>
                                </div>
                                <div className="col-md-12 col-sm-12">
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1">  <FontAwesomeIcon icon={faEnvelope} /></span>
                                        </div>
                                        <input disabled={isLoading} id="email" name="email" onChange={onChangeEmail} value={agency.email} required type="email" className="form-control" placeholder="Enter Agency's E-mail" aria-label="Username" aria-describedby="basic-addon1" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" />
                                    </div>
                                </div>

                            </div>
                            <div className="row">

                                <div className="col-md-12 col-sm-12">
                                    <label className="lb">Url</label>
                                </div>
                                <div className="col-md-12 col-sm-12">
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1">  <FontAwesomeIcon icon={faGlobe} /></span>
                                        </div>
                                        <input disabled={isLoading} onChange={onChangeUrl} value={agency.url} type="url" className="form-control" placeholder="Enter Agency's url" aria-label="Username" aria-describedby="basic-addon1" />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <label className="lb"> Admin settings</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-9"> 
                                    <label className="lb"> Manual join requests</label> 
                                </div>
                                <div className="col-3 toggle-switch"> 
                                    <input disabled={isLoading} type="checkbox" checked={agency.manualrequest} className="toggle-switch-1" /> 
                                    <label onClick={onChangeManualRequests}></label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-9"> 
                                    <label className="lb"> Bulk processing of requests</label> 
                                </div>
                                <div className="col-3 toggle-switch"> 
                                    <input  disabled={isLoading} type="checkbox" id="topping" name="topping" value="Paneer" checked={agency.bulkrequest} className="toggle-switch-1" /> 
                                    <label onClick={onChangeBulkRequest}> </label>
                                </div>
                            </div>             
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    {agencyId !== undefined && agencyId > 0 &&
                                        <button disabled={isLoading} type="submit" className="btn btn-primary btn-full" >
                                             {isLoading === true ? (
                                                    <span className="spinner-border spinner-border-sm"></span> 
                                                ) : (<></>)} 
                                                &nbsp; &nbsp;
                                            Save Changes
                                        </button>
                                    }
                                    {/** NBC CJIS - 4953 - Remove ability to add agencies within the app **/}
                                    {/* {(agencyId === undefined || agencyId == null || agencyId === 0 )  &&
                                        <button type="submit" className="btn btn-primary btn-full" >+ Add Agency</button>
                                    } */}
                                    <button type="button" className="btn btn-secondary btn-fullx" onClick={onCancel} >Cancel</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
                </div>
            </div>
           
        </>
    );
}

export default AzureAgency;