import AzureDepartmentApp from "./AzureDepartment";
import DepartmentProvider from "../../../components/Department/Department.Provider";

export const AzureDepartment = () => {
    return (
        <DepartmentProvider>
            <AzureDepartmentApp />
        </DepartmentProvider>
    );
}
