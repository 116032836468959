import React, { useContext, useState } from "react";

export const AppNBCContext = React.createContext();


export const UsedAppNBCContext = () => {
    return useContext(AppNBCContext);
};



const AppNBCProvider = ({ children }) => {

    const [dbUserId, setDbUserId] = useState("");
    const onSetDbUserId = (val) => {
        setDbUserId(val);
    }

    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const onSetIsSuperAdmin = (val) => {
        setIsSuperAdmin(val);
    }

    const [isAdmin, setIsAdmin] = useState(false);
    const onSetIsAdmin = (val) => {
        setIsAdmin(val);
    }

    const [userRoles, setUserRoles] = useState([]);
    const onUserRoles = (val) => {
        setUserRoles(val);
    }

    const [dbAgencyId, setDbAgencyId] = useState(0);
    const onSetDbAgencyId = (val) => {
        setDbAgencyId(val);
    }

    const [imagePath, setImagePath] = useState("");
    const onSetImagePath = (val) => {
        setImagePath(val);
    }

    const [imageBase64String, setImageBase64String] = useState("");
    const onSetImageBase64String = (val) => {
        setImageBase64String(val);
    }

    const [dbIsDeleted, setDbIsDeleted] = useState(false);
    const onSetDbIsDeleted = (val) => {
        setDbIsDeleted(val);
    }

    const [dbIsChief, setDbIsChief] = useState(false);
    const onSetDbIsChief = (val) => {
        setDbIsChief(val);
    }

    const [signalRConnection, setSignalRConnection] = useState(null);
    const onSetSignalRConnection = (val) => {
        setSignalRConnection(val);
    }

    const [signalRdisabled, setSignalRdisabled] = useState(false); //Set to "true" to disable the signalR temporarily when performance issue occur upon local development/debugging
    const onSetSignalRdisabled = (val) => {
        setSignalRdisabled(val);
    }

    const [isFileEncryptionEnable, setIsFileEncryptionEnable] = useState(true); //Value here should be the same with Web API config
    const onSetIsFileEncryptionEnable = (val) => {
        setIsFileEncryptionEnable(val);
    }

    const [isGoogleMapAddressRequired, setIsGoogleMapAddressRequired] = useState(false); //Set to "false" to set the input not a required field when the api key is expired
    const onSetIsGoogleMapAddressRequired = (val) => {
        setIsGoogleMapAddressRequired(val);
    }

    const [agencyGeoLoc, setAgencyGeoLoc] = useState(null);
    const onSetAgencyGeoLoc = (val) => {
        setAgencyGeoLoc(val);
    }

    const props = {
        dbUserId,
        onSetDbUserId,

        isSuperAdmin,
        onSetIsSuperAdmin,

        isAdmin,
        onSetIsAdmin,

        userRoles,
        onUserRoles,

        dbAgencyId,
        onSetDbAgencyId,

        imagePath,
        onSetImagePath,

        imageBase64String,
        onSetImageBase64String,

        dbIsDeleted,
        onSetDbIsDeleted,

        dbIsChief,
        onSetDbIsChief,

        signalRConnection,
        onSetSignalRConnection,

        signalRdisabled,
        onSetSignalRdisabled,

        isFileEncryptionEnable,
        onSetIsFileEncryptionEnable,

        isGoogleMapAddressRequired,
        onSetIsGoogleMapAddressRequired,

        agencyGeoLoc,
        onSetAgencyGeoLoc
    };
  
    return (
      <AppNBCContext.Provider value={props}>{children}</AppNBCContext.Provider>
    );

};


export default AppNBCProvider