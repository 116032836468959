/* eslint-disable jsx-a11y/alt-text */
import Avatar from 'react-avatar';
import { Accordion, Row, Col } from 'react-bootstrap';
import userCard from "../../assets/CardUser.svg";
import phone from "../../assets/Phone.svg";
import email from "../../assets/Email.svg";


const AdminPreview = (props) => {
    return <>
        <div className="divInlineBlock">
            <Accordion className="dropdownAdminPreview" >
                <Accordion.Item className="dropdownAdminPreview" eventKey="0">
                    <Accordion.Header>
                        <Row>
                            <Col>

                                {props.data.urlPic && <img className="picUrlPreviewAdmin" src={props.data.urlPic} alt="" />}
                                {(props.data.urlPic === undefined || props.data.urlPic === "") && <Avatar className="sb-avatar--textPreview" size="30" round="50%" name={props.data.name}></Avatar>}
                                &nbsp;<label className="nameProfile">{props.data.name}</label>
                                
                            </Col>
                        </Row>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className="accordionItem">
                          
                            <object className="svgIcon" data={userCard} />&nbsp;<label>{props.data.address}</label>
                        </div>
                        {(props.data.phone && props.data.phone !== "" && props.data.phone !== " ") && <div className="accordionItem">
                            <a href={"tel:" + props.data.phone}>
                                <object className="svgIcon" data={phone} />&nbsp; {props.data.phone}
                            </a>
                        </div>}
                        {(props.data.email && props.data.email !== "" && props.data.email !== " ") && <div className="accordionItem">
                            <a href={"mailto:" + props.data.email}>
                                <object className="svgIcon" data={email} />&nbsp; {props.data.email}
                            </a>
                        </div>}
                        {/**NBC CJIS - 4967 - remove button for messaging***/}
                        {/* <div className="accordionItem">
                            <button className="btn btn-outline-primary btn-lg btn-bloc col-lg-12">Message</button>
                        </div> */}
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    </>
}

export default AdminPreview;