import { Col, Row } from "reactstrap";
import { format } from 'date-fns'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserGroup } from "@fortawesome/free-solid-svg-icons";

const AzureChatMessageBar = (props) => (
    <div style={{ borderRadius: '5px', padding: '0 10px' }}>
        <Row>
            <Col>
                {
                    (props.chat.urlPic !== undefined && props.chat.urlPic !== null) &&
                    <img src={props.chat.urlPic} className="avatar" alt="" />
                }
                {
                    (props.chat.urlPic === undefined || props.chat.urlPic === null) && 
                    <FontAwesomeIcon icon={faUserGroup} size="2xl" />
                }
            </Col>
            <Col>{props.chat.user}</Col>
            <Col>
                {format(new Date(props.chat.created), 'dd/MM/yyyy | HH:mm')}
            </Col>
        </Row>
        <Row>
            <p>{props.chat.body}</p>
        </Row>
    </div>
);

export default AzureChatMessageBar;