import React, { useCallback, useState, useEffect } from "react";
import Table from "../../../components/Common/table";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareSquare } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core';
import Pagination from "../../../components/Common/pagination";
import { faEdit, faTrash, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import "./AzureSharePost.css"
import { Row, Col } from 'reactstrap';
import Avatar from 'react-avatar';
import { UsedAppNBCContext } from "../../AppNBC.Provider";
import useFetchWithMsal from "../../hooks/useFetchWithMsal";
import { protectedEndPointAPI } from "../../Helpers/ProtectedCustomClass";

library.add(faEdit, faTrash, faMagnifyingGlass, faShareSquare);

const AzureSharePost = (props) => {

    const appNBCContext = UsedAppNBCContext();
    // eslint-disable-next-line no-unused-vars
    const { error, execute } = useFetchWithMsal();

    const { SelectUser } = props;
    // eslint-disable-next-line no-unused-vars
    const [division, setDivision] = useState(0);
    // eslint-disable-next-line no-unused-vars
    const [agencyId, setAgencyId] = useState(props.AgencyId !== undefined ? props.AgencyId : '');
    const [search, setSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(1);

    const [isExecuteSearchPost, setIsExecuteSearchPost] = useState(false);
    const [isTextSearchExecuted, setIsTextSearchExecuted] = useState(false);

    const Search = (e) => {
        setSearch(e.target.value);
        setIsTextSearchExecuted(true);
        setIsExecuteSearchPost(false);
    }

    const SearchPost = useCallback(() => {
        getData(currentPage, search).then((info) => {
            const { totalPages, data, totalData } = info.data;
            setPageData({
                isLoading: false,
                rowData: data,
                totalPages,
                totalData,
            });
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[search,currentPage])

    const getData = useCallback(async (pageNo = 1, search = "") => {
        if(appNBCContext.dbUserId !== null) {
            let _requestBody = {
                Take: 5,
                Page: pageNo,
                UserId: appNBCContext.dbUserId,
                IsSuperAdmin: appNBCContext.isSuperAdmin,
                IsAdmin: appNBCContext.isAdmin,
                DivisionId: division,
                AgencyId: agencyId,
                SearchCriteria: search,
            }

            return execute("PUT", protectedEndPointAPI.officer.getlist, _requestBody);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[execute,appNBCContext]);

    const columns = [
        {
            Header: "Recents",
            accessor: "name",
            Cell: cellInfo => 
                <>
                    <Row>
                        <Col md="2">   <Avatar className="avatarsharepost" src={cellInfo.data[cellInfo.row.index].urlPic} name={cellInfo.data[cellInfo.row.index].name} round size="40" /></Col>
                        <Col md="8"><label className="mgt3 lblnameofficer" > {cellInfo.data[cellInfo.row.index].name}</label> </Col>
                        <Col md="2" onClick={(event) => SelectUser(cellInfo.data[cellInfo.row.index])}>    <FontAwesomeIcon className="mgt25" icon={faShareSquare} size="xl" /> </Col>
                    </Row>
                </>
             
        }
    ];

    const [pageData, setPageData] = useState({
        rowData: [],
        isLoading: false,
        totalPages: 0,
        totalData: 0,
    });

    
    useEffect(() => {
        setPageData((prevState) => ({
            ...prevState,
            rowData: [],
            isLoading: true,
        }));
        getData(currentPage).then((info) => {
            const { totalPages, data, totalData } = info.data;
            setPageData({
                isLoading: false,
                rowData: data,
                totalPages,
                totalData,
            });
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    //Set some delay when typing
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            setIsExecuteSearchPost(false);
            setIsExecuteSearchPost(true);
        }, 1500)

        return () => clearTimeout(delayDebounceFn)
    }, [search])

    useEffect(() => {
        if(isExecuteSearchPost === true && isTextSearchExecuted === true) {
            SearchPost();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isExecuteSearchPost,isTextSearchExecuted])

    return (
        <>
            <div>
                <br />
                <div className="row">

                    <div className="col-12">
                        <div className="input-group mb-12">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1">
                                    <FontAwesomeIcon className="svgIconGrey" icon={faMagnifyingGlass} />
                                </span>
                            </div>
                            <input type="text" className="form-control" placeholder="Keywords search..." onChange={Search} value={search} />
                        </div>
                    </div>
                </div>
          
                <div>
                    <Table
                        columns={columns}
                        data={pageData.rowData}
                        isLoading={pageData.isLoading}
                    />
                    {pageData.totalData === 0 &&
                        <h2 className="noitems">
                            NO DATA.
                        </h2>
                    }
                </div>
                <Pagination
                    totalRows={pageData.totalData}
                    pageChangeHandler={setCurrentPage}
                    rowsPerPage={5}
                />
            </div>
 

        </>
    );
}
export default AzureSharePost;