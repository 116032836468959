import React from "react";
import IncidentCard from '../Common/IncidentCard';


const CardPostListCard = (props) => {

    return (
    <>
        <br/><br/>
       <h2>Posts</h2><hr></hr>
       <br/>
        {props.listpost.map((post) => (
            <>
                <IncidentCard SetNotification={props.SetNotification} DeleteIncident={props.DeleteIncident} LoadIncidents={props.LoadIncidents} OpenFolders={props.OpenFolders} OpenSharePost={(event) => props.OpenSharePost(post)} OpenReportPost={(event) => props.OpenReportPost(post)} IsSuperAdmin={props.IsSuperAdmin}  item={post} />
                <br/>
            </>
        )
        )}
    </>);

}


export default CardPostListCard;