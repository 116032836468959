import { faBuilding, faCircle, faMessage, faPhone, faUserGroup, faAt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col } from 'reactstrap';
import { library } from '@fortawesome/fontawesome-svg-core';
import { Link } from 'react-router-dom';
import Avatar from 'react-avatar';
library.add(faUserGroup, faCircle, faBuilding, faMessage, faPhone, faAt)

const ModalProfileCard = (props) => {
    console.log(props);
    const { user } = props;
    return (
        <>
            <Row>
                <Col>

                    {user.urlPic && <img className="picUrlPreviewAdmin" src={user.urlPic} alt="" />}
                    {(user.urlPic === undefined || user.urlPic === "") &&
                     <Avatar className="sb-avatar--textPreview" size="30" round="50%" name={user.name}></Avatar>}
                    &nbsp;<label className="nameProfile">{user.name}</label>

                </Col>
                <Col>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FontAwesomeIcon icon={faCircle} color="green" />
                    <strong>{user.status}</strong>
                </Col>
                <Col sm="7">
                    <FontAwesomeIcon icon={faBuilding} />
                    <strong>{user.departament}</strong>
                </Col>
            </Row>
            <Row>
                <hr />
            </Row>
            <Row>
                <Col sm="1">
                    <FontAwesomeIcon icon={faMessage} />
                </Col>
                <Col>
                    <h6><Link to={{
                        pathname: '/',
                        state: { userId: user.id, Chat_Id: 0 } 
                    }}>MESSAGE OFFICER</Link></h6>
                </Col>
            </Row>
            {
                user.showPhone && 
                <Row>
                    <Col sm="1">
                        <FontAwesomeIcon icon={faPhone} />
                    </Col>
                    <Col>
                        <h6 ><a href={`tel:${user.phone ?? ""}`}>PHONE OFFICER</a></h6>
                    </Col>
                </Row>
            }

            {
                user.showEmail &&
                <Row>
                    <Col sm="1">
                        <FontAwesomeIcon icon={faAt} />
                    </Col>
                    <Col>
                        <h6><a href={`mailto:${user.email}`}>EMAIL OFFICER</a></h6>
                    </Col>
                </Row>
            }
            
        </>
    );
}

export default ModalProfileCard;