import React, {useCallback} from "react";
import { faCircleXmark, faTrashCan
    , faTriangleExclamation, faPencil
    , faUndo, faClipboardUser } 
from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col} from 'reactstrap';
import { library } from '@fortawesome/fontawesome-svg-core';
import './AzureAccounts.Option.css';

import { UsedAppNBCContext } from "../../AppNBC.Provider";
import useFetchWithMsal from "../../hooks/useFetchWithMsal";
import { protectedEndPointAPI } from "../../Helpers/ProtectedCustomClass";

library.add(faCircleXmark, faTrashCan, faTriangleExclamation, faPencil, faUndo, faClipboardUser)

const AzureAccountsOption = (props) => {

    const appNBCContext = UsedAppNBCContext();
    // eslint-disable-next-line no-unused-vars
    const { error, execute } = useFetchWithMsal();


    const { data
        ,EditFunction
        ,ViewFunction
        ,DeleteFunction
        ,RestoreAccount
        } = props;

    const removeRelate = useCallback((data) => {
        if(appNBCContext.dbUserId !== null) {
            let _requestBody = {
                Id: data.Id 
            }
            execute("POST", protectedEndPointAPI.accounts.bookmark.delete, _requestBody).then((response) => {
                var _data = response.data;
                if(_data !== null) { 
                    props.LoadIncidents();
                }
            });

        }
    },[appNBCContext.dbUserId, execute, props])


    return (
        <>
           
            <Row className="option" style={{border: "0px"}}>
                {

                    (data.relateCrimes && data.relateCrimes.length > 0) &&
                    <>
                        <Col md="2" className="centerDivVerticall">
                            <FontAwesomeIcon className="iconPopup" icon={faCircleXmark} size="xl" />
                        </Col>
                        <Col md="6">
                            <a className="transparentLink" href="#" onClick={() => removeRelate({ Id: data.relateCrimes[0].id })}>
                                Remove from {' '}
                                {data.relateCrimes[0].name}
                            </a>
                        </Col>
                    </>
                }
            </Row>

            {
                data.canEdit && data.canEdit === true && <Row className="option">

                    <Col md="2">
                        <a href={"/post/edit/" + data.id}>
                            <FontAwesomeIcon className="iconPopup" icon={faPencil} size="xl" />
                        </a>
                    </Col>
                    <Col md="5">
                        <a href={"/post/edit/" + data.id}>
                            Edit Post
                        </a>
                    </Col>

                </Row>
            }

            {
                (data.deleted || data.statusApp === "Suspended" 
                    ? 
                    <Row className="option" style={{border: "0px"}} onClick={()=>ViewFunction(data)}>
                        <Col md="2">
                            <FontAwesomeIcon className="iconPopup" icon={faClipboardUser} size="xl" />
                        </Col>
                        <Col md="10">
                            View Account
                        </Col>
                    </Row> 
                    :
                    <Row className="option" style={{border: "0px"}} onClick={()=>EditFunction(data)}>
                        <Col md="2">
                            <FontAwesomeIcon className="iconPopup" icon={faPencil} size="xl" />
                        </Col>
                        <Col md="10">
                            Edit Account
                        </Col>
                    </Row>  
                )
            }
            {
                (
                    data.deleted ? 
                    (
                        <Row className="option" style={{border: "0px"}} onClick={() => RestoreAccount(data)}>
                        <Col md="2">
                            <FontAwesomeIcon className="iconPopup" icon={faUndo} size="xl" />
                        </Col>
                        <Col md="10">
                            Restore Account
                        </Col>
                      </Row>
                    )
                    : 
                    ( 
                      <Row className="option" style={{border: "0px" }} onClick={() => DeleteFunction(data)}>
                        <Col md="2">
                            <FontAwesomeIcon className="iconPopup" icon={faTrashCan} size="xl" />
                        </Col>
                        <Col md="10">
                            Deactivate Account
                        </Col>
                      </Row>
                    )
                )

            }
           

        </>
    );
}
export default AzureAccountsOption;