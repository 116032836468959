import React from "react";

function WordIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="700"
      height="700"
      version="1.1"
      viewBox="-0.13 0 8.996 9.032"
    >
      <path
        fill="#41a5ee"
        strokeWidth="0.191"
        d="M7.165 1.847H3.097a.254.254 0 00-.254.254v1.08l2.288.668L7.42 3.18v-1.08a.254.254 0 00-.254-.254z"
      ></path>
      <path
        fill="#2b7cd3"
        strokeWidth="0.191"
        d="M7.42 3.181H2.842v1.335l2.288.668 2.288-.668z"
      ></path>
      <path
        fill="#185abd"
        strokeWidth="0.191"
        d="M7.42 4.516H2.842v1.335l2.288.667 2.288-.667z"
      ></path>
      <path
        fill="#103f91"
        strokeWidth="0.191"
        d="M7.42 5.85H2.842v1.081a.254.254 0 00.254.255h4.068a.254.254 0 00.254-.255z"
      ></path>
      <path
        strokeWidth="0.191"
        d="M4.496 2.8H2.843v3.623h1.653a.255.255 0 00.254-.254V3.054a.255.255 0 00-.254-.254z"
        opacity="0.1"
      ></path>
      <path
        strokeWidth="0.191"
        d="M4.305 2.99H2.843v3.624h1.462a.255.255 0 00.254-.255V3.245a.255.255 0 00-.254-.254z"
        opacity="0.2"
      ></path>
      <path
        strokeWidth="0.191"
        d="M4.305 2.99H2.843v3.242h1.462a.255.255 0 00.254-.254V3.245a.255.255 0 00-.254-.254z"
        opacity="0.2"
      ></path>
      <path
        strokeWidth="0.191"
        d="M4.114 2.99H2.843v3.242h1.271a.255.255 0 00.254-.254V3.245a.255.255 0 00-.254-.254z"
        opacity="0.2"
      ></path>
      <path
        fill="#185abd"
        strokeWidth="0.191"
        d="M1.572 2.99h2.542a.254.254 0 01.254.255v2.542a.254.254 0 01-.254.255H1.572a.254.254 0 01-.254-.255V3.245a.254.254 0 01.254-.254z"
      ></path>
      <path
        fill="#fff"
        strokeWidth="0.191"
        d="M3.596 5.47h-.343l-.4-1.316-.42 1.316H2.09l-.382-1.907h.344l.267 1.334.4-1.296h.286l.381 1.296.267-1.334h.324z"
      ></path>
      <path
        fill="none"
        strokeWidth="0.191"
        d="M1.318 1.465h6.101v6.102H1.318z"
      ></path>
    </svg>
  );
}

export default WordIcon;