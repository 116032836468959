import React, { useState, useEffect, useCallback } from "react";
import Avatar from 'react-avatar';
import InputMask from "react-input-mask";
import { useForm } from 'react-hook-form';
import agencyimg from "../../../assets/account-w.png"
import { Button} from 'reactstrap';

import { UsedAppNBCContext } from "../../AppNBC.Provider";
import useFetchWithMsal from "../../hooks/useFetchWithMsal";
import { protectedEndPointAPI } from "../../Helpers/ProtectedCustomClass";


const AzureAccountsModal = (props) => {

    
    const appNBCContext = UsedAppNBCContext();
    // eslint-disable-next-line no-unused-vars
    const { error, execute, executeFormData } = useFetchWithMsal();

    const { closeModal, divisions, departments,AfterCreateEditOfficer } = props;

    const { register, handleSubmit, formState: { errors }, setError, clearErrors } = useForm({
        mode: 'onChange'
    });

    const [account, setAccount] = useState(props.account);
    const [isLoading, setIsLoading] = useState(false);

    const onChangeName = e => {
        setAccount({
            ...account,
            name: e.target.value
        });
    }

    const onChangeEmail = e => {
        setAccount({
            ...account,
            email: e.target.value
        });
    }
   
    const ChangeDivision = e => {
        setAccount({
            ...account,
            divisions: e.target.value
        });
    }

    const onChangePhone = e => {

        let _tempPhone = e.target.value.replace(/\D/g,'');
        if(_tempPhone.trimEnd() !== "") {
            if (  _tempPhone.length < 11) {
                setError('phone', { type: 'invalid-format' });
            } else {
                clearErrors("phone");
            }
        } else {
            clearErrors("phone");
        }

        setAccount({
            ...account,
            phone: e.target.value
        });
    }

    const ChangeDepartment = (e) => {
        setAccount({
            ...account,
            agencyId: e.target.value
        });
    }

    const SaveAccount = useCallback((event) => {
        if(appNBCContext.dbUserId !== null) {

            if(account.phone !== undefined ) {
                if(account.phone !== null) {
                    let _tempPhone = account.phone.replace(/\D/g,'');
                    if(_tempPhone.trimEnd() !== "") {
                        if (  _tempPhone.length < 11) {
                            setError('phone', { type: 'invalid-format' });
                            return ;
                        } else {
                            clearErrors("phone");
                        }
                    } else {
                        clearErrors("phone");
                    }
                } else {
                    clearErrors("phone");
                }
               
            }
            
            setIsLoading(true);
            let isEdit = !(account.id === undefined || account.id === null || account.id === 0);

        
            const _requestFormData = new FormData();
            _requestFormData.append("UserId", account.id);
            _requestFormData.append("IsSuperAdmin", appNBCContext.isSuperAdmin);
            _requestFormData.append("IsAdmin", appNBCContext.isAdmin);
            _requestFormData.append("FullName", account.name);
            _requestFormData.append("AgencyId", account.agencyId);
            _requestFormData.append("DivisionId", account.divisions);
            _requestFormData.append("PhoneNumber", account.phone);
            _requestFormData.append("Email", account.email);
            _requestFormData.append("Status", 1);
            _requestFormData.append("AllowSearchMyProfile", false);
            _requestFormData.append("NotificationAgency", false);
            _requestFormData.append("NotificationAgencySign", false);
            _requestFormData.append("NotificationUpdateMyPost", false);
            _requestFormData.append("NotificationReportedPost", false);
            _requestFormData.append("NotificationRequests", false);
            _requestFormData.append("ShowEmail", true);
            _requestFormData.append("ShowPhone", true);
            _requestFormData.append("UserProfileImage", account.selectedImage);
            _requestFormData.append("IsUserProfileImageUpdated", account.imageRemoved === undefined ? false : account.imageRemoved);

            executeFormData("POST"
            , isEdit ? protectedEndPointAPI.accounts.officer.update : protectedEndPointAPI.accounts.officer.new
            , _requestFormData)
            .then((response) => {
                if(response.success) {
                    AfterCreateEditOfficer(true, isEdit);
                } else {
                    AfterCreateEditOfficer(false, isEdit);
                }
            });
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[appNBCContext.dbUserId, appNBCContext.isSuperAdmin, appNBCContext.isAdmin, account.phone, account.id, account.name
        , account.departaments, account.divisions, account.email, account.selectedImage, account.imageRemoved
        , executeFormData]);

    
        return (
        
            <>
                <div className="row">
                   
                    <div className="col-12">
                    <div className="containermodal">
                        <div className="row titlerowcenter">
                            {(account.id !== undefined )&&
                                <label className="accounttitle">Edit Account</label>
                            }
                            {(account.id === undefined || account.id == null || account.id === 0) &&
                                <label className="accounttitle">Add Account</label>
                            }
                            </div>
                            <div className="">
                                <form onSubmit={handleSubmit(SaveAccount)}>
                                    <div>
                                        {account.id !== undefined && account.id > 0 &&
                                            <h5 className="card-titleagency">Edit Agency</h5>
                                        }
                                        {account.id === undefined && account.id === 0 &&
                                            <h5 className="card-titleagency">Add new Agency</h5>
                                        }

                                        <div className="row centerimg">
                                        {account.urlPic && !account.selectedImage &&
                                            <img alt="" src={account.urlPic} className="iconagency" />
                                            }
                                        {!account.selectedImage && !account.urlPic &&
                                             <Avatar src={agencyimg} size="80" round className="avataraccounttext avtborder" />
                                              
                                            }
                                            {account.selectedImage && (
                                                <div>
                                                <img alt="Not Found" className="iconofficersaved" src={URL.createObjectURL(account.selectedImage)} />
                                                    <br />
                                                    <button className="btn btn-danger" onClick={() => setAccount({
                                                        ...account,
                                                        selectedImage: null,
                                                        imageRemoved: true
                                                    })}>Remove</button>
                                                </div>
                                            )}
                                            <br />
                                        {!account.selectedImage && !account.img &&
                                            <input
                                                className={errors.image ? 'form-control is-invalid' : 'form-control'}
                                                type="file"
                                                name="myImage"
                                                disabled={account.isView}

                                            {...register("image")}
                                                onChange={(event) => {
                                                    setAccount({
                                                        ...account,
                                                        selectedImage: event.target.files[0],
                                                        imageRemoved: true
                                                    });
                                                    clearErrors("image");
                                                }}
                                            />
                                        }
                                        </div>
                                        <div className="row">

                                            <div className="col-md-12 col-sm-12">
                                                <label className="lb">Name</label>
                                            </div>
                                            <div className="col-md-12 col-sm-12">
                                                <div className="input-group mb-3">
                                                   
                                                <input required type="text" className="form-control" onChange={onChangeName} value={account.name} disabled={account.id !== undefined} placeholder="Name..." aria-label="Username" aria-describedby="basic-addon1" />
                                                </div>
                                            </div>

                                        </div>
                                    

                                        <div className="row">

                                            <div className="col-md-12 col-sm-12">
                                                <label className="lb">Phone</label>
                                            </div>
                                            <div className="col-md-12 col-sm-12">
                                                <div className="input-group mb-3">
                                                  
                                                <InputMask 
                                                    placeholder="Phone" 
                                                    onChange={onChangePhone} 
                                                    value={account.phone} 
                                                    disabled={account.isView ? true : account.showPhone === false ? true : account.phone === null ? false: false} 
                                                    className="form-control" mask="+1\ 999 999 9999" />
                                               
                                                </div>
                                                {errors.phone && errors.phone.type === "invalid-format" && <span className="text-danger">Invalid format</span>}
                                            </div>

                                        </div>

                                        <div className="row">

                                            <div className="col-md-12 col-sm-12">
                                                <label className="lb">Email</label>
                                            </div>
                                            <div className="col-md-12 col-sm-12">
                                                <div className="input-group mb-3">
                                                   
                                                <input id="email" name="email" onChange={onChangeEmail} value={account.email} required type="email" disabled={account.id !== undefined} className="form-control" placeholder="E-mail" aria-label="Username" aria-describedby="basic-addon1"
                                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                                />
                                                </div>
                                            </div>

                                        </div>
                                        <div className="row">

                                            <div className="col-md-12 col-sm-12">
                                                <label className="lb">Department</label>
                                            </div>
                                            <div className="col-md-12 col-sm-12">
                                            <select className="form-control" onChange={ChangeDepartment} value={account.agencyId} required disabled={account.id !== undefined}>
                                               <option value="">Select Department</option>
                                                {departments.map((item) => (
                                                    <option key={item.value} value={item.value}>{item.label}</option>
                                                ))}
                                            </select>
                                            </div>

                                        </div>
                                    <div className="row">

                                        <div className="col-md-12 col-sm-12">
                                            <label className="lb">Division</label>
                                        </div>
                                        <div className="col-md-12 col-sm-12">
                                            <select className="form-control" onChange={ChangeDivision} value={account.divisions} disabled={account.isView} required>
                                                <option value="">Select Division</option>
                                                {divisions.map((item) => (
                                                    <option key={`division ${item.value}`} value={item.value}>{item.label}</option>
                                                ))}
                                            </select>
                                        </div>

                                    </div>

                                        <div className="row">


                                            <div className="col-md-12 col-sm-12">
                                                {account.id !== undefined  && 
                                                    (
                                                        (!account.isView) 
                                                        ? 
                                                        <div className="col-md-12 col-sm-12" style={{marginTop: "25px", marginBottom: "5px"}}>
                                                            <Button
                                                                className="col-md-12 col-sm-12"
                                                                color="primary"
                                                                disabled={isLoading}>
                                                                    Edit Officer 
                                                                    {isLoading && (<>&nbsp;&nbsp;<span className="spinner-border spinner-border-sm"></span></>)}
                                                            </Button> 
                                                        </div>
                                                      
                                                        : 
                                                        <>
                                                     
                                                        <div className="row">&nbsp;</div>
                                                        </>
                                                    )
                                                }
                                               
                                               {/** NBC CJIS - 4952 - Remove adding officer within the app.** /}
                                                {/* {(account.id === undefined || account.id == null || account.id === 0) &&
                                                <Button color="primary" className="col-md-12 col-sm-12" style={{marginTop: "25px", marginBottom: "5px"}} disabled={isLoading} >+ Add Officer {isLoading && (
                                                    <span className="spinner-border spinner-border-sm"></span>
                                                )}</Button>
                                                } */}

                                                <div className="col-md-12 col-sm-12" style={{marginTop: "10px", marginBottom: "5px"}}>
                                                    <Button className="col-md-12 col-sm-12" onClick={closeModal}>
                                                        <div style={{fontSize: "14px"}}>Cancel</div>
                                                    </Button>
                                                </div>
                                            
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
         
        </>
    );
}


export default AzureAccountsModal;