const ImageUpLoader = (props) => {
  return (
    <>
      <img
        className="imageViewer"
        alt="Not Found"
        width={"250px"}
        src={
          props.selectedImage !== null &&
          props.selectedImage !== undefined &&
          typeof props.selectedImage !== "object" &&
          props.selectedImage.startsWith("https")
            ? props.selectedImage
            : URL.createObjectURL(props.selectedImage)
        }
      />
      <br />
    </>
  );
};

export default ImageUpLoader;
