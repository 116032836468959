import React, {useCallback, useEffect, useRef, useState} from "react";
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import 'bootstrap-daterangepicker/daterangepicker.css';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import {
    ButtonFontStyle,
    CenterAllStyle,
    DatePickerField, 
    // DepartmentDropDownField, 
    HeaderLabel, 
    IncidentPostContainer, 
    InitialDateRangeState,
    NoResultFoundContainer,
    PostShimmeringEffect,
    PrimaryFontFamily,
    SearchOfficerField, 
} from "../../../components/Department/Department.Style";
import { UsedDepartmentContext } from "../../../components/Department/Department.Provider";
import ViewportList from 'react-viewport-list';
import * as _ from "lodash";
import MultiSelectWithSelectAll from "../../../components/Common/multiselect";

import { UsedAppNBCContext } from "../../AppNBC.Provider";
import useFetchWithMsal from "../../hooks/useFetchWithMsal";
import { protectedEndPointAPI } from "../../Helpers/ProtectedCustomClass";

const initialDepartmentData = [
    { value: 0, label: 'All' }
]

const initialIncidentTypeData = [
    { value: 0, label: 'All' }
]

const AzureDepartment = () => {

    const appNBCContext = UsedAppNBCContext();
    // eslint-disable-next-line no-unused-vars
    const { error, execute } = useFetchWithMsal();

    const departmentContext = UsedDepartmentContext();

    const [isLoading, setIsLoading] = useState(true);
    const [isExecuteFetch, setIsExecuteFetch] = useState(false);

    const [data, setData] = useState([]); 
    const[departmentData, setDepartmentData] = useState(initialDepartmentData);

    const viewPortRef = useRef(null);
    const viewPortListRef = useRef(null);

    const [searchOfficer, setSearchOfficer] = useState("");
    const [dateRangeState, setDateRangeState] = useState(InitialDateRangeState());

    const [listIncidentTypeData, setListIncidentTypeData] = useState(initialIncidentTypeData);
    const [selectedMultiIncidentType, setSelectedMultiIncidentType] = useState([]);

    let departmentInitialVal = 0;
    //Still need some adjustments here in relation to the User's Department/Agency
    // if (userRoles.length > 0 && userRoles.includes("Admin")) {
    //     departmentInitialVal = currentUser.agencyId;
    // }

    // eslint-disable-next-line no-unused-vars
    const [selectedDepartment, setSelectedDepartment] = useState(departmentInitialVal);
    const [selectedMultiDepartment, setSelectedMultiDepartment] = useState([]);

    const [timer, setTimer] = useState(null);

    const handleSearchOfficeFieldOnChange = (e) => {
        setSearchOfficer(e.nativeEvent.srcElement.value);

        if(e.nativeEvent.srcElement.value === "") {
                if (timer) {
                clearTimeout(timer);
                setTimer(null);
            }

            //set a pause after typing
            setTimer(
                setTimeout(1, 1000)
            );
            setIsExecuteFetch(true);
        }
    };

    const handleDateRangeFieldOnChange = (e) => {
        setDateRangeState({
            startDate: e.startDate,
            endDate: e.endDate,
        });
        setIsExecuteFetch(true);
    };

    // const handleDepartmentOnChange = (e) => {
    //     setSelectedDepartment(e.value)
    // };

    const handleMultiDepartmentOnChange = (values) => {
        if(values.length > 0) {
            const flatValues = values.map(item => item.value);
            setSelectedMultiDepartment(flatValues);
        }
    };

    const handleMultiIncidentTypeOnChange = (values) => {
        if(values.length > 0) {
            const flatValues = values.map(item => item.value);
            setSelectedMultiIncidentType(flatValues);
        }
    };

    const handleCloseDeleteDialog = () => {
        departmentContext.onSetIncidentId(0);
        departmentContext.onShowDeleteDialog(false);
    };

    const handleCloseSuspendAccountDialog = () => {
        departmentContext.onSetUserId("");
        departmentContext.onShowSuspendAccountDialog(false);
    };

    const handleRemovePost = useCallback(() => {
        if(appNBCContext.dbUserId !== null) {
            let _requestBody = departmentContext.incidentId

            execute("POST", protectedEndPointAPI.department.incident.delete, _requestBody)
                .then((response) => {
                    console.log(response.data)
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    departmentContext.onSetIncidentId(0);
                    setIsExecuteFetch(true);
                    departmentContext.onShowDeleteDialog(false);
                });
        }
    },[execute, appNBCContext, departmentContext]);

    const handleSuspendAccount = useCallback(() => {
        if(appNBCContext.dbUserId !== null) {
            let _requestBody = departmentContext.userId

            execute("POST", protectedEndPointAPI.account.suspend, _requestBody)
                .then((response) => {
                    console.log(response.data)
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    departmentContext.onSetUserId(0);
                    setIsExecuteFetch(true);
                    departmentContext.onShowSuspendAccountDialog(false);
                });
        }
    },[execute, appNBCContext, departmentContext]);

    //[Start] - Filter data 
    const getFilteredData = () => {
        let _result = !searchOfficer ? (selectedMultiDepartment.length > 0 ?  
                                    (
                                        selectedMultiDepartment.filter(x => x === 0).length > 0 ? 
                                        data 
                                        :
                                        data.filter((item) => selectedMultiDepartment.includes(item.departmentId))
                                    ) 
                                    : 
                                    (data))
                        :  
                        selectedMultiDepartment.length > 0 ?  
                                (
                                    selectedMultiDepartment.filter(x => x === 0).length > 0 ? 
                                    data.filter((item) => (item.fullName.toLowerCase()).includes(searchOfficer.toLowerCase())) 
                                    :
                                    data.filter((item) => (item.fullName.toLowerCase()).includes(searchOfficer.toLowerCase()) && selectedMultiDepartment.includes(item.departmentId)) 
                                ) 
                                : 
                                (data.filter((item) => (item.fullName.toLowerCase()).includes(searchOfficer.toLowerCase())))
                        ;

        _result = selectedMultiIncidentType.length > 0 ? 
                        selectedMultiIncidentType.filter(x => x === 0).length > 0 ? 
                        _result 
                        :
                        _result.map(item => {
                            let _item = {
                                ...item,
                                incidents: item.incidents.filter((x) => selectedMultiIncidentType.includes(x.incidentTypeId))
                            }
                            return _item;
                        })
                    : 
                    _result ;

        _result = _result.filter((item) => item.incidents.length > 0);

        return  _result;
    }

    const filteredData = getFilteredData();
    //[End] - Filter data 

    //Initial state of data
    useEffect(() => {
        //[Start] - Populate department combo box
        execute("GET", protectedEndPointAPI.department.agency.list)
            .then((response) => {
                var _data = response.data;
                if(_data !== null) { 
                    if(_data.length > 0) {
                        let tempData = response.data;
                        tempData.push(initialDepartmentData[0]);
    
                        let sortData = _.sortBy(tempData,"value")
    
                        setDepartmentData(sortData);
                    }
                }
             })
             .catch((error) => {
                console.log(error);
            });
        //[End] - Populate department combo box

        //[Start] - Populate incident type combo box
        execute("GET", protectedEndPointAPI.home.incident.type.v2.list)
            .then((response) => {
                var _data = response.data.data;
                
                if(_data !== null) { 
                    if(_data.length > 0) {
                        let sortData = _.sortBy(_data,"label")
                        sortData.unshift(initialIncidentTypeData[0]); //push the "All" at the beginning of the array
                        setListIncidentTypeData(sortData);
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
        //[End] - Populate incident type combo box

        //[Start] - Populate list of data for incidents
        const {startDate, endDate } = dateRangeState;

        let _requestBody = {
            SearchCriteria: "",
            DateStart: startDate.format("YYYY-MM-DD"), 
            DateEnd: endDate.format("YYYY-MM-DD"),
            DepartmentId: 0,
            UserId: appNBCContext.dbUserId
        }

        execute("PUT", protectedEndPointAPI.department.search.list,_requestBody)
            .then((response) => {
                var _data = response.data;
                if(_data !== null) { 
                    if(_data.length > 0) {
                        setData(response.data);
                        setIsLoading(false);
                    } else {
                        setData([]);
                        setIsLoading(false);
                    }
                }
             })
             .catch((error) => {
                console.log(error);
                setIsLoading(false);
            });
        //[End] - Populate list of data for incidents


    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    //Fetch data from API
    useEffect(() => {
        if(isExecuteFetch) {
            setIsLoading(true);
            const {startDate, endDate } = dateRangeState;

            let _requestBody = {
                SearchCriteria: searchOfficer,
                DateStart: startDate.format("YYYY-MM-DD"), 
                DateEnd: endDate.format("YYYY-MM-DD"),
                DepartmentId: selectedDepartment,
                UserId: appNBCContext.dbUserId
            }
            
            execute("PUT", protectedEndPointAPI.department.search.list,_requestBody)
                .then((response) => {
                    var _data = response.data;
                    if(_data !== null) { 
                        if(_data.length > 0) {
                            setData(response.data);
                            setIsLoading(false);
                        } else {
                            setData([]);
                            setIsLoading(false);
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setIsLoading(false);
                });

                setIsExecuteFetch(false);
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
        },[isExecuteFetch, searchOfficer, dateRangeState, selectedDepartment]);

        return (
            <>
                <Container fluid>
                    <HeaderLabel label={"Department"} ></HeaderLabel>
                    <Row>
                        <Col>
                            <SearchOfficerField onChange={handleSearchOfficeFieldOnChange} />
                        </Col>
                        <Col>
                            <DatePickerField onChangeDateValues={handleDateRangeFieldOnChange} />
                        </Col>
                         
                        {
                            (appNBCContext.dbUserId !== null) ? 
                                ( 
                                    (appNBCContext.isSuperAdmin) ? 
                                        (<Col><MultiSelectWithSelectAll options={departmentData} onSelectedValueChanged={handleMultiDepartmentOnChange} placeholder={"Select Department(s)..."} /></Col>) 
                                        : 
                                        (<></>)
                                ) 
                                : 
                                (<></>)
                        }
                        
                        <Col>
                            <MultiSelectWithSelectAll options={listIncidentTypeData} onSelectedValueChanged={handleMultiIncidentTypeOnChange} placeholder={"Select Case Type(s)..."} />
                        </Col>
                    </Row>
    
                    <Row style={{padding: "25px 0px 0px 10px"}}>
                        {
                            isLoading ? 
                            (
                                <div>
                                    <div style={{marginBottom: "25px"}}><PostShimmeringEffect/></div>
                                    <div style={{marginBottom: "25px"}}><PostShimmeringEffect/></div>
                                </div>
                                
                            ) 
                            : 
                            (
                                <div className="scroll-container" ref={viewPortRef}>
                                    { 
                                        filteredData.length > 0 ? 
                                            (
                                                <ViewportList ref={viewPortListRef} viewportRef={viewPortRef} items={filteredData} itemMinSize={40} margin={8}>
                                                    {(item) => (
                                                        <div className="item" key={item.userId} id={"item-content-id-" + item.userId}>
                                                            <IncidentPostContainer data={item} />
                                                        </div>
                                                    )}
                                                </ViewportList>
                                            ) 
                                            : 
                                            (<NoResultFoundContainer message={"No results found. Try another keywords..."} />)
                                    }
                                </div>
                            )
                        }
                    </Row>
                </Container>
    
                {/**[Start] - Delete Incident Post Dialog**/}
                <Dialog open={departmentContext.isDeleteDialogOpen}
                        onClose={handleCloseDeleteDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                    <div style={{width: "290px", height: "160px", padding: "25px", ...CenterAllStyle}}>
                        <div style={{fontFamily: PrimaryFontFamily, fontSize: "18px", fontWeight: 600, color: "#002542", textAlign: "center", marginBottom: "10px"}}>
                            <div>Are you sure you want to remove this post?</div>
                        </div>
                        <div style={{display: "flex", padding: "5px", ...CenterAllStyle}}>
                                <div style={{marginRight: "15px"}}>
                                    <Button onClick={handleCloseDeleteDialog} variant="outlined" sx={{borderColor: "#D9DFE1"}}>
                                        <div style={{...ButtonFontStyle, color: "#576F80"}}>Cancel</div>
                                    </Button>
                                </div>
                                <div>
                                    <Button onClick={handleRemovePost} variant="contained">
                                        <div style={{...ButtonFontStyle, color: "#FFFFFF"}}>Remove</div>
                                    </Button>
                                </div>
                        </div>
                    </div>
                </Dialog>
                {/**[End] - Delete Incident Post Dialog**/}
    
                {/**[Start] - Suspend Account Dialog**/}
                <Dialog open={departmentContext.isSuspendAccountDialogOpen}
                        onClose={handleCloseSuspendAccountDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                    <div style={{width: "290px", height: "160px", padding: "25px", ...CenterAllStyle}}>
                        <div style={{fontFamily: PrimaryFontFamily, fontSize: "18px", fontWeight: 600, color: "#002542", textAlign: "center", marginBottom: "10px"}}>
                            <div>Are you sure you want to suspend this account?</div>
                        </div>
                        <div style={{display: "flex", padding: "5px", ...CenterAllStyle}}>
                                <div style={{marginRight: "15px"}}>
                                    <Button onClick={handleCloseSuspendAccountDialog} variant="outlined" sx={{borderColor: "#D9DFE1"}}>
                                        <div style={{...ButtonFontStyle, color: "#576F80"}}>Cancel</div>
                                    </Button>
                                </div>
                                <div>
                                    <Button onClick={handleSuspendAccount} variant="contained">
                                        <div style={{...ButtonFontStyle, color: "#FFFFFF"}}>Suspend</div>
                                    </Button>
                                </div>
                        </div>
                    </div>
                </Dialog>
                {/**[End] - Suspend Account Dialog**/}
                
    
            </>
        );
}

export default AzureDepartment;