import React,{ useEffect, useState} from "react";
import { Link } from 'react-router-dom';
import { UsedAppNBCContext } from "../../AppNBC.Provider";
import { protectedVariable } from "../../Helpers/ProtectedCustomClass";


const AzurePostAgenciesList = (props) => {

    const appNBCContext = UsedAppNBCContext();

    // eslint-disable-next-line no-unused-vars
    const [isOfficer, setIsOfficer] = useState(true)

    useEffect(() => {
        if(appNBCContext.dbUserId !== null) {
            setIsOfficer(!appNBCContext.isAdmin && !appNBCContext.isSuperAdmin);
        }
    },[appNBCContext])

    return (
    <>
        <h2>Agencies</h2><hr></hr>
        {props.listagencies.map((agency) => (
            <div className="row cardagency">
                <div className="col-md-6">
                    {(agency !== undefined && agency !== null ? agency.name : "")}
                </div>
                <div className="col-md-6">
                    {
                        (isOfficer ? <Link to={"/agency/view?id=" + agency.id +"&pagesource=" + protectedVariable.PageSource.Home} className="">Go to Agency Profile </Link>: <Link to={"/agencies/update?agencyId=" + agency.id} className="">Go to Agency Profile </Link>)
                    }
                    
                </div>
            </div>
        ))}
    </>);

}


export default AzurePostAgenciesList;