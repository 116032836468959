
import AzureChatMessageBar from './AzureChatMessageBar';

const AzureChatWindow = (props) => {
    const chat = props.chat !== undefined? props.chat
        .map(m => <AzureChatMessageBar
            key={Date.now() * Math.random()}
            chat={m} />) : "";
    return (
        <>
            {chat}
        </>
           
    )
};
export default AzureChatWindow;