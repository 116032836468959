import React, { useCallback, useState, useEffect} from "react";
import Autocomplete from "react-google-autocomplete";
import InputMask from "react-input-mask";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo, faLocationArrow, faEnvelope, faMobile,faGlobe} from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core';
import { useHistory } from 'react-router-dom';

import agencyNoImg from "../../../assets/agency.png";

import { UsedAppNBCContext } from "../../AppNBC.Provider";
import useFetchWithMsal from "../../hooks/useFetchWithMsal";
import { protectedEndPointAPI, protectedVariable } from "../../Helpers/ProtectedCustomClass";

library.add(faInfo, faLocationArrow, faEnvelope, faMobile, faGlobe);

const AzureAgencyView = () => {
   
    let history = useHistory();

    const queryParams = new URLSearchParams(window.location.search);
    const agencyId = queryParams.get('id');
    const pageSource = queryParams.get('pagesource');

    const [isInitialLoadDone, setIsInitialLoadDone] = useState(false);

    const appNBCContext = UsedAppNBCContext();
    // eslint-disable-next-line no-unused-vars
    const { error, execute } = useFetchWithMsal();

    // eslint-disable-next-line no-unused-vars
    const [originalAgencyName, setOriginalAgencyName] = useState("");

    const [agency, setAgency] = useState({
        name:"",
        locationinput: "",
        selectedImage: null,
        agencyId: 0,
        addressId: 0,
        latitude: 0.0,
        longitude: 0.0
    });


    const getAgency = useCallback(async () => {
        if(appNBCContext.dbUserId !== null) {
            let _requestBody = agencyId

            execute("PUT", protectedEndPointAPI.agencies.information.base, _requestBody).then((response) => {
                var _data = response.data;
                if(_data !== null) { 
                    setAgency({
                        ...agency,
                        name: _data.name,
                        locationinput: _data.address.address1,
                        addressId: _data.address.googlePlaceId,
                        latitude: _data.address.latitude,
                        longitude: _data.address.longitude,
                        agencyId: _data.id,
                        phone: _data.phone ? _data.phone:"",
                        email: _data.email ? _data.email : "",
                        url: _data.url ? _data.url : "",
                        manualrequest: _data.manageRequest,
                        img:_data.azureAgencyProfileImage !== null ? _data.azureAgencyProfileImage.url : "",
                        bulkrequest: _data.bulkRequest
                    
                    });
                    setOriginalAgencyName(_data.name.toLowerCase());
                }
            }).finally(() => { if(!isInitialLoadDone) setIsInitialLoadDone(true); });

        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[execute, appNBCContext,isInitialLoadDone])

    const RedirectToPreviousPage = useCallback(() => {
        try {
            switch(parseInt(pageSource)) {
                case protectedVariable.PageSource.Message:
                    history.push("/message");
                    break;
                default:
                    history.push("/home");
                    break;
              }
        } catch (ex) {
            console.log(ex);
        }

    },[history,pageSource])


    useEffect(() => {
        if(!isInitialLoadDone) {
            if(agencyId !== undefined) {
                if(agencyId !== null) {
                    if(agencyId > 0) getAgency()
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isInitialLoadDone,agencyId]);

    return (
        <>
            <div className="row">
                <div className="col-3"></div>
                <div className="col-6">
                    <div className="card-container">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-titleagency">View Agency</h5>
                                <div className="row centerimg">
                                    {agency.img && !agency.selectedImage &&
                                        <img alt="" src={agency.img} className="iconagency" />
                                    }
                                    {!agency.img && !agency.selectedImage &&
                                        <img alt="" src={agencyNoImg} className="iconagency" />
                                    }
                                </div>
                                <div className="row">
                                    <div className="col-md-12 col-sm-12">
                                        <label className="lb">Name</label>
                                    </div>
                                    <div className="col-md-12 col-sm-12">
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="basic-addon1">  <FontAwesomeIcon icon={faInfo} /></span>
                                            </div>
                                            <input type="text" disabled={true} className="form-control" value={agency.name}  aria-label="Name" aria-describedby="basic-addon1" />
                                        </div>
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-md-12 col-sm-12">
                                        <label className="lb">Location</label>
                                    </div>
                                    <div className="col-md-12 col-sm-12">
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="basic-addon1">  <FontAwesomeIcon icon={faLocationArrow} /></span>
                                            </div>
                                            <Autocomplete
                                                disabled={true}
                                                apiKey={protectedVariable.GoogleMapAPIKey}
                                                className="form-control"
                                                placeholder="Enter Agency's location"
                                                defaultValue={agency.locationinput}
                                                required
                                                options={{componentRestrictions: { country: "us" },}}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 col-sm-12">
                                        <label className="lb">Phone</label>
                                    </div>
                                    <div className="col-md-12 col-sm-12">
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="basic-addon1">  <FontAwesomeIcon icon={faMobile} /></span>
                                            </div>
                                            <InputMask disabled={true} placeholder="Agency's Phone" value={agency.phone} className="form-control" mask="+1\ 999 999 9999" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 col-sm-12">
                                        <label className="lb">Email</label>
                                    </div>
                                    <div className="col-md-12 col-sm-12">
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="basic-addon1">  <FontAwesomeIcon icon={faEnvelope} /></span>
                                            </div>
                                            <input id="email" name="email" disabled={true} value={agency.email} required type="email" className="form-control" placeholder="Agency's E-mail" aria-label="Username" aria-describedby="basic-addon1" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 col-sm-12">
                                        <label className="lb">Url</label>
                                    </div>
                                    <div className="col-md-12 col-sm-12">
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="basic-addon1">  <FontAwesomeIcon icon={faGlobe} /></span>
                                            </div>
                                            <input type="url"  value={agency.url} disabled={true} className="form-control" placeholder="Agency's url" aria-label="URL" aria-describedby="basic-addon1" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 col-sm-12">
                                        <label className="lb"> Admin settings</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-9"> 
                                        <label className="lb"> Manual join requests</label> 
                                    </div>
                                    <div className="col-3 toggle-switch"> 
                                        <input disabled={true} type="checkbox" checked={agency.manualrequest} className="toggle-switch-1" /> 
                                        <label disabled={true} ></label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-9"> 
                                        <label className="lb"> Bulk processing of requests</label> 
                                    </div>
                                    <div className="col-3 toggle-switch"> 
                                        <input disabled type="checkbox" id="topping" name="topping" value="Paneer" checked={agency.bulkrequest} className="toggle-switch-1" /> 
                                        <label ></label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 col-sm-12">
                                        <button type="button" className="btn btn-primary btn-fullx" onClick={RedirectToPreviousPage} >Back</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AzureAgencyView