import React from "react";
import { Row } from "reactstrap";
import loadingchatpanel from "../../../../assets/incident_load.png";
import "./AzureState.css";
const AzureLoaderPost = () => {
    return (
        <>
            <Row>
                <img src={loadingchatpanel} className="chatuserloader" alt="loader" />
            </Row>
            <Row>
                <img src={loadingchatpanel} className="chatuserloader" alt="loader"  />
            </Row>
          
        </>
    );
};
export default AzureLoaderPost;
