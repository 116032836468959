import React from "react";

const ImageBinaryDataLoader = (props) => {
  return (
    <>
      {props.isProfilePicChange ? (
        <img
          className="imageViewer"
          alt="Not Found"
          width={"250px"}
          src={
            props.selectedImage !== null &&
            props.selectedImage !== undefined &&
            typeof props.selectedImage !== "object" &&
            props.selectedImage.startsWith("https")
              ? props.selectedImage
              : URL.createObjectURL(props.selectedImage)
          }
        />
      ) : (
        <img
          className="imageViewer"
          alt="Not Found"
          width={"250px"}
          src={`data:image/jpeg;base64,${props.binaryData}`}
        />
      )}

      <br />
    </>
  );
};

export default ImageBinaryDataLoader;
