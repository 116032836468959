import React, {useCallback, useEffect, useRef, useState} from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { 
    ButtonFontStyle,
    CenterAllStyle,
    HeaderLabel, 
    InitialDateRangeState, 
    NoResultFoundContainer,
    PostShimmeringEffect,
    PrimaryFontFamily,
    ReportedPostContainer,
    StatusReport, 
} 
from "../../../components/ReportedPost/Reported.Style";
import { UsedReportedPostContext } from "../../../components/ReportedPost/ReportedPost.Provider";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ViewportList from 'react-viewport-list';
import * as _ from "lodash";

import { UsedAppNBCContext } from "../../AppNBC.Provider";
import useFetchWithMsal from "../../hooks/useFetchWithMsal";
import { protectedEndPointAPI } from "../../Helpers/ProtectedCustomClass";


const initialDepartmentData = [
    { value: 0, label: 'All' }
]

const AzureReportedPost = () => {

    const appNBCContext = UsedAppNBCContext();
    // eslint-disable-next-line no-unused-vars
    const { error, execute } = useFetchWithMsal();

    const reportedPostContext = UsedReportedPostContext();

    const [isLoading, setIsLoading] = useState(true);
    const [isExecuteFetch, setIsExecuteFetch] = useState(false);

    const [data, setData] = useState([]); 

     // eslint-disable-next-line no-unused-vars
     const[departmentData, setDepartmentData] = useState(initialDepartmentData);

     const viewPortRef = useRef(null);
     const viewPortListRef = useRef(null);
 
     const [searchKeyWords, setSearchKeyWords] = useState("");
     const [dateRangeState, setDateRangeState] = useState(InitialDateRangeState());
 
     let departmentInitialVal = 0;
    //Still need some adjustments here in relation to the User's Department/Agency
    //  if (userRoles.length > 0 && userRoles.includes("Admin")) {
    //      departmentInitialVal = currentUser.agencyId;
    //  }

    const [selectedDepartment, setSelectedDepartment] = useState(departmentInitialVal);

    const [timer, setTimer] = useState(null);

    // eslint-disable-next-line no-unused-vars
    const handleSearchKeywordsFieldOnChange = (e) => {
        setSearchKeyWords(e.nativeEvent.srcElement.value);

        if(e.nativeEvent.srcElement.value === "") {
                if (timer) {
                clearTimeout(timer);
                setTimer(null);
            }

            //set a pause after typing
            setTimer(
                setTimeout(1, 1000)
            );
            setIsExecuteFetch(true);
        }
    };

    // eslint-disable-next-line no-unused-vars
    const handleDateRangeFieldOnChange = (e) => {
        setDateRangeState({
            startDate: e.startDate,
            endDate: e.endDate,
        });
        setIsExecuteFetch(true);
    };

     // eslint-disable-next-line no-unused-vars
    const handleDepartmentOnChange = (e) => {
        setSelectedDepartment(e.value)
    };

    const handleCloseReportedPostDialog = () => {
        reportedPostContext.onSetReportedPostId(0);
        reportedPostContext.onShowStatusReportDialog(false);
        reportedPostContext.onSetStatusReportId(0);
    }

    const handleReportedPost = useCallback(() => {
        if(appNBCContext.dbUserId !== null) {
            let _requestBody = {
                UserId: appNBCContext.dbUserId,
                Id: reportedPostContext.reportedPostId,
                StatusReportId: reportedPostContext.statusReportId,
            }

            execute("POST", protectedEndPointAPI.reportedposts.update, _requestBody)
                .then((response) => {
                    console.log(response.data)
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    reportedPostContext.onSetReportedPostId(0);
                    reportedPostContext.onSetStatusReportId(0);
                    setIsExecuteFetch(true);
                    reportedPostContext.onShowStatusReportDialog(false);
                });
        }
    },[execute, appNBCContext, reportedPostContext]);


    // If filter functionality will be enabled, please include the Department Id here.
    const filteredData = searchKeyWords ? 
                                        (data.filter((item) => (item.fullName.toLowerCase()).includes(searchKeyWords.toLowerCase()) ||
                                                               (item.reportPostMessage.toLowerCase()).includes(searchKeyWords.toLowerCase()) ||
                                                               (item.anonymous ? "anonymous".includes(searchKeyWords.toLowerCase()) : "")  ||
                                                               (item.department.toLowerCase()).includes(searchKeyWords.toLowerCase()) ||
                                                               (item.statusReportId > 0 ? item.statusReport.toLowerCase().includes(searchKeyWords.toLowerCase()) : false) ||
                                                               (item.incident.agencyName.toLowerCase()).includes(searchKeyWords.toLowerCase()) ||
                                                               (item.incident.incidentNum.toLowerCase()).includes(searchKeyWords.toLowerCase()) ||
                                                               (item.incident.address1.toLowerCase()).includes(searchKeyWords.toLowerCase()) || 
                                                               (item.incident.incidentType.toLowerCase()).includes(searchKeyWords.toLowerCase()) ||
                                                               (item.incident.description.toLowerCase()).includes(searchKeyWords.toLowerCase()) ||
                                                               (item.incident.fullName.toLowerCase()).includes(searchKeyWords.toLowerCase()) ||
                                                               (item.incident.division.toLowerCase()).includes(searchKeyWords.toLowerCase()) 
                                        ))
                                        : 
                                        (data);


    //Initial state of data
    useEffect(() => {
        //[Start] - Populate department combo box
        execute("GET", protectedEndPointAPI.department.agency.list)
            .then((response) => {
                if(response.data.length > 0) {
                    let tempData = response.data;
                    tempData.push(initialDepartmentData[0]);

                    let sortData = _.sortBy(tempData,"value")

                    setDepartmentData(sortData);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        //[End] - Populate department combo box

        //[Start] - Populate list of data for incidents
        const {startDate, endDate } = dateRangeState;

        let _requestBody = {
            SearchCriteria: "",
            DateStart: startDate.format("YYYY-MM-DD"), 
            DateEnd: endDate.format("YYYY-MM-DD"),
            DepartmentId: departmentInitialVal
        }

        execute("PUT", protectedEndPointAPI.reportedposts.search.list,_requestBody)
            .then((response) => {
                if(response.data.length > 0) {

                var _data = response.data;

                if(departmentInitialVal > 0) {
                    _data = _data.filter((item) => (item.incident.departmentId === departmentInitialVal))
                }
                    setData(_data);
                    setIsLoading(false);
                } else {
                    setData([]);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            });
        //[End] - Populate list of data for incidents

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    //Fetch data from API
    useEffect(() => {
        if(isExecuteFetch) {
            setIsLoading(true);
            const {startDate, endDate } = dateRangeState;

            let _requestBody = {
                SearchCriteria: searchKeyWords,
                DateStart: startDate.format("YYYY-MM-DD"), 
                DateEnd: endDate.format("YYYY-MM-DD"),
                DepartmentId: selectedDepartment
            }
            
            execute("PUT", protectedEndPointAPI.reportedposts.search.list,_requestBody)
                .then((response) => {
                    if(response.data.length > 0) {
                        setData(response.data);
                        setIsLoading(false);
                    } else {
                        setData([]);
                        setIsLoading(false);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setIsLoading(false);
                });

            setIsExecuteFetch(false);
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isExecuteFetch, searchKeyWords, dateRangeState, selectedDepartment]);

    return (<>
        <Container fluid>
            <HeaderLabel label={"Reported posts"} ></HeaderLabel>
            {/* //Uncomment this area when filter functionality need to be included
            <Row>
                <Col>
                    <SearchKeywordsField onChange={handleSearchKeywordsFieldOnChange} />
                </Col>
                <Col>
                    <DatePickerField onChangeDateValues={handleDateRangeFieldOnChange} />
                </Col>
                <Col>
                    <DepartmentDropDownField data={departmentData} onChange={handleDepartmentOnChange} />
                </Col>
            </Row> 
            */}

            <Row style={{padding: "25px 0px 0px 10px"}}>
                {
                        isLoading ? 
                        (
                            <div>
                                <div style={{marginBottom: "25px"}}><PostShimmeringEffect/></div>
                                <div style={{marginBottom: "25px"}}><PostShimmeringEffect/></div>
                                <div style={{marginBottom: "25px"}}><PostShimmeringEffect/></div>
                            </div>
                        ) 
                        : 
                        (
                            <div className="scroll-container" ref={viewPortRef}>
                                { 
                                    filteredData.length > 0 ? 
                                        (
                                            <ViewportList ref={viewPortListRef} viewportRef={viewPortRef} items={filteredData} itemMinSize={40} margin={8}>
                                                {(item) => (
                                                    <div className="item" key={"key-id-" + item.id} id={"item-content-id-" + item.id}>
                                                        <ReportedPostContainer data={item}/>
                                                    </div>
                                                )}
                                            </ViewportList>
                                        ) 
                                        : 
                                        (<NoResultFoundContainer message={"No results found."} />)
                                }
                            </div>
                        )

                }
            </Row>
        </Container>

        {/**[Start] - Reported Post Dialog**/}
        <Dialog open={reportedPostContext.isStatusReportDialogOpen}
                onClose={handleCloseReportedPostDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
            <div style={{width: "290px", height: "160px", padding: "25px", ...CenterAllStyle}}>
                <div style={{fontFamily: PrimaryFontFamily, fontSize: "18px", fontWeight: 600, color: "#002542", textAlign: "center", marginBottom: "10px"}}>
                    <div>Are you sure you want to {reportedPostContext.statusReportId === StatusReport.Ignored ? "ignore" : "remove"} this post?</div>
                </div>
                <div style={{display: "flex", padding: "5px", ...CenterAllStyle}}>
                    <div style={{marginRight: "15px"}}>
                        <Button onClick={handleCloseReportedPostDialog} variant="outlined" sx={{borderColor: "#D9DFE1"}}>
                            <div style={{...ButtonFontStyle, color: "#576F80"}}>Cancel</div>
                        </Button>
                    </div>
                    <div>
                        <Button onClick={handleReportedPost} variant="contained">
                            <div style={{...ButtonFontStyle, color: "#FFFFFF"}}>Yes</div>
                        </Button>
                    </div>
                </div>
            </div>
        </Dialog>

        {/**[End] - Reported Post Dialog**/}


    </>);
}

export default AzureReportedPost;