import React from "react";
import { Row, } from "reactstrap";
import loadingchatusers from "../../../assets/user_chat_load.png";
import "./Loader.css";
const LoaderUserChat = () => {
    return (
        <>
            <Row>
                <img src={loadingchatusers} className="chatusers" alt="" />
            </Row>
            <Row>
                <img src={loadingchatusers} className="chatusers"  alt="" />
            </Row>
            <Row>
                <img src={loadingchatusers} className="chatusers"  alt="" />
            </Row>
            <Row>
                <img src={loadingchatusers} className="chatusers"  alt="" />
            </Row>
        </>
    );
};
export default LoaderUserChat;
