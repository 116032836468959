import React, {useEffect, useState, useCallback} from "react";
import Select from 'react-select';
import './AzureProfile.css';
import { Row, Col, Button } from "reactstrap";
import InputMask from "react-input-mask";
import { useForm } from 'react-hook-form';
import { Container,Tab, Tabs, Form} from "react-bootstrap";
import { Link,  } from 'react-router-dom';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import ProfilePicture from "../../../components/Profile/ProfilePictureUpload";
import locationIcon from "../../../assets/locationBlue.svg";
import editProfile from "../../../assets/EditProfilePic.png";
import AdminPreview from "../../../components/Admin/AdminPreview";
import Autocomplete from "react-google-autocomplete";

import { useMsal } from "@azure/msal-react";
import { UsedAppNBCContext } from "../../AppNBC.Provider";
import useFetchWithMsal from "../../hooks/useFetchWithMsal";
import { protectedEndPointAPI, protectedVariable } from "../../Helpers/ProtectedCustomClass";

const exportDataListSelection = [
    { value: 1, label: 'All my data' },
    { value: 2, label: 'Just my posts' },
    { value: 3, label: 'Just my messages' }
]

const ExportDataDropDownField = ({data, onChange}) => {
    return (
        <Select className="basic-single"
                classNamePrefix="Export data"
                components={{IndicatorSeparator: () => null}}
                isDisabled={false}
                isLoading={false}
                isRtl={false}
                isSearchable={true}
                defaultValue={data[0]}
                name="color"
                onChange={onChange}
                options={data}
                styles={{control: styles => 
                    ({ ...styles
                        , minHeight: "45px"
                        , borderColor: "#cccccc"
                        , boxShadow: "0 0 0 0px #cccccc"
                        , "&:hover": {
                            borderColor: "#cccccc"
                        }}
                    )
                }}
    />);
}

const AzureProfile = () => {

    const appNBCContext = UsedAppNBCContext();
   // eslint-disable-next-line no-unused-vars
   const { error, execute, executeFormData, executeBlob } = useFetchWithMsal();
   const { instance } = useMsal();

   const [listDivision, setListDivision] = useState([]);
   const [validationErros, setValidationErros] = useState({ matchError: false, rulesError: false });
   const [isSuperAdmin, setIsSuperAdmin] = useState(false);

    const { register, handleSubmit, setValue, formState: { errors }, setError } = useForm({
        mode: 'onChange'
    });

    const { register: register2, handleSubmit: handleSubmit2, formState: { errors: errors2 }, } = useForm({
        mode: "onChange",
    });

    const { register: register3, handleSubmit: handleSubmit3, reset:reset3, formState: { errors: errors3 },
    } = useForm({
        mode: "onChange",
    });

    const [isLoading, setIsLoading] = useState(false);

    const [profile, setProfile] = useState({
        name: "",
        departament:"",
        division: "",
        phone: "",
        email: "",
        status: "",
        notificationAgency: false,
        notificationAgencySign: false,
        notificationChat: false,
        notificationUpdateMyPost: false,
        notificationReportedPost: false,
        notificationRequests:false,
        allowSearchMyProfile: false,
        showEmail: true,
        showPhone: true,
        selectedImage: null,
        profilePicBase64String: "",
        profilePicFileType: "",
        twofactorAuthentication: false,
        imageRemoved: false,
        agencyInfo: {},
        addressId:"",
        nameAddress: "",
        latitude: 0,
        longitude: 0,
        state: ""
    });

    const [location, setLocation] = useState({});
    const [listDivisions, setListDivisions] = useState([]);
    const [serverErrors, setServerErrors] = useState();

    const onChangeEmail = e => {
        setProfile({
            ...profile,
            email: e.target.value

        });
    }

    const onChangePostPublished = e => {
        setProfile({
            ...profile,
            notificationAgency: e.target.checked

        });
    }

    const onChangePostPublishedSign = e => {
        setProfile({
            ...profile,
            notificationAgencySign: e.target.checked

        });
    }

    const onChangeTwofactorAuthentication= e => {
        setProfile({
            ...profile,
            twofactorAuthentication: e.target.checked

        });
    }

    const onChangeAllowSearch = e => {
        setProfile({
            ...profile,
            allowSearchMyProfile: e.target.checked

        });
    }

    const onChangeChat = e => {
        setProfile({
            ...profile,
            notificationChat: e.target.checked

        });
    }

    const onChangeUpdatePost = e => {
        setProfile({
            ...profile,
            notificationUpdateMyPost: e.target.checked

        });
    }

    const onChangeReportedPost = e => {
        setProfile({
            ...profile,
            notificationReportedPost: e.target.checked

        });
    }

    const onChangeRequests = e => {
        setProfile({
            ...profile,
            notificationRequests: e.target.checked

        });
    }

    const onChangeShowEmail = e => {
        setProfile({
            ...profile,
            showEmail: e.target.checked

        });
    }

    const onChangeShowPhone = e => {
        setProfile({
            ...profile,
            showPhone: e.target.checked

        });
    }

    const onChangeSelectedImage = e => {
        setProfile({
            ...profile,
            selectedImage: e,
            imageRemoved: true
        });
    }

    const onChangeInputMap = e => {
        setProfile({
            ...profile,
            locationinput: e.target.value
        });

    }

    const onChangeLocation = e => {
        setLocation({
            ...location, e
        });
    }

    const changePassword = (data) =>
    {
        setIsLoading(true);

        const regex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
        if (!data.password || regex.test(data.password) === false) {
            setValidationErros({
                ...validationErros,
                rulesError :true
            });
            setIsLoading(false);
            return;

        } else if (data.password !== data.newPassword) {
            setValidationErros({
                ...validationErros,
                matchError: true
            });
            setIsLoading(false);
            return;
        }

        setValidationErros({
            rulesError: false,
            matchError: false
        });

        const formData = new FormData();
        formData.append("Password", data.password);
        formData.append("OldPassword", data.oldPassword);

        // api.post("/authenticate/resetPasswordProfile", formData, AuthService.getToken())
        //     .then(data => {
        //         if (data.data && data.data.errors && data.data.errors !== "") {
        //             setServerErrors(data.data.errors);
        //         } else {
        //             reset3({ password: "", newPassword: "", oldPassword:"" });
        //             NotificationManager.success('Success', 'PASSWORD UPDATED CORRECTLY', 5000);
                    
        //             setServerErrors(undefined);
        //         }
        //         setLoading(false);
        //     });
    }

    // const uploadPicAgency = (args) => {
    //     // var data = new FormData();
    //     // setLoading(true);
    //     // data.append("FormFile", args.target.files[0]);
    //     // data.append("AgencyId", account.agencyInfo.id);

    //     // api.post("/account/saveAgencyProfilePic", data, AuthService.getToken())
    //     //     .then(response => {
    //     //         if (response.data.id && response.data.id !== 0) {
    //     //             NotificationManager.success('Success', 'Profile picture updated successfully', 5000);
    //     //             getAccount();
    //     //         }
    //     //         setLoading(false);
    //     //     });
    // }

    const uploadPicAgency = useCallback((args) => {
        if(appNBCContext.dbUserId !== null) {
            setIsLoading(true);

            const _requestFormData = new FormData();
            _requestFormData.append("IsAgencyProfileImageUpdated", true);
            _requestFormData.append("AgencyProfileImage", args.target.files[0]);
            _requestFormData.append("AgencyId", profile.agencyInfo.id);
   

            executeFormData("POST", protectedEndPointAPI.agencies.profile.image.save, _requestFormData)
            .then((response) => {
                var _data = response.data;
                if(_data !== null)  {
                    console.log("_data: ", _data);
                    if(response.success) NotificationManager.success('Success', 'Profile picture updated successfully', 5000);
                }
            });

            setIsLoading(false);
        }
    },[executeFormData, appNBCContext, profile])

    const saveDataInformation = useCallback((data,isSaveInformation) => {
        if(appNBCContext.dbUserId !== null) {
            setIsLoading(true);

            var action = 0;
            if (data.name) {
                action = 1;
            } else
            {
                action = 2;
            }

            if(isSaveInformation !== undefined) {
                if(isSaveInformation === true) {
                    action = 1;
                }
            }

            let isvalid = true;
            if (action === 1 && data.name.trimEnd() === "") {
                setError('name', { type: 'required' });
                isvalid = false;
            }

            if (action === 1 && data.phone.trimEnd() === "") {
                setError('phone', { type: 'required' });
                isvalid = false;
            }

            let _tempPhone = data.phone.replace(/\D/g,'');

            if (action === 1 && _tempPhone.length < 11) {
                setIsLoading(false);
                setError('phone', { type: 'invalid-format' });
                isvalid = false;
            }
            if (action === 1 && data.division === "") {
                setError('division', { type: 'required' });
                isvalid = false;
            }
            if (!isvalid)
                return;

                const _requestFormData = new FormData();
                _requestFormData.append("UserId", appNBCContext.dbUserId);
                _requestFormData.append("IsSuperAdmin", appNBCContext.isSuperAdmin);
                _requestFormData.append("IsAdmin", appNBCContext.isAdmin);
                _requestFormData.append("FullName", data.name);
                _requestFormData.append("DivisionId", data.division);
                _requestFormData.append("PhoneNumber", data.phone);
                _requestFormData.append("AddressId", location.e ? location.e.place_id : profile.addressId === undefined || profile.addressId === null ? "-" : profile.addressId );
                _requestFormData.append("NameAddress", location.e ? location.e.formatted_address : profile.locationinput === undefined || profile.locationinput === null ? "-" : profile.locationinput);
                _requestFormData.append("Latitude", location.e ? location.e.geometry.location.lat() : profile.latitude === null || profile.latitude === undefined ? 0 : profile.latitude);
                _requestFormData.append("Longitude", location.e ? location.e.geometry.location.lng() : profile.longitude === null || profile.longitude === undefined ? 0 : profile.longitude);
                _requestFormData.append("State", location.e ? location.e.address_components[2].long_name : profile.state === null || profile.state === undefined ? "-" : profile.state );
                _requestFormData.append("ShowEmail", profile.showEmail);
                _requestFormData.append("ShowPhone", profile.showPhone);
                _requestFormData.append("Status", profile.status);
                _requestFormData.append("AllowSearchMyProfile", profile.allowSearchMyProfile);
                _requestFormData.append("NotificationAgency",  profile.notificationAgency);
                _requestFormData.append("NotificationAgencySign", profile.notificationAgencySign);
                _requestFormData.append("NotificationUpdateMyPost", profile.notificationUpdateMyPost);
                _requestFormData.append("NotificationReportedPost", profile.notificationReportedPost);
                _requestFormData.append("NotificationRequests", profile.notificationRequests);
                _requestFormData.append("NotificationChat", profile.notificationChat);
                _requestFormData.append("IsUserProfileImageUpdated", profile.imageRemoved);
                _requestFormData.append("UserProfileImage", profile.selectedImage);


             executeFormData("POST", protectedEndPointAPI.profile.information.save, _requestFormData).then((response) => {
                var _data = response.data;
                if(_data !== null)  {
                    console.log("_data: ", _data);
                  
                    if(response.success) NotificationManager.success('Success', 'DATA UPDATED CORRECTLY', 5000);
                }
             }).finally(() => {
                setIsLoading(false);
             });

            
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[executeFormData, appNBCContext, profile,location])

    const SaveInformation = (data) => {
        saveDataInformation(data,true);
    }

    const SavePrivacy = useCallback(() => {
        if(appNBCContext.dbUserId !== null) {
            setIsLoading(true);

            const _requestFormData = new FormData();
            _requestFormData.append("UserId", appNBCContext.dbUserId);
            _requestFormData.append("AllowSearchMyProfile", profile.allowSearchMyProfile);
            _requestFormData.append("NotificationAgency", profile.notificationAgency);
            _requestFormData.append("NotificationAgencySign", profile.notificationAgencySign);
            _requestFormData.append("NotificationUpdateMyPost", profile.notificationUpdateMyPost);
            _requestFormData.append("NotificationRequests", profile.notificationRequests);
            _requestFormData.append("NotificationReportedPost", profile.notificationReportedPost);
            _requestFormData.append("NotificationChat", profile.notificationChat);
            _requestFormData.append("TwofactorAuthentication", profile.twofactorAuthentication);
    
            executeFormData("POST", protectedEndPointAPI.profile.information.privacy.save, _requestFormData).then((response) => {
                var _data = response.data;
                if(_data !== null)  {
                    console.log("_data: ", _data);
                    if(response.success) {
                        NotificationManager.success('Success', 'DATA UPDATED CORRECTLY', 5000);
                    } else {
                        NotificationManager.error("ERROR WHILE SAVING PRIVACY INFORMATION", 5000);
                        console.log(response.message);
                    }
                }
             }).finally(() => {
                setIsLoading(false);
             });


        }

       

    },[appNBCContext,executeFormData, profile])

    // eslint-disable-next-line no-unused-vars
    const [selectedExportData, setSelectedExportData] = useState(1);
    const [isExportingData, setIsExportingData] = useState(false);

    const onChangeExportData = (e) => {
        setSelectedExportData(e.value);
    }

    const exportData = useCallback(() => {
        setIsExportingData(true);
        
        if(appNBCContext.dbUserId !== null) {
            executeBlob("PUT", protectedEndPointAPI.profile.exportdata,{TypeId: selectedExportData, UserId: appNBCContext.dbUserId})
                .then((response) => {
                    var _data = response.data;
                    if(_data !== null) {
                        const url = _data; // URL.createObjectURL(_data);
                        const link = document.createElement('a');
                        link.href = url;
                        link.download = 'myData.zip';
                        link.click();
                        setIsExportingData(false);
                    }
                })
                .catch((error) => {
                    setIsExportingData(false);
                    console.log(error);
                });
        }
    },[executeBlob,appNBCContext.dbUserId, selectedExportData])



    useEffect(() => {
        if(appNBCContext.dbUserId !== null) {
            if(appNBCContext.dbUserId !== "") {
                execute("PUT", protectedEndPointAPI.profile.information.base, appNBCContext.dbUserId).then((response) => {
                    var _data = response.data;
                    if(_data !== null) {
                        setProfile({
                            ...profile,
                            name: _data.name !== null ? _data.name.trimEnd() : "",
                            departament: _data.departament,
                            phone:  _data.phone === null ? "" : _data.phone.trimEnd(),
                            email: _data.email,
                            division: _data.division,
                            status: _data.status,
                            notificationAgency: _data.notificationAgency,
                            notificationAgencySign: _data.notificationAgencySign,
                            notificationChat: _data.notificationChat,
                            notificationUpdateMyPost: _data.notificationUpdateMyPost,
                            notificationReportedPost: _data.notificationReportedPost,
                            notificationRequests: _data.notificationRequests,
                            allowSearchMyProfile: _data.allowSearchMyProfile,
                            showEmail: _data.showEmail,
                            showPhone: _data.showPhone,
                            selectedImage: _data.urlPic,
                            profilePicBase64String: _data.profilePicBase64String,
                            profilePicFileType: _data.profilePicFileType,
                            twofactorAuthentication: _data.twofactorAuthentication,
                            agencyInfo: _data.agencyInfo,
                            addressId: _data.addressId,
                            nameAddress: _data.nameAddress,
                            latitude: _data.latitude,
                            longitude: _data.longitude,
                            state: _data.state
                        });
                        setValue('name', _data.name !== null ? _data.name.trimEnd().trimStart() : "");
                        setValue('phone', _data.phone === null ? "": _data.phone.trimEnd().trimStart());
                        setValue('division', _data.division);
                        
                    }
                });

                execute("GET", protectedEndPointAPI.profile.division.list).then((response) => {
                    var _data = response.data;
                    if(_data !== null) {
                        setListDivisions(_data);
                    }
                   
                });

                setIsLoading(false);
            } 
        }
        else {
            console.log("DB User Id is empty!");

        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[appNBCContext,execute]);

    useEffect(() => {
        console.log("msal error: ", error);
    },[error]);

    return (
    <>
        <Container className="customContainer" fluid>
                         <br />
                <div >
                    <Row>
                        <Col>
                            <h5>Profile & Privacy</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Tabs >
                                <Tab eventKey="Mydeparment" title="My Department">
                                    <br />
                                    <Row>
                                        <Form>
                                            <div className="card-header-profile">
                                                <img alt="" className="card-img-profile" src={profile.agencyInfo.picture} />
                                                {
                                                    appNBCContext.isSuperAdmin === true && 
                                                    <>
                                                     <input disable={isLoading} id="input-file" className="input-file" onChange={uploadPicAgency} type="file" accept="image/*" />
                                                     <label for="input-file" className="label-input-file">
                                                        <img alt="" src={editProfile}/>
                                                        </label>
                                                    </>
                                                }
                                            </div>
                                        </Form>
                                    </Row>
                                    <Row>
                                        <div className="divContent" style={{backgroundColor: "#FDFDFD"}}>
                                            <br />
                                            <br />
                                            <div className="centerDivProfile">
                                                <Row>
                                                    <div className="d-flex justify-content-center">
                                                        <h4>{profile.agencyInfo.name}</h4>
                                                    </div>
                                                </Row>
                                                <Row>
                                                    <Col className="d-flex justify-content-center lg-1">
                                                        <object data={locationIcon} width="14" height="17"> </object> &nbsp;{profile.agencyInfo.address}
                                                    </Col>
                                                </Row>
                                             </div>
                                             <br />

                                             <div className="row justify-content-md-center">
                                                <div className="col-md-auto">
                                                    <div className="col-xs-1 center-block">
                                                        <div className="textCenter">
                                                            <label className="textCenter textSecondary">Members:</label>
                                                        </div>
                                                        <div className="textCenter">
                                                            <label className="textCenter text">{profile.agencyInfo.officers} officers</label>
                                                        </div>
                                                        <br />
                                                        {
                                                            profile.agencyInfo &&  profile.agencyInfo.email && profile.agencyInfo.email !== "" &&
                                                            <>
                                                                <div className="textCenter">
                                                                    <label className="textCenter textSecondary">Email</label>

                                                                </div>
                                                                <div className="textCenter">
                                                                    <label className="textCenter textBlue"><a href={"mailto:" + profile.agencyInfo.email}>{profile.agencyInfo.email}</a></label>
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                    <div className="col-md-auto">
                                                        <div className="col-xs-1 center-block">
                                                            <div className="textCenter">
                                                                <label className="textCenter textSecondary">Post:</label>
                                                            </div>
                                                            <div className="textCenter">
                                                                <label className="textCenter text">{profile.agencyInfo.posts} published</label>
                                                            </div>
                                                            <br />
                                                            {
                                                                profile.agencyInfo && profile.agencyInfo.url && profile.agencyInfo.url !== "" &&
                                                                <>
                                                                    <div className="textCenter">
                                                                        <label className="textCenter textSecondary">Website:</label>
                                                                    </div>
                                                                    <div className="textCenter">
                                                                        <label className="textCenter textBlue">
                                                                        
                                                                            <Link to={{ pathname: profile.agencyInfo.url}} target="_blank" >{profile.agencyInfo.url}</Link>
                                                                        </label>
                                                                    </div>
                                                                </>
                                                            }
                                                        </div>
                                                     </div>
                                                    {
                                                        profile.agencyInfo && profile.agencyInfo.phone && profile.agencyInfo.phone !== "" &&
                                                        <div className="col-md-auto">
                                                            <div className="col-xs-1 center-block">
                                                                <div className="textCenter">
                                                                    <label className="textCenter textSecondary">Phone:</label>
                                                                </div>
                                                                <div className="textCenter">
                                                                    <label className="textCenter text"><a href={"tel:" + profile.agencyInfo.phone}>{profile.agencyInfo.phone}</a></label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>

                                             </div>
                                             <br />
                                             <hr className="hrProfile" />
                                             <br/>
                                             <Row className="hrProfile">
                                                <Col>
                                                    <div>
                                                        <div>
                                                            <label className="textCenter textSecondary">Admin:</label>
                                                        </div>
                                                        {
                                                            profile.agencyInfo && profile.agencyInfo.admins ?
                                                            profile.agencyInfo.admins.map((item) => {
                                                                    return (
                                                                        <AdminPreview key={item.id} data={item} /> 
                                                                    );
                                                                }
                                                                )
                                                                : ""
                                                        }
                                                    </div>
                                                </Col>
                                             </Row>
                                        </div>
                                    </Row>
                                    <br/>
                                </Tab>

                                <Tab eventKey="Profile" title="Profile">
                                    <br />
                                    <form key={1} onSubmit={handleSubmit(SaveInformation)}>
                                        <Row>
                                            <Col lg="2">
                                                <div >
                                                    <ProfilePicture name={profile.name} pictureChanged={onChangeSelectedImage} selectedImage={profile.selectedImage} binaryData={profile.profilePicBase64String}  />
                                                </div>
                                            </Col>
                                            <Col lg="10">
                                                <Row>
                                                    <Col>
                                                       
                                                        <Form.Label className="text" >Name</Form.Label>
                                                        <Form.Control type="text" 
                                                                     className={errors.name ? 'form-control is-invalid' : 'form-control'} 
                                                                     {...register("name", { required: true, maxLength: 20 })} 
                                                                     placeholder="Name..." 
                                                                     
                                                                     />
                                                        {errors.name && errors.name.type === "required" && <span className="text-danger">This is required</span>}
                                                       
                                                    </Col>
                                                    <Col>
                                                        <Row>
                                                            <Col>
                                                                <Form.Label className="text">Phone</Form.Label>
                                                            </Col>
                                                            <Col lg="3">
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <label className="labelText">Public</label>
                                                                    </div>
                                                                    <div className="col-1 toggle-switch">
                                                                        <div className="toggle-switch">
                                                                            <input id="phone" className="toggle-switch-1" checked={profile.showPhone} onChange={onChangeShowPhone} type="checkbox" />
                                                                            <label for="phone"></label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <InputMask placeholder="Phone..." className={errors.phone ? 'form-control is-invalid' : 'form-control'} {...register("phone", { required: true })} mask="+1\ 999 999 9999" />
                                                        {errors.phone && errors.phone.type === "invalid-format" && <span className="text-danger">Invalid format</span>}
                                                        {errors.phone && errors.phone.type === "required" && <span className="text-danger">This is required</span>}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Form.Label className="text">
                                                            Division
                                                        </Form.Label>
                                                        <select defaultValue="" className={errors.division ? 'form-control is-invalid' : 'form-control'} {...register("division", { required: true })}>
                                                            <option value="" disabled>Select</option>
                                                            {listDivisions.map((item) => (
                                                                <option key={item.id} value={item.id}>{item.name}</option>
                                                            ))}
                                                        </select>
                                                        {errors.division && errors.division.type === "required" && <span className="text-danger">This is required</span>}
                                                    </Col>
                                                    <Col>
                                                        <Row>
                                                            <Col>
                                                                <Form.Label className="text">
                                                                    Email
                                                                </Form.Label>
                                                            </Col>
                                                            <Col lg="3">
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <label className="labelText">Public</label>
                                                                    </div>
                                                                    <div className="col-1 toggle-switch">
                                                                        <div className="toggle-switch">
                                                                            <input id="email" type="checkbox" className="toggle-switch-1" checked={profile.showEmail} onChange={onChangeShowEmail} disabled />
                                                                            <label for="email"></label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>

                                                        </Row>

                                                        <input type="text" className="form-control" placeholder="Email..." onChange={onChangeEmail} value={profile.email} disabled readOnly  />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Form.Label className="text">
                                                            Address
                                                        </Form.Label>
                                                        <Autocomplete
                                                            apiKey={protectedVariable.GoogleMapAPIKey}
                                                            className="form-control"
                                                            placeholder="Enter your address..."
                                                            defaultValue={profile.nameAddress}
                                                            
                                                            options={{

                                                                componentRestrictions: { country: "us" },
                                                            }}

                                                            onChange={onChangeInputMap}
                                                            onPlaceSelected={(place) => {
                                                                onChangeLocation(place);
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <br />

                                        <Row>
                                            <Col lg="2">
                                            </Col>
                                            <Col>
                                               
                                                <div className="form-group" style={{fontFamily: "Work Sans"}}>
                                                    <Button color="primary" disabled={isLoading} >
                                                        Save changes
                                                        {isLoading && (
                                                            <span className="spinner-border spinner-border-sm"></span>
                                                        )}
                                                    </Button>
                                                    &nbsp;&nbsp;
                                                    {/* <Button onClick={DeleteAccountModal}>Delete account</Button> */}
                                                </div>
                                            </Col>
                                        </Row>
                                    </form>
                                   
                                </Tab>
                                <Tab eventKey="Privacy" title="Privacy & Notifications">
                                    <form key={2} onSubmit={handleSubmit2(SavePrivacy)}>
                                        <br />
                                        <div className="row">
                                            <div className="col-7">
                                                <label className="labelText">Receive email notifications for posts published to my department:</label>
                                            </div>
                                            <div className="col-1 toggle-switch">
                                                <div className="toggle-switch float-end">
                                                    <input id="notificationAgency" type="checkbox" onChange={onChangePostPublished} checked={profile.notificationAgency} className="toggle-switch-1" />

                                                    <label for="notificationAgency"></label>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="row">
                                            <Col lg="8">
                                                <hr />
                                            </Col>
                                        </div>

                                        <div className="row">
                                            <div className="col-7">
                                                <label className="labelText">Receive email notifications for posts published to departments to which I have signed up:</label>
                                            </div>
                                            <div className="col-1 toggle-switch">
                                                <div className="toggle-switch float-end">
                                                    <input id="notificationAgencySign" type="checkbox" onChange={onChangePostPublishedSign} checked={profile.notificationAgencySign} className="toggle-switch-1" />

                                                    <label for="notificationAgencySign"></label>
                                                </div>
                                            </div>
                                        </div>
                                        <br />

                                        <div className="row">
                                            <Col lg="8">
                                                <hr />
                                            </Col>
                                        </div>

                                        <div className="row">
                                            <div className="col-7">
                                                <label className="labelText">Receive email notifications for new messages sent to chat rooms I am in:</label>
                                            </div>
                                            <div className="col-1 toggle-switch">
                                                <div className="toggle-switch float-end">
                                                    <input id="notificationChat" type="checkbox" onChange={onChangeChat} checked={profile.notificationChat} className="toggle-switch-1" />

                                                    <label for="notificationChat"></label>
                                                </div>
                                            </div>
                                        </div>
                                        <br />

                                        <div className="row">
                                            <Col lg="8">
                                                <hr />
                                            </Col>
                                        </div>
                                        <div className="row">
                                            <div className="col-7">
                                                <label className="labelText">Receive email notifications for updated posts I have saved:</label>
                                            </div>
                                            <div className="col-1 toggle-switch">
                                                <div className="toggle-switch float-end">

                                                    <input id="notificationUpdateMyPost" type="checkbox" onChange={onChangeUpdatePost} checked={profile.notificationUpdateMyPost} className="toggle-switch-1" />

                                                    <label for="notificationUpdateMyPost"></label>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        {
                                            appNBCContext.isAdmin === true &&
                                            <>
                                                <div className="row">
                                                    <Col lg="8">
                                                        <hr />
                                                    </Col>
                                                </div>
                                        <div className="row">
                                            <div className="col-7">
                                                <label className="labelText">Receive email notifications for reported posts:</label>
                                            </div>
                                            <div className="col-1 toggle-switch">
                                                <div className="toggle-switch float-end">

                                                    <input id="notificationReportedPost" type="checkbox" onChange={onChangeReportedPost} checked={profile.notificationReportedPost} className="toggle-switch-1" />

                                                    <label for="notificationReportedPost"></label>
                                                </div>
                                            </div>
                                        </div>
                                                <br />
                                                <div className="row">
                                                    <Col lg="8">
                                                        <hr />
                                                    </Col>
                                                </div>
                                        <div className="row">
                                            <div className="col-7">
                                                        <label className="labelText">Receive email notifications for requests agency(only if the agency's requests are as manually):</label>
                                            </div>
                                            <div className="col-1 toggle-switch">
                                                <div className="toggle-switch float-end">

                                                    <input id="notificationRequests" type="checkbox" onChange={onChangeRequests} checked={profile.notificationRequests} className="toggle-switch-1" />

                                                    <label for="notificationRequests"></label>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                            </>
                                        }

                                        <div className="row">
                                            <Col lg="8">
                                                <hr />
                                            </Col>
                                        </div>

                                        {!appNBCContext.isSuperAdmin && 
                                        (
                                        <>
                                            <div className="row">
                                                <div className="col-7">
                                                    <label className="labelText">Allow officers to search my profile:</label>
                                                </div>
                                                <div className="col-1 toggle-switch">
                                                    <div className="toggle-switch float-end">
                                                        <input id="allowSearchMyProfile" type="checkbox" onChange={onChangeAllowSearch} checked={profile.allowSearchMyProfile} className="toggle-switch-1" />
                                                        <label for="allowSearchMyProfile"></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                            <div className="row">
                                                <Col lg="8">
                                                    <hr />
                                                </Col>
                                            </div>
                                        </>
                                        )}
                                        

                                        {/** Removed 2FA row since login features will be coming from Azure Identity**/}
                                        {/* <div className="row">
                                            <div className="col-7">
                                                <label className="labelText">Two-factor authentication:</label>
                                            </div>
                                            <div className="col-1 toggle-switch">
                                                <div className="toggle-switch float-end">
                                                    <input id="twofactorAuthentication" type="checkbox" onChange={onChangeTwofactorAuthentication} checked={profile.twofactorAuthentication} className="toggle-switch-1" />
                                                    <label for="twofactorAuthentication"></label>
                                                </div>
                                            </div>
                                        </div> */}
                                        <br />
                                        <Row>
                                            <Col lg={8}>
                                                <div className="form-group" style={{fontFamily: "Work Sans"}}>
                                                    <Button color="primary" disabled={isLoading} >
                                                        Save changes
                                                        {isLoading && (
                                                            <span className="spinner-border spinner-border-sm"></span>
                                                        )}
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </form>
                                </Tab>
                                {/** Removed password tab since login features will be coming from Azure Identity**/}
                                {/* <Tab eventKey="Password" title="Password">
                                    <form key={3} onSubmit={handleSubmit3(changePassword)}>
                                        <br />
                                        <Row>
                                            <Col lg="4">

                                                <Form.Label className="text">Old password</Form.Label>
                                                <Form.Control
                                                    type="password" placeholder="Enter your password" className={errors.name ? 'form-control is-invalid' : 'form-control'}
                                                    {...register3("oldPassword", { required: true })}  />
                                                {errors3.oldpassword && errors3.oldpassword.type === "required" && <span className="text-danger">This is required</span>}
                                                
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="4">

                                                <Form.Label className="text">New password</Form.Label>
                                                <Form.Control
                                                    type="password" placeholder="Enter new password" className={errors.name ? 'form-control is-invalid' : 'form-control'}
                                                    {...register3("password", { required: true })}  />
                                                {errors3.password && errors3.password.type === "required" && <span className="text-danger">This is required</span>}
                                                {validationErros.rulesError && <span className="text-danger">This field need format password(miminum 8 letter ( 1 lowercase,uppercase,numeric,special character)!</span> }
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="4">

                                                <Form.Label className="text">Confirm New password</Form.Label>
                                                <Form.Control
                                                    type="password" placeholder="Confirm the new password"  className={errors.name ? 'form-control is-invalid' : 'form-control'}
                                                    {...register3("newPassword", { required: true })}  />
                                                {errors3.newPassword && errors3.newPassword.type === "required" && <span className="text-danger">This is required</span>}
                                                {validationErros.matchError && <span className="text-danger">The password does not match</span> }
                                                
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <span className="hintSpan">Should be at least 8 characters </span>
                                            </Col>
                                            
                                        </Row>
                                        {serverErrors && <span className="text-danger">{serverErrors}</span>}

                                        <br />
                                        <Row>
                                            <Col lg={7}>
                                                <div className="form-group" style={{fontFamily: "Work Sans"}}>
                                                    <Button color="primary" disabled={isLoading} >
                                                        Change
                                                        {isLoading && (
                                                            <span className="spinner-border spinner-border-sm"></span>
                                                        )}
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </form>
                                    <br />
                                   
                                </Tab> */}
                                <Tab eventKey="Export data" title="Export data">
                                    <br />
                                    <div className="row">
                                        <br />
                                        <h5>Export your data</h5>
                                        <div className="col-4">
                                            {<ExportDataDropDownField data={exportDataListSelection} onChange={onChangeExportData} />}
                                        </div>
                                        <div className="col-2" style={{fontFamily: "Work Sans"}}>
                                            {!isExportingData && (<Button type="button" className="btn btn-default baseButton" onClick={() => {exportData()}} >
                                            Export
                                            </Button>)}
                                            {isExportingData && (<Button type="button" className="btn btn-default baseButton" disabled >
                                                <span className="spinner-border spinner-border-sm"></span> &nbsp; Exporting...
                                            </Button>)}
                                        </div>
                                        <Row>
                                            <Col >
                                                <span className="hintSpan">All data by selected type</span>
                                            </Col>
                                        </Row>
                                    </div>
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>
                    
                </div>
                
        </Container>
        <NotificationContainer />
    </>
    );


}

export default AzureProfile;