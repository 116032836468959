import React, { useState,useEffect,useCallback } from 'react';
import { Link } from 'react-router-dom';
import {  NavItem, NavLink, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";

import './AzureUserBar.css';
import Avatar from 'react-avatar';

import { StyledBadge } from './AzureUserBar.Style';
import microsoftLogo from "../../../assets/microsoft-logo.png";
import useFetchWithMsal from '../../hooks/useFetchWithMsal';
import { UsedAppNBCContext } from '../../AppNBC.Provider';





const AzureUserBarError = () => {

    const isAuthenticated = useIsAuthenticated();
    const {instance} = useMsal();

    const appNBCContext = UsedAppNBCContext();


    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [userFullName, setUserFullName] = useState("");

    // eslint-disable-next-line no-unused-vars
    const { error, execute } = useFetchWithMsal();

    const toggle = () => {
        setDropdownOpen(!dropdownOpen);
    }

    // const handleLogoutPopup = (event) => {
    //     event.preventDefault(); // 👈️ prevent page refresh    
    //     instance.logoutPopup({
    //       redirectUri: '/login'
    //   });
    
    // };

    const handleLogoutRedirect = (event) => {
        event.preventDefault(); // 👈️ prevent page refresh    
        instance.logoutRedirect();
      
    };

    const UserLogOutOnly = () => {
        return <ul className="nomgbottom">
        <NavItem className='logoutli spanhand col-6'>
            <Dropdown isOpen={dropdownOpen} toggle={toggle} className="dropdownprofile">
                <DropdownToggle caret className="droplog">
                <StyledBadge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant="dot">
                        {appNBCContext.imagePath !== undefined ? (<Avatar src={appNBCContext.imagePath} size="50" round  />) 
                        : (<Avatar src={microsoftLogo} size="50" round  />)}
                    </StyledBadge>
                </DropdownToggle>
                <DropdownMenu className="menudropdown">
                    <DropdownItem onClick={handleLogoutRedirect}>Log Out</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </NavItem>
    </ul>
    }

    useEffect(() => {
        if(instance.getActiveAccount() !== null) {
            if(userFullName === "") setUserFullName(instance.getActiveAccount().name);
        }
    },[instance,userFullName]);

    return (
        <div>
            {isAuthenticated ? (
                <div className="row">
                    <div className="col-5"></div>
                   
                    <div className="col-2">
                        {/**NOTIFICATION ARE HERE**/}
                    </div>
                    <div className="col-3 namenavbar nomgbottom"> <label className="namelbl"> {userFullName}</label></div>
                    <div className="col-2 nomgbottom">
                            <UserLogOutOnly />
                    </div>
                </div>
            ) : (
                <ul className="navbar-nav flex-grow">
                    <NavItem className='noborder'>
                        <NavLink tag={Link} className="text-dark" to="/login">Login</NavLink>
                    </NavItem>
                </ul>
            )}


        </div>
    );
}
export default AzureUserBarError;