import React from "react";
import { UsedAppNBCContext } from "../../AppNBC.Provider";



const AzurePostFileList = (props) => {

    const appNBCContext = UsedAppNBCContext();


    const renderSwitchFileCommon = (item) => {
        return  <a target="_blank" href={item.file.url} className="" rel="noreferrer">View File</a>
    }

    const renderSwitchFileBinaryData = (item) => {
        const url = `data:${item.fileType};base64,${item.fileBase64String}`;
        return <a target="_blank" href={url} className="" rel="noreferrer">View File</a>
    }

    return (
    <>
        <br/><br/>
        <h2>Files</h2> <hr></hr>
        <br/>
        {
            props.listfiles !== undefined ? 
            (
                props.listfiles.length > 0 ? 
                (
                    props.listfiles.map((item) => (
                        <div className="row cardpost" style={{marginLeft: "2px", marginRight: "2px", marginBottom: "10px"}}>
            
                            <div className="row">
                                <div className="col-9">
                                    {item.incident.incidentNum} - {item.incident.address}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-9">
                                    {item.file.fileName}
                                </div>
                                <div className="col-3">
                                    {item.incident.date}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-9">
                                    {(item.incident.agency !== undefined && item.incident.agency !== null ?  item.incident.agency.name : "")} - {item.incident.incidentCategory.name}
                                </div>
                                <div className="col-3">
                                {appNBCContext.isFileEncryptionEnable ? 
                                    (renderSwitchFileBinaryData(item))
                                    : 
                                    (renderSwitchFileCommon(item))
                                }
                                </div>
                            </div>
            
                        </div>
                    ))
                ) 
                : 
                (<></>)
            ) 
            : 
            (<></>)  
        }
     </>);

}


export default AzurePostFileList;