import React from "react";
import Avatar from 'react-avatar';
import { useHistory } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import StatusBadge from "../Officer/StatusBadge";


const CardPostOfficersCardInfo = (props) => {

    let data = props.data;

    let history = useHistory();

    const RedirectMessage = (state, userId, name, type, urlPic, phone, email, address, typechat) => {
        if (state != null && (state === "Suspended" || state === "Removed")) {
            NotificationManager.info('Information', 'The account cannot be contacted because it was deleted or suspended.', 5000);
            return;
        }

        let img = urlPic;
        if (props.Incident !== undefined && (props.Incident.filesIncidents != null && props.Incident.filesIncidents !== undefined && props.Incident.filesIncidents.length > 0)) {
            img = props.Incident.filesIncidents[0].file.path;
        }

        history.push({
            pathname: `/message`,
            search: `?userId=${userId}&name=${name}&type=${props.Incident !== undefined ? props.Incident.type : type}&typechat=${typechat}&incident=${props.Incident !== undefined ? props.Incident.id : ""}&address=${props.Incident !== undefined ? props.Incident.address : ""}`,
            state: {
                urlPic: img,
                userId: userId,
                name: name,
                type: (props.Incident !== undefined ? props.Incident.type : type),
                typechat: typechat,
                incident: props.Incident !== undefined ? props.Incident.id : "",
                address: props.Incident !== undefined ? props.Incident.address : "",
                phone: phone,
                email: email,
                userAddress: address
            }

        });

    }
    
    return (
    <>
        <div className="row">
            <div className="col-6" key={['idoffice', data.id]} style={{marginTop: "20px"}}>
                <div className="row  card-officer">
                    <div className="col-3 card no-mgr" style={{marginRight: "20px"}}>
                        <div className="row" style={{flex: "1", alignItems:"center", justifyContent:"center", marginRight:"10px", marginTop:"10px"}}>
                            {data.urlPic && <Avatar src={data.urlPic} size="80" round  className="avatarofficer" />}
                            {!data.urlPic && <Avatar name={data.name} size="80" round />}
                        </div>
                        <div className="row" style={{flex: "1", alignItems:"center", justifyContent:"center", fontFamily: "Work Sans"}}>
                            <button type="button" 
                                    disabled={data.deleted ? true : data.statusApp === "Suspended" ? true : false}
                                    className="btn btn-outline-primary btn-lg btn-bloc col-lg-10"  
                                    onClick={() => RedirectMessage(data.statusApp, data.id, data.name, data.division, data.urlPic, data.phone, data.email, data.address, "user")}>
                                <div style={{fontSize: "14px"}}>Message</div>
                            </button>
                        </div>
                    </div>
                    
                    <div className="col-8 card bg-white no-mgl">
                            <div className="row" style={{textAlign: "right", paddingRight: "15px"}}><span>{StatusBadge(data.deleted ? 3 : data.statusApp === "Suspended" ? 2 : 1)}</span></div>
                            <div className="row"> <label className="titlename">{data.name}</label></div>
                            <div className="row"> <label className="lblinfo">Division:  <span className="txtinfo">{data.division}</span></label></div>
                            <div className="row"> <label className="lblinfo">Department:  <span className="txtinfo">{data.department}</span></label></div>
                            <div className="row"> <label className="lblinfo">Phone:   <span className="txtinfo"><a href={`tel:${data.phone ?? ""}`}>{data.phone}</a></span></label></div>
                            <div className="row"> <label className="lblinfo">Email: <span className="txtinfo"><a href={`mailto:${data.email}`}>{data.email}</a></span></label> </div>
                            <div className="row"> <label className="lblinfo">Address: <span className="txtinfo">{data.address}</span></label> </div>
                    </div>
                </div>
            
            </div>
        </div>
    </>);

}


export default CardPostOfficersCardInfo;