import React, {useCallback, useEffect,useState} from 'react';
import { Route } from 'react-router';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { HubConnectionBuilder,LogLevel } from '@microsoft/signalr';
import { LayoutNoLogged } from '../components/LayoutNoLogged';
import { LayoutLoggedIn } from './Layout/LayoutLoggedIn';
import { LayoutLoggedInError } from './Layout/LayoutLoggedInError';
import { AzureLogin } from './Authentication/AzureLogin'
// eslint-disable-next-line no-unused-vars
import { Post, GridPost, PostInfo } from '../components/Post'; //Do not remove this since the layout of login screen will be affected
import "./custom.css"
import "@fontsource/work-sans";
import { AzureAccounts } from "./components/AzureAccounts"
import { AzureAgency, AzureAdminAgency, AzureAgencyView } from './components/AzureAgencies/';
import { AzureChatPanel } from './components/AzureChat/';
import { AzureDepartment } from './components/AzureDepartment/';
import { AzureHome } from './components/AzureHome';
import { AzureJoinRequest } from "./components/AzureJoinRequests";
import { AzurePostEdit, AzurePostView } from './components/AzurePost';
import { AzureProfile } from './components/AzureProfile';
import { AzureReportedPost } from './components/AzureReportedPost/';
import TermsAndCondition from './components/TermsAndCondition/TermsAndCondition';
import { AppInnerLoader, UserNotYetSyncInner, UserInactiveSyncInner } from './AppInner.Style';
import { useMsal } from '@azure/msal-react';
import { useIsAuthenticated } from '@azure/msal-react';
import useFetchWithMsal from './hooks/useFetchWithMsal';
import { UsedAppNBCContext } from './AppNBC.Provider';
import { protectedEndPointAPI,protectedMSGraphEndPointAPI } from './Helpers/ProtectedCustomClass';


const UserNotYetSync  = () => {
  return (
    <AuthenticatedTemplate>
      <LayoutLoggedInError>
        <Route exact path='/' component={UserNotYetSyncInner} />
        <Route exact path='/notsync' component={UserNotYetSyncInner} />
      </LayoutLoggedInError>
    </AuthenticatedTemplate>
  )
}

const UserInactiveSync  = () => {
  return (
    <AuthenticatedTemplate>
      <LayoutLoggedInError>
        <Route exact path='/' component={UserInactiveSyncInner} />
        <Route exact path='/notsync/inactive' component={UserInactiveSyncInner} />
      </LayoutLoggedInError>
    </AuthenticatedTemplate>
  )
}


const AppInner = () => {

  // eslint-disable-next-line no-unused-vars
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [isLoading, setIsLoading] = useState(true);
  const [isExecuteProcess2, setIsExecuteProcess2] = useState(false);
  const [isExecuteProcess3, setIsExecuteProcess3] = useState(false);
  const [isExecuteProcess4, setIsExecuteProcess4] = useState(false);
  const [isExecuteProcess5, setIsExecuteProcess5] = useState(false);
  const [isTermConditionAccepted, setIsTermConditionAccepted] = useState(false);


  // eslint-disable-next-line no-unused-vars
  const { error, execute, accessToken,executeOnAccessToken } = useFetchWithMsal();

  const appNBCContext = UsedAppNBCContext();

  const handleOnAcceptTerms = useCallback(() => {
    sessionStorage.setItem('termsConditionAccepted-' + appNBCContext.dbUserId, JSON.stringify(true));
    localStorage.setItem('termsConditionAccepted-' + appNBCContext.dbUserId, JSON.stringify(true));
    setIsTermConditionAccepted(true);
  },[appNBCContext]);

  //Execute Step 1
  useEffect(() => {
    if(instance.getActiveAccount() !== null) {

      var _account = instance.getActiveAccount();

      if(appNBCContext.dbUserId !== null) {
        if(appNBCContext.dbUserId === "") {
          
          let _requestBody = {
            Email: _account.username,
            FullName:  _account.name
          }
          execute("POST", protectedEndPointAPI.accounts.sync, _requestBody)
          .then((response) => {
              if(response.success) {
                var _data = response.data;
                if(_data !== null) {
                    appNBCContext.onSetDbUserId(_data.dbUserId);
                    appNBCContext.onSetDbAgencyId(_data.dbAgencyId);
                    appNBCContext.onSetImagePath(_data.imagePath);
                    appNBCContext.onSetImageBase64String(_data.imageBase64String);
                    appNBCContext.onSetDbIsDeleted(_data.dbIsDeleted);
                    appNBCContext.onSetDbIsChief(_data.dbIsChief);
                    appNBCContext.onSetAgencyGeoLoc(
                      {
                        key: _data.agencyName,
                        imagePath: _data.agencyImagePath,
                        imageBase64String: _data.agencyImageBase64String,
                        lat: _data.agencyAddressLatitude, 
                        lng: _data.agencyAddressLongitude
                      }
                    );
                    if(!_data.dbIsDeleted)  {
                      setIsExecuteProcess2(true)
                    } else {
                      setIsLoading(false);
                    };
                  
                }
              }
          });

        }

      }

    }
  
  },[instance,appNBCContext,execute]);

  //Execute Step 2
  useEffect(() => {
    if(isExecuteProcess2) {
      if(instance.getActiveAccount() !== null) {
          var _account = instance.getActiveAccount();
          const _userRoles = _account.idTokenClaims.roles;

          if(_userRoles !== null) {
            if(appNBCContext.userRoles !== null) {
              if(appNBCContext.userRoles.length <= 0)  appNBCContext.onUserRoles(_userRoles);
            }
  
            if(appNBCContext.isSuperAdmin !== null) {
              if(appNBCContext.isSuperAdmin !== "") {
                appNBCContext.onSetIsSuperAdmin(_userRoles.includes("Manager") || _userRoles.includes("SuperAdmin"));
              }
            }
  
            if(appNBCContext.isAdmin !== null) {
              if(appNBCContext.isAdmin !== "") {

                if(appNBCContext.dbIsChief !== null) {
                  if(appNBCContext.dbIsChief !== "") {
                      if(appNBCContext.dbIsChief  === true ) {
                        appNBCContext.onSetIsAdmin(true);
                      } else {
                        appNBCContext.onSetIsAdmin(_userRoles.includes("Manager") || _userRoles.includes("Admin"));
                      }
                  } else {
                    appNBCContext.onSetIsAdmin(_userRoles.includes("Manager") || _userRoles.includes("Admin"));
                  }

                } else {
                  appNBCContext.onSetIsAdmin(_userRoles.includes("Manager") || _userRoles.includes("Admin"));

                }
              }
            }
  
            setIsExecuteProcess3(true);
            setIsExecuteProcess2(false);

          } else {
            console.log("error: roles for this user is not yet configured.")

          }
        
        } else {
          console.log("error: instance active account is null.")
        }
       
    }
  
  },[instance,appNBCContext,execute,isExecuteProcess2]);

  //Execute Step 3
  useEffect(() => {
    if(isExecuteProcess3) {
      if(instance.getActiveAccount() !== null) {

          if(accessToken !== null) {
            executeOnAccessToken("GET", protectedMSGraphEndPointAPI.groups, accessToken)
            .then((response) => {
              var _data = response.value;
              if(_data !== null) {

                let _itemToSelect = [];
                
                // eslint-disable-next-line no-unused-vars
                var _dataGroups = _data.map((item) => {
                  var _filterItem = item.groupTypes.filter(x => x.toLowerCase().includes("unified")); //groupTypes should be with "unified"
                  if(_filterItem.length > 0) {
                    _itemToSelect.push({id: item.id,displayName: item.displayName});
                  } 
                  return null;
                });

                let _groupIds = _itemToSelect.map(item => item.id);
                
                if(_groupIds !== undefined) {

                  let _itemToSelectFiltered = [];

                  if(_groupIds.length > 0) {

                      executeOnAccessToken("POST", protectedMSGraphEndPointAPI.me.checkMemberGroups, accessToken,{groupIds: _groupIds})
                        .then((response) => {
                          var _data = response.value;
                          if(_data != null) {
                            // eslint-disable-next-line no-unused-vars
                            var _dataMemberGroups = _data.map((item) => {
                              var _filterItem = _itemToSelect.filter(x => x.id === item); 
                              if(_filterItem.length > 0) {
                                _itemToSelectFiltered.push(..._filterItem);
                              } 
                              return null;
                            });

                            if(_itemToSelectFiltered.length > 0) {
                              let _requestBody = {
                                UserId: appNBCContext.dbUserId,
                                AzureGroups: _itemToSelectFiltered
                              }

                              execute("POST", protectedEndPointAPI.agencies.azuregroups.sync, _requestBody)
                                .then((response) => {
                                    var _data = response.data;
                                    if(_data !== null) console.log("successfully sync azure groups");
                                }).finally(() => {
                                  setIsLoading(false);
                                });

                             } else {
                              console.log("No azure groups need to sync.");
                              setIsLoading(false);
                             }
                            
                          }
                        });
                  }

                }
              }
            });
          }
          
      }
      setIsExecuteProcess3(false);
      setIsExecuteProcess4(true);

    }
  
  },[instance, appNBCContext, isExecuteProcess3, accessToken, executeOnAccessToken, execute]);

  //Execute Step 4
  useEffect(() => {
    if(isExecuteProcess4) {
      if(instance.getActiveAccount() !== null) {
        if(appNBCContext.dbUserId !== null) {
          if( appNBCContext.dbUserId !== "") {
            const storedTermsCondition = sessionStorage.getItem('termsConditionAccepted-' + appNBCContext.dbUserId );
            if(storedTermsCondition !== null) {
              setIsTermConditionAccepted(JSON.parse(storedTermsCondition));
            }
          }
        }
      }
      setIsExecuteProcess4(false);
      setIsExecuteProcess5(true);
    }
  },[instance, appNBCContext, isExecuteProcess4, accessToken, executeOnAccessToken, execute])

    //Execute Step 5
    useEffect(() => {
      if(isExecuteProcess5) {
        if(instance.getActiveAccount() !== null) {
          if(appNBCContext.dbUserId !== null) {
            if( appNBCContext.dbUserId !== "") {

              if(!appNBCContext.signalRdisabled) {
                let currentAppIdKey = Math.trunc(Math.random() * (10000 - 1 + 1));

                const conn = new HubConnectionBuilder()
                  .withUrl("/hubs/chat?hubKey=" + currentAppIdKey + "&userId=" + appNBCContext.dbUserId )
                  .withAutomaticReconnect()
                  //.configureLogging(LogLevel.Information)
                  .build();
  
                  conn.start();
  
                  appNBCContext.onSetSignalRConnection(conn);
              }
            }
          }
        }
        setIsExecuteProcess5(false);
      }
    },[instance, appNBCContext, isExecuteProcess5, accessToken, executeOnAccessToken, execute])

    //Intended for opening new tab due to the bug found on terms and condition
    useEffect(() => {
      if(appNBCContext.dbUserId !== null) {
        if( appNBCContext.dbUserId !== "") {
          const storedTermsCondition = sessionStorage.getItem('termsConditionAccepted-' + appNBCContext.dbUserId );
          if(storedTermsCondition == null) {
            const storedLocalTermsCondition = localStorage.getItem('termsConditionAccepted-' + appNBCContext.dbUserId );
            if(storedLocalTermsCondition !== null) {
              setIsTermConditionAccepted(JSON.parse(storedLocalTermsCondition));
            }
          }
        }
      }
    },[appNBCContext])


  // useEffect(() => {console.log("inProgress: ",inProgress)},[inProgress]); //added temp

  useEffect(() => {
    // console.log("instance: ",instance) //added temp
    // console.log("instance.getActiveAccount(): ",instance.getActiveAccount()) //added temp
    // console.log("accessToken: ",accessToken) //added temp

    // if(!(accessToken == null)) {
    //   if(instance.getActiveAccount() !== null) {
    //     const logoutRequest = {
    //       account: instance.getAccountByHomeId(instance.getActiveAccount().homeAccountId),
    //       postLogoutRedirectUri: "/login",
    //     };
    //     instance.logoutRedirect(logoutRequest);
    //   }
    // }
  },[instance,accessToken])

  // //added temp
  // useEffect(() => {
  //   console.log("appNBCContext.dbUserId: ", appNBCContext.dbUserId); 
  // },[appNBCContext.dbUserId]);

  // useEffect(() => {
  //   console.log("msal-error: ",error); //added temp
  // },[error])

  return (
    <>
      {isAuthenticated ? (
        isLoading ? (<AppInnerLoader />) 
        : 
        (
          appNBCContext.dbUserId !== null ? 
          (
           appNBCContext.dbUserId !== "" ? 
           (

            appNBCContext.dbIsDeleted ? 
            (<UserInactiveSync />) 
            : 
            (
              isTermConditionAccepted === false ? (<TermsAndCondition HandleOnAcceptTerms={handleOnAcceptTerms}/>) : (
              <AuthenticatedTemplate>
               <LayoutLoggedIn>
                 <Route exact path='/' component={AzureHome} />
                 <Route exact path='/home' component={AzureHome} />
                 <Route exact path={'/post/edit/:id'} component={AzurePostEdit} />
                 <Route exact path={'/post/view'} component={AzurePostView} />
                 <Route exact path='/profile' component={AzureProfile} />
                 <Route exact path='/message' component={AzureChatPanel} />
                 <Route exact path='/mydepartments' component={AzureDepartment} />
                 <Route exact path='/accounts' component={AzureAccounts} />
                 <Route exact path='/joinrequests' component={AzureJoinRequest} />
                 <Route exact path='/reportedposts' component={AzureReportedPost} />
                 <Route exact path='/agencies' component={AzureAdminAgency} />
                 <Route exact path='/agencies/new' component={AzureAgency} />
                 <Route exact path='/agencies/update' component={AzureAgency} />
                 <Route exact path='/agency/view' component={AzureAgencyView} />
                 <Route exact path='/login' component={AzureLogin} />
               </LayoutLoggedIn>
             </AuthenticatedTemplate>
             )
            )

             
           ) : 
           (<UserNotYetSync />)
          ) 
          : 
          (<UserNotYetSync />)
        ) 
      ) 
      : 
      (      
      <UnauthenticatedTemplate>
        <LayoutNoLogged>
            <Route exact path='*' component={AzureLogin} />  {/**Redirect to login page when browsing non existing page**/}
            <Route exact path='/login' component={AzureLogin} />
        </LayoutNoLogged>
      </UnauthenticatedTemplate>)}
    </>
  );
}
export default AppInner;