
import React from "react";

export const InitialDepartmentData = [
    { value: 0, label: 'All Departments' }
]

export const InitialDivisionData = [
    { value: 0, label: 'All Divisions' }
]

export const InitialStatusData = [
    { value: -2, label: 'All Statuses' }
]

