import React, {useEffect, useState, useCallback} from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, Row, Col, Button, Form} from "reactstrap"
import { NotificationManager } from 'react-notifications';
import { useForm } from 'react-hook-form';
import Table from "../../../components/Common/table"
import Pagination from "../../../components/Common/pagination"
import { UsedAppNBCContext } from "../../AppNBC.Provider";
import useFetchWithMsal from "../../hooks/useFetchWithMsal";
import { protectedEndPointAPI } from "../../Helpers/ProtectedCustomClass";
import folderIcon from "../../../assets/folderIcon.png"
import savedIcon from "../../../assets/savedIcon.png"
import btnIconPlus from "../../../assets/BntIconPlus.png"
import  AzureBookmarkNewModal from "./AzureBookmarkNewModal";

const AzureBookmarkModal = (props) => {

    const appNBCContext = UsedAppNBCContext();
    // eslint-disable-next-line no-unused-vars
    const { error, execute } = useFetchWithMsal();

    const [currentPage, setCurrentPage] = useState(1);

    // eslint-disable-next-line no-unused-vars
    const [exists, setExists] = useState(false);

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        mode: 'onChange'
    });

    const saveRelateCrime = useCallback((data) => {
        if(appNBCContext.dbUserId !== null) {

            setLoading(true);

            let _requestBody = {
                UserId: appNBCContext.dbUserId,
                IsSuperAdmin: appNBCContext.isSuperAdmin,
                IsAdmin: appNBCContext.isAdmin,
                BookmarkName: data.name,
                IncidentId: props.datamodel.id,
                FolderId: data.folder ? data.folder : 0,
                IncidentNumber: props.datamodel && props.datamodel.incidentNum ? props.datamodel.incidentNum : ""
            }

            execute("POST", protectedEndPointAPI.home.incident.bookmark.related.save, _requestBody)
                .then((response) => {
                    if(response.success) {
                        NotificationManager.success('Post saved to collection successfully.', 'Success', 5000);
                    } else {
                        NotificationManager.error("Error while saving incident post collection", 5000);
                    }
                })
                .finally(() => {
                    props.ReloadRelated();
                    setLoading(false);
                    props.closeFolders();
                    props.AfterSaveRelateCrime();
                    reset({ name: ""});
                });

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[execute, appNBCContext,props.datamodel])

    const onSelectChange = (action) =>
    {
        saveRelateCrime({ name: action.folderName + ":" + props.datamodel.incidentNum, folder: action.id});
    } 

    //Table folders 
    const columns = [
        {
            Header: "",
            accessor: "folderName",
            Cell: cellInfo => <>
                <div onClick={() => onSelectChange(cellInfo.data[cellInfo.row.index])}>
                    <Row>
                       <Col sm={3}>
                        <img width={50} height={50}  src={cellInfo.data[cellInfo.row.index].notCanDelete ===false ?  folderIcon: savedIcon} alt=""  />
                       </Col>
                       <Col  className="my-auto">
                            {cellInfo.data[cellInfo.row.index].folderName}
                       </Col>
                    </Row>
                    
                </div>

            </>
        }
    ];
    
    const [pageData, setPageData] = useState({
        rowData: [],
        isLoading: false,
        totalPages: 0,
        totalData: 0,
    });
    
    const [loading, setLoading] = useState(false);
    const [showNewFolder, setShowNewFolder] = useState(false);
    const { datamodel } = props;
    const [showCreateThread, setShowCreateThread] = useState(false);

    const loadFolders = useCallback(async (filter) => {
        if(appNBCContext.dbUserId !== null) {
            let _requestBody = {
                UserId: appNBCContext.dbUserId,
                IsSuperAdmin: appNBCContext.isSuperAdmin,
                IsAdmin: appNBCContext.isAdmin,
                Page: currentPage,
                Take: 7,
                SearchCriteria: filter,
                IncidentId: props.data === undefined ? 0:props.data.id
            }

             execute("PUT", protectedEndPointAPI.home.incident.bookmark.related.list, _requestBody).then((response) => {
                var _data = response.data;
                if(_data !== null) {
                    const { totalPages, data, totalData } = _data;
                    setPageData({
                        isLoading: false,
                        rowData: data,
                        totalPages,
                        totalData,
                    });
                }
            });

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[execute, appNBCContext,currentPage])

    const openNewFolder = () => {
        setShowCreateThread(true);
    }

    const closeNewFolder = () => {
        setShowNewFolder(false);
    }

    useEffect(() => {
        if (datamodel !== undefined && datamodel.id !== 0) {
            loadFolders("");
        }
       
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, datamodel])

    return (
    <>
        <Modal size="sm" isOpen={props.show}>
            <ModalHeader toggle={() => { props.closeFolders(); }} cssModule={{ 'modal-title': 'w-100 text-center' }}>
                Save to
            </ModalHeader>
            <ModalBody>
                <div>
                   
                    <div className="withoutHeader">
                        <Table  columns={columns} data={pageData.rowData} />
                    </div>
                   
                    <Pagination
                        totalRows={pageData.totalData}
                        pageChangeHandler={setCurrentPage}
                        rowsPerPage={7}
                    />
                    
                </div>

            </ModalBody>
            <ModalFooter className="borde_footer">
                <Form onSubmit={handleSubmit(saveRelateCrime)}>

                    {
                        showCreateThread === false &&
                        <Row>
                            <Col>
                                <Button
                                    type="submit"
                                    className="buttonWithoutBorder"
                                    style={{border: "0px"}}
                                    onClick={openNewFolder}
                                    disabled={loading}
                                >
                                    <img src={btnIconPlus} alt="" />
                                    &nbsp;
                                    New thread

                                </Button>
                            </Col>
                        </Row>
                    }
            

                    {
                        showCreateThread === true &&
                        <>
                            <div className="row">
                                <label>Name</label>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <input type="text" className={errors.name ? 'form-control is-invalid' : 'form-control'} {...register("name", { required: true, maxLength: 200 })} placeholder="Name..." />
                                    {errors.name && errors.name.type === "required" && <span className="text-danger">This is required</span>}
                                    {exists && <p className="alert alert-danger">The folder name already exists.</p>}
                                </div>
                            </div>
                            <br/>
                            <Row>
                                <div style={{background: "transparent", paddingLeft: "35px"}}>
                                   
                                    <Button className="cancelBtn" onClick={() => {setShowCreateThread(false);}}>Cancel</Button>
                                    {' '}&nbsp;&nbsp;
                                    <Button type="submit" color="primary" disabled={loading}>{"Create"} {loading && (<span className="spinner-border spinner-border-sm"></span>)}</Button>
                                </div>
                            </Row>
                        </>
                    }

            
                </Form>
            </ModalFooter>
        </Modal>
        <AzureBookmarkNewModal show={showNewFolder}  closeModal={closeNewFolder} />
    </>
    );

}

export default AzureBookmarkModal;