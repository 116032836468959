import React, { useCallback } from "react";
import {faCircleXmark,faUserGroup,faBookmark,faShareSquare,faBell,faTrashCan,faTriangleExclamation,faPencil,faBrain } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col } from 'reactstrap';
import { library } from '@fortawesome/fontawesome-svg-core';
import "./AzurePost.Options.css"
import { UsedAppNBCContext } from "../../AppNBC.Provider";
import useFetchWithMsal from "../../hooks/useFetchWithMsal";
import { protectedEndPointAPI } from "../../Helpers/ProtectedCustomClass";

library.add(faCircleXmark,faUserGroup, faBookmark, faShareSquare, faBell, faTrashCan, faTriangleExclamation, faPencil, faBrain)
const AzurePostOptions = (props) => {
    const { data, OpenSharePost, OpenReportPost } = props;
    
    const appNBCContext = UsedAppNBCContext();
    // eslint-disable-next-line no-unused-vars
    const { error, execute } = useFetchWithMsal();
    
    const removeRelate = useCallback((data) => {
        if(appNBCContext.dbUserId !== null) {
             //delete related post
             execute("POST", protectedEndPointAPI.home.incident.related.delete, data.Id)
             .then((response) => {
                //var _data = response.data;
                props.AfterRemoveRelateCrime();
                
            });
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[execute, appNBCContext])

    return (
        <>
            <Row className="option" style={{border: "none"}}>
                {
                    (data.relateCrimes && data.relateCrimes.length === 0) &&
                    <>
                        <Col md="2" >
                            <FontAwesomeIcon className="iconPopup" icon={faBookmark} size="xl" />
                        </Col>
                        <Col md="5">
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a className="transparentLink" href="#" onClick={(e) => props.OpenFolders(e, data)}>
                                Save post
                            </a>
                        </Col>
                    </>
                }
                {

                    (data.relateCrimes && data.relateCrimes.length > 0) &&
                    <>
                        <Col md="2" className="centerDivVerticall">
                            <FontAwesomeIcon className="iconPopup" icon={faCircleXmark} size="xl" />
                        </Col>
                        <Col md="6">
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a className="transparentLink" href="#" onClick={(e) => removeRelate({ Id: data.relateCrimes[0].id })}>
                                Remove from {' '}
                                {data.relateCrimes[0].name}
                            </a>
                        </Col>
                    </>
                }


            </Row>

            {
                (data.canEdit && data.canEdit === true && !props.IsSuperAdmin) && <Row className="option" style={{border: "none"}}>

                    <Col md="2">
                        <a href={"/post/edit/" + data.id}>
                            <FontAwesomeIcon className="iconPopup" icon={faPencil} size="xl" />
                        </a>
                    </Col>
                    <Col md="5">
                        <a href={"/post/edit/" + data.id}>
                            Edit Post
                        </a>
                    </Col>

                </Row>
            }
            

            <Row className="option" onClick={OpenReportPost} style={{border: "none"}}>
                <Col md="2">
                    <FontAwesomeIcon className="iconPopup" icon={faTriangleExclamation} size="xl" />
                </Col>
                <Col md="5">
                    Report Post
                </Col>

            </Row>


            {
                data.canEdit && data.canEdit === true && <Row className="option" style={{border: "none"}} onClick={() => {
                    props.DeleteIncident(data.id);
                }}>
                    <Col md="2">
                        <FontAwesomeIcon className="iconPopup" icon={faTrashCan} size="xl" />
                    </Col>
                    <Col md="10">
                        Remove Post
                    </Col>

                </Row>
            }
           

            <Row className="option" style={{border: "none"}} onClick={()=> props.SetNotification(data)}>
                <Col md="2">
                    <FontAwesomeIcon className="iconPopup" icon={faBell} size="xl" />
                </Col>
                <Col md="10">
                    Get Notified
                </Col>

            </Row>

            <Row className="option" style={{border: "none"}} onClick={OpenSharePost}>
                <Col md="2">
                    <FontAwesomeIcon className="iconPopup" icon={faShareSquare} size="xl" />
                </Col>
                <Col md="10">
                    Share Post with fellow user
                </Col>

            </Row>

        </>
    );
}

export default AzurePostOptions;