import React from "react";
import { Row} from "reactstrap";
import row_load from "../../../assets/row_load.png";
import "./Loader.css";
const LoaderAgency = () => {
    return (
        <>
            <Row>
                <img src={row_load} className="row_load" alt="" />
            </Row>
            <Row>
                <img src={row_load} className="row_load" alt="" />
            </Row>
            <Row>
                <img src={row_load} className="row_load" alt="" />
            </Row>
            <Row>
                <img src={row_load} className="row_load" alt="" />
            </Row>
        </>
    );
};
export default LoaderAgency;
