import React, {useEffect, useState, useCallback} from "react";
import { Col, Nav, Row} from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { NotificationContainer } from 'react-notifications';
import { UsedAppNBCContext } from "../../AppNBC.Provider";
import useFetchWithMsal from "../../hooks/useFetchWithMsal";
import { protectedEndPointAPI } from "../../Helpers/ProtectedCustomClass";

import "./AzureHome.css"
import Pagination from "../../../components/Common/pagination";
import AzurePost from "../AzurePost/AzurePost"; 
import AzurePostModal from "../AzurePost/AzurePostModal";
import {AzureBookmarkModal} from "../AzureBookmark";
import AzureIncidentMapView from "../AzureIncidentMapView/AzureIncidentMapView";
import * as _ from "lodash";
//import ShowFoldersModal from "../../../components/ReleatedCrimes/Folders/RelatedCrimeFolderModal"


const initialIncidentTypeData = [
    { value: 0, label: 'All' }
]

const AzureHome = () => {

    const appNBCContext = UsedAppNBCContext();
    // eslint-disable-next-line no-unused-vars
    const { error, execute } = useFetchWithMsal();

    const [isInitialLoadDone, setIsInitialLoadDone] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [agenciesuser, setAgenciesUser] = useState([]);
    const [listPostFeed, setListPostFeed] = useState([]);
    const [listPostFeedMap, setlistPostFeedMap] = useState([]);

    const [listPostBolos, setListPostBolos] = useState([]);
    const [listPostBolosMap, setListPostBolosMap] = useState([]);

    const [listPostOfficer, setListPostOfficer] = useState([]);
    const [listPostOfficerMap, setListPostOfficerMap] = useState([]);

    const [listPostAttempt, setListPostAttempt] = useState([]);
    const [listPostAttemptMap, setListPostAttemptMap] = useState([]);

    const [listPostRelated, setListRelated] = useState([]);
    const [listPostRelatedMap, setListRelatedMap] = useState([]);

    const [listIncidentTypeData, setListIncidentTypeData] = useState(initialIncidentTypeData);

    const [openCreatePost, setOpenCreatePost] = useState(false);
    const [listfolders, setListFolders] = useState([]);
    const [notificationPost, setNotificationPost] = useState({});
    const [smsNotification, setSmsNotification] = useState(false);
    const [emailNotification, setEmailNotification] = useState(false);
    const [loadingPosts, setLoadingPosts] = useState(true);
    const [isAfterDelete, setIsAfterDelete] = useState(false);
    const [isAfterCreatedPost, setIsAfterCreatedPost] = useState(false);
    const [isAfterSaveRelateCrime, setIsAfterSaveRelateCrime] = useState(false);
    const [isAfterRemoveRelateCrime, setIsAfterRemoveRelateCrime] = useState(false);

    const [modalDelete, setModalDelete] = useState({
        show: false,
        id: "",
        name: ""
    });

    const toggleDelete = (incident) => {
        setModalDelete({
            ...modalDelete,
            show: !modalDelete.show,
            id: incident,
            name: incident.incidentNum
        });

    }

    const DeletePost = useCallback((id) => {
        if(appNBCContext.dbUserId !== null) {
            let _requestBody = {
                UserId: appNBCContext.dbUserId,
                IsSuperAdmin: appNBCContext.isSuperAdmin,
                IsAdmin: appNBCContext.isAdmin,
                Id: id
            }

            execute("POST", protectedEndPointAPI.home.incident.delete.base, _requestBody).then((response) => {
                var _data = response.data;
                if(_data !== null) { 
                    toggleDelete(false);
                    setIsAfterDelete(true);
                }
            });

        }
            
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[execute, appNBCContext])

    // eslint-disable-next-line no-unused-vars
    const [chatMessage, setChatMessage] = useState({});

    const [currentTab, setCurrentTab] = useState("first");

    const handleSelect = (eventKey) => {
        setChatMessage({
            From: "",
            To: [eventKey.userId],
            Message: "",
            // Chat_Id: 0,
            IncidentNumber: eventKey.incidentNum,
            IncidentId: eventKey.id,
            chat: [],
            Chat_Id: eventKey.Chat_Id
        });
        setCurrentTab("five");
    };

    const SearchPostRelated = useCallback((item) => {
        if(appNBCContext.dbUserId !== null) {
            if (item == null) {
                item = { id:0}
            }
    
            let _requestBody = {
                Take: item,
                Page: 1,
                UserId: appNBCContext.dbUserId,
                IsSuperAdmin: appNBCContext.isSuperAdmin,
                IsAdmin: appNBCContext.isAdmin
            }
    
            //load data of incident folder
            execute("PUT", protectedEndPointAPI.home.incident.getfolderlist, _requestBody).then((response) => {
                var _data = response.data;
                if(_data !== null) {
                    setListRelated(_data.data);
                    if(_data.data.length > 0 ) {
                        let listpostRelatedMap = [];
                        _data.data.forEach((element) => {
                            if(element.address !== "") {
                                listpostRelatedMap.push({...renderFeedMap(element)});
                            }
                        });
                        setListRelatedMap(listpostRelatedMap);
                    }
                } else {
                    setListRelated([]);
                    setListRelatedMap([])
                }
            });
        }
    },[execute,appNBCContext])

    const ReloadRelated = () => {
        ListFolder();
        GetIncidentsFolders();
    }

    const ListFolder = useCallback(() => {
        if(appNBCContext.dbUserId !== null) {
            let _requestBody = {
                Take: 7,
                Page: 1,
                UserId: appNBCContext.dbUserId,
                IsSuperAdmin: appNBCContext.isSuperAdmin,
                IsAdmin: appNBCContext.isAdmin
            }

            //load data of incident folder
            execute("PUT", protectedEndPointAPI.home.folder.getlist, _requestBody).then((response) => {
                    var _data = response.data;
                    if(_data !== null) setListFolders(_data);
            });
        }
    },[execute,appNBCContext])

    const ListIncidentType = useCallback(() => {
        execute("GET", protectedEndPointAPI.home.incident.type.v2.list)
            .then((response) => {
                var _data = response.data.data;
                
                if(_data !== null) { 
                    if(_data.length > 0) {
                        let sortData = _.sortBy(_data,"label")
                        sortData.unshift(initialIncidentTypeData[0]); //push the "All" at the beginning of the array
                        setListIncidentTypeData(sortData);
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    },[execute])

    const [isParentPagination, setIsParentPagination] = useState(true);
    const [isParentPaginationShown, setIsParentPaginationShown] = useState(true);
    const [isExecuteByCurrentPage, setIsExecuteByCurrentPage] = useState(false);
    const [isExecuteInitialLoad, setIsExecuteInitialLoad] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageData, setPageData] = useState({
        rowData: [],
        isLoading: false,
        totalPages: 0,
        totalData: 0,
    });

    const onCurrentPageChange = useCallback((pageNum) => {
        if(isExecuteInitialLoad === true) {
            setCurrentPage(pageNum);
            setIsExecuteByCurrentPage(true);
        }
    },[isExecuteInitialLoad]);

    const onParentPaginationChange = (val) => {
        setIsParentPagination(val);
    }

    const onOpenCreatePost = useCallback(() => {
        setOpenCreatePost(!openCreatePost);
    },[openCreatePost])

    const renderFeedMap = useCallback((element) => {
        return {
            incidentId: element.id,
            id: element.id,
            key: element.incidentNum,
            imagePath: element.azureIncidentImages !== undefined ? 
                        element.azureIncidentImages.length > 0 ? 
                            element.azureIncidentImages[0].url  : ""
                       : "",
            imageBase64String: appNBCContext.isFileEncryptionEnable ? 
                               element.azureIncidentImages !== undefined ? 
                               element.azureIncidentImages.length > 0 ?
                                element.azureIncidentImages[0].fileBase64String : ""
                                : "" 
                               : "",
            lat: element.addressLatitude, 
            lng: element.addressLongitude,
            canEdit: element.canEdit
          }
    },[appNBCContext])

    const SearchPost = useCallback(() => {
        if(appNBCContext.dbUserId !== null) {
            setLoadingPosts(true);

            let _requestBody = {
                Take: 7,
                Page: currentPage,
                UserId: appNBCContext.dbUserId,
                IsSuperAdmin: appNBCContext.isSuperAdmin,
                IsAdmin: appNBCContext.isAdmin
            }

            //load data of incident
            execute("PUT", protectedEndPointAPI.home.incident.getlistbypage, _requestBody).then((response) => {
                var _data = response.data;
                if(_data !== null) {

                    let listfeed = [];
                    let listfeedMap = [];

                    let listbolos = [];
                    let listbolosMap = [];

                    let listattemptid = [];
                    let listattemptidMap = [];

                    let listofficersafety = [];
                    let listofficersafetyMap = [];

                    const { totalPages, data, totalData } =  _data;

                    setPageData({
                        isLoading: false,
                        rowData: data,
                        totalPages,
                        totalData,
                    });

                    data.forEach((element) => {
                        if (element.incidentCategory.name.toUpperCase() === "BOLOS") {
                            listbolos.push(element);
                            if(element.address !== "") {
                                listbolosMap.push({...renderFeedMap(element)});
                            }
                        }
                           
                        if (element.incidentCategory.name.toUpperCase() === "OFFICER SAFETY") {
                            listofficersafety.push(element);
                            if(element.address !== "") {
                                listofficersafetyMap.push({...renderFeedMap(element)});
                            }
                        }
                           
                        if (element.incidentCategory.name.toUpperCase() === "ATTEMPT TO ID") {
                            listattemptid.push(element);
                            if(element.address !== "") {
                                listattemptidMap.push({...renderFeedMap(element)});
                            }
                        }
                            
                        listfeed.push(element);
                        if(element.address !== "") {
                            listfeedMap.push({...renderFeedMap(element)});
                        }
                    });

                    setListPostFeed(listfeed);
                    setlistPostFeedMap(listfeedMap);

                    setListPostBolos(listbolos);
                    setListPostBolosMap(listbolosMap);

                    setListPostOfficer(listofficersafety);
                    setListPostOfficerMap(listofficersafetyMap);

                    setListPostAttempt(listattemptid);
                    setListPostAttemptMap(listattemptidMap);

                    setLoadingPosts(false);
                    setIsExecuteByCurrentPage(false);
                }
            });
        }
    },[execute,appNBCContext,currentPage])

    const AfterSaveRelateCrime = () => {
        setIsAfterSaveRelateCrime(true);
    }

    const AfterRemoveRelateCrime = () => {
        setIsAfterRemoveRelateCrime(true);
    }

     const loadIncidents = useCallback(() => {
        if(appNBCContext.dbUserId !== null) {
            let _requestBody = {
                Take: 7,
                Page: currentPage,
                UserId: appNBCContext.dbUserId,
                IsSuperAdmin: appNBCContext.isSuperAdmin,
                IsAdmin: appNBCContext.isAdmin
            }

            //load data of incident
            execute("PUT", protectedEndPointAPI.home.incident.getlistbypage, _requestBody).then((response) => {
                var _data = response.data;
                if(_data !== null) {

                    let listfeed = [];
                    let listfeedMap = [];

                    let listbolos = [];
                    let listbolosMap = [];

                    let listattemptid = [];
                    let listattemptidMap = [];

                    let listofficersafety = [];
                    let listofficersafetyMap = [];

                    const { totalPages, data, totalData } =  _data;

                    setPageData({
                        isLoading: false,
                        rowData: data,
                        totalPages,
                        totalData,
                    });

                    data.forEach((element) => {
                        if (element.incidentCategory.name.toUpperCase() === "BOLOS") {
                            listbolos.push(element);
                            if(element.address !== "") {
                                listbolosMap.push({...renderFeedMap(element)});
                            }
                        }
                            
                        if (element.incidentCategory.name.toUpperCase() === "OFFICER SAFETY") {
                            listofficersafety.push(element);
                            if(element.address !== "") {
                                listofficersafetyMap.push({...renderFeedMap(element)});
                            }
                        }
                           
                        if (element.incidentCategory.name.toUpperCase() === "ATTEMPT TO ID") {
                            listattemptid.push(element);
                            if(element.address !== "") {
                                listattemptidMap.push({...renderFeedMap(element)});
                            }
                        }
                            
                        listfeed.push(element);
                        if(element.address !== "") {
                            listfeedMap.push({...renderFeedMap(element)});
                        }
                    });
                    setListPostFeed(listfeed);
                    setlistPostFeedMap(listfeedMap);

                    setListPostBolos(listbolos);
                    setListPostBolosMap(listbolosMap);

                    setListPostOfficer(listofficersafety);
                    setListPostOfficerMap(listofficersafetyMap);

                    setListPostAttempt(listattemptid);
                    setListPostAttemptMap(listattemptidMap);

                    setLoadingPosts(false);
                    setIsExecuteByCurrentPage(false);
                    setIsExecuteInitialLoad(true);
                        
                }
            });

            _requestBody = {
                ..._requestBody,
                FolderId: 0
            }

            //load data of incident folder
            execute("PUT", protectedEndPointAPI.home.incident.getfolderlist, _requestBody).then((response) => {
                var _data = response.data;
                if(_data !== null) {
                    setListRelated(_data.data);
                    if(_data.data.length > 0 ) {
                        let listpostRelatedMap = [];
                        _data.data.forEach((element) => {
                            if(element.address !== "") {
                                listpostRelatedMap.push({...renderFeedMap(element)});
                            }
                        });
                        setListRelatedMap(listpostRelatedMap);
                    }
                }else {
                    setListRelated([]);
                    setListRelatedMap([]);
                }
            });

        }

    },[currentPage,appNBCContext,execute])

     useEffect(() => {
        //load initial data on 1st render
        if(!isInitialLoadDone) {
            loadIncidents();
            SearchMyDepartmentPost();
            ListFolder();
            ListIncidentType();
            setIsInitialLoadDone(true);
        }
      
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadIncidents,isInitialLoadDone]);

    useEffect(() => {
        if(isExecuteByCurrentPage === true) {
            setLoadingPosts(true);
            loadIncidents();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[currentPage,isExecuteByCurrentPage]);

     //Modals
    const [folderModal, setFolderModal] = useState(false);
    const [folderModalData, setFolderModalData] = useState();
    const [relateCrimeModal, setRelateCrimeModal] = useState(false);
    const [relateCrimeData, SetRelateCrimeData] = useState({});
    const [sendNotificationModal, setSendNotificationModal] = useState(false);

    const closeFolders = (cleanData) => {

        setFolderModal(false);

        if (cleanData === undefined) setFolderModalData(undefined);
    }

    const closeRelateCrime = () => {
        setRelateCrimeModal(false);
    }

    const saveRelateCrime = () => {
        setRelateCrimeModal(false);
    }
    const GetIncidentsFolders = () => {
        SearchPostRelated(null);
    }
    const OpenRelateCrime = (item) => {
        SetRelateCrimeData(item);
        setFolderModal(false);
        setRelateCrimeModal(true);
    }

    const OpenFolders = (e, item) => {
        setFolderModal(true);
        setFolderModalData(item);
        e.preventDefault();
       
    }

    const SetNotification = (incident) => {
        setNotificationPost(incident);
        setSendNotificationModal(true);
        getSettingsNotification(incident);
    }

    const closeNotificationModal = () => {
        setSendNotificationModal(false);
    }

    const getSettingsNotification = useCallback((incident) => {
        if(appNBCContext.dbUserId !== null) {
            let _requestBody = {
                Take: 7,
                Page: 1,
                UserId: appNBCContext.dbUserId,
                IsSuperAdmin: appNBCContext.isSuperAdmin,
                IsAdmin: appNBCContext.isAdmin
            }

            //load data of incident notification
            execute("PUT", protectedEndPointAPI.home.incident.notification.getlistbyid, _requestBody).then((response) => {
                var _data = response.data;
                if(_data !== null) {
                    setSmsNotification(_data.sms);
                    setEmailNotification(_data.email);
                } else {
                    setSmsNotification(false);
                    setEmailNotification(false);
                }
            });
        }
    },[execute,appNBCContext])


    const SendDataNotification = useCallback(() => {
        if(appNBCContext.dbUserId !== null) {
            let _requestBody = {
                UserId: appNBCContext.dbUserId,
                IsSuperAdmin: appNBCContext.isSuperAdmin,
                IsAdmin: appNBCContext.isAdmin,
                IncidentId: notificationPost.id,
                IsSMS: smsNotification,
                IsEmail: emailNotification
            }

            //set incident notification
            execute("POST", protectedEndPointAPI.home.incident.notification.set, _requestBody).then((response) => {
                var _data = response.data;
                console.log(_data);
            });

            setSendNotificationModal(false);

        }
    },[execute,appNBCContext,notificationPost,smsNotification,emailNotification])

    const OnsmsNotificationChange = (e) => {
        setSmsNotification(e.target.checked);
    }

    const OnemailNotificationChange = (e) => {
        setEmailNotification(e.target.checked);
    }

    // eslint-disable-next-line no-unused-vars
    const [isLoading, setIsLoading] = useState(true);
    // eslint-disable-next-line no-unused-vars
    const [myPosts, setMyPost] = useState([]);
    const [myPostsMap, setMyPostMap] = useState([]);
    const SearchMyPost = useCallback((x, y, z, draft, published, removed) => {
        if(appNBCContext.dbUserId !== null) {

            let _requestBody = {
                UserId: appNBCContext.dbUserId,
                IsSuperAdmin: appNBCContext.isSuperAdmin,
                IsAdmin: appNBCContext.isAdmin,
                DateStart: (x === undefined) ? "" : x,
                DateEnd: (y === undefined) ? "" : y,
                SearchCriteria: (z === undefined) ? "" : z,
                IsDraft: (draft === undefined) ? true : draft,
                IsPublished: (published === undefined) ? true : published,
                IsRemoved: (removed === undefined) ? true : removed,
            }

            setIsLoading(true);
            //set incident my post
            execute("PUT", protectedEndPointAPI.home.incident.search.mypost, _requestBody).then((response) => {
                var _data = response.data.data;
                if(_data !== null) { 
                    let listfeed = [];
                    let listfeedMap = [];
                    if(_data.length > 0) {
                    
                        _data.forEach((element) => {
                            listfeed.push(element);
                            if(element.address !== "") {
                                listfeedMap.push({...renderFeedMap(element)});
                            }
                        });
                    }
                    setMyPost(listfeed);
                    setMyPostMap(listfeedMap);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
                
            });
        }
    },[execute,appNBCContext])

    useEffect(() => {
        SearchMyPost();
        ListFolder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setMyPost,setListFolders]);

    // my department posts 
    const [myDepartmentPosts, setMyDepartmentPost] = useState([]);
    const [myDepartmentPostsMap, setMyDepartmentPostMap] = useState([]);
    //This will work on initial load of page only, text search functionality will be handle under CardPost component
    const SearchMyDepartmentPost = useCallback((x, y, z, draft, published, removed) => {
        if(appNBCContext.dbUserId !== null) {

            let _requestBody = {
                UserId: appNBCContext.dbUserId,
                IsSuperAdmin: appNBCContext.isSuperAdmin,
                IsAdmin: appNBCContext.isAdmin,
                DateStart: (x === undefined) ? "" : x,
                DateEnd: (y === undefined) ? "" : y,
                SearchCriteria: (z === undefined) ? "" : z,
                IsDraft: (draft === undefined) ? true : draft,
                IsPublished: (published === undefined) ? true : published,
                IsRemoved: (removed === undefined) ? true : removed,
            }

            setIsLoading(true);
            //set incident post by department
            execute("PUT", protectedEndPointAPI.home.incident.search.mydepartment, _requestBody).then((response) => {
                var _data = response.data.data;
                if(_data !== null) { 
                    let listMyDepartment = [];
                    let listMyDepartmentMap = [];
                    if(_data.length > 0) {
                        _data.forEach((element) => {
                            listMyDepartment.push(element);
                            if(element.address !== "") {
                                listMyDepartmentMap.push({...renderFeedMap(element)});
                            }
                        });
                    }
                  
                    setMyDepartmentPost(listMyDepartment);
                    setMyDepartmentPostMap(listMyDepartmentMap);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
                
            });
        }
    },[execute,appNBCContext])

    const onNavItemSelectChange = useCallback((tab) => {
        if(isParentPagination === true) {
            setIsParentPaginationShown(tab === "first");
        }
    },[isParentPagination]);

    // useEffect(() => {
    //     SearchMyDepartmentPost();
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [setMyDepartmentPost, setIsLoading]);

    useEffect(() => {
        if(isAfterCreatedPost) {
            SearchMyDepartmentPost();
            SearchMyPost();
            setIsAfterCreatedPost(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAfterCreatedPost, setIsLoading]);

    useEffect(() => {
        if(isAfterDelete === true) {
            loadIncidents();
            SearchMyPost();
            SearchMyDepartmentPost();
            setIsAfterDelete(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAfterDelete]);

    useEffect(() => {
        if(isAfterSaveRelateCrime) {
            loadIncidents();
            SearchMyDepartmentPost();
            SearchMyPost();
            setIsAfterSaveRelateCrime(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAfterSaveRelateCrime]);

    useEffect(() => {
        if(isAfterRemoveRelateCrime) {
            loadIncidents();
            SearchMyDepartmentPost();
            SearchMyPost();
            setIsAfterRemoveRelateCrime(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAfterRemoveRelateCrime]);


    return (
        <>

            <Tab.Container onSelect={(key) => setCurrentTab(key)} defaultActiveKey="first" activeKey={currentTab}>
                <Row>
                    <Col lg={12}>
                        <h3 className='legendpost' >Live Feed</h3>
                    </Col>
                </Row>
                <Row className="elementBorderBottom">
                    <Col className="colWithOutMPadding">
                        <Nav variant="pills" className="flex-column tabNavItem" onSelect={onNavItemSelectChange}>
                            <Nav.Item>
                                <Nav.Link eventKey="first">All Posts</Nav.Link>
                            </Nav.Item>
                            {
                                (appNBCContext.dbUserId !== null) ? 
                                    ( (appNBCContext.isSuperAdmin) ? (<></>) 
                                        : 
                                        (   <Nav.Item>
                                                <Nav.Link eventKey="myPost">My Posts</Nav.Link>
                                            </Nav.Item>
                                        )
                                    ) 
                                    :
                                    (<></>)
                            }
                            {
                                (appNBCContext.dbUserId !== null) ? 
                                    ( (appNBCContext.isSuperAdmin) ? (<></>) 
                                        : 
                                        (   <Nav.Item>
                                                <Nav.Link eventKey="myDepartment">My Department's Posts</Nav.Link>
                                            </Nav.Item>
                                        )
                                    ) 
                                    :
                                    (<></>)
                            }
                            <Nav.Item>
                                <Nav.Link eventKey="second">BOLOS</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="third">Attempt to ID</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="fourth">Officer Safety</Nav.Link>
                            </Nav.Item>
                            <Nav.Item >
                                <Nav.Link eventKey="related">Saved Posts</Nav.Link>
                            </Nav.Item>
                            <Nav.Item >
                                <Nav.Link eventKey="incidentMapView">Map View</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    <Col lg={2} className="colWithOutMPadding" >
                        {
                            (appNBCContext.dbUserId !== null) ? 
                                ( (!appNBCContext.isSuperAdmin) ? (
                                    <button className='btn btn-primary btncreate' onClick={() => {setOpenCreatePost(!openCreatePost);}}>Create Post</button>) 
                                    : 
                                    (<></>)
                                ) 
                                :
                                (<></>)
                        }
                    </Col>
                   
                </Row>
              <br/>
                <Row>
                    
                    <Col sm={12}>
                        <Tab.Content >
                            <Tab.Pane eventKey="first">
                            <div className="container" id="firstDiv">
                                    <AzurePost 
                                        AfterRemoveRelateCrime={AfterRemoveRelateCrime} 
                                        DeleteIncident={toggleDelete} 
                                        IsAfterDelete={isAfterDelete} 
                                        Key={"All"} 
                                        listagenciesid={agenciesuser} 
                                        listpost={listPostFeed} 
                                        listpostMap={listPostFeedMap} 
                                        listIncidentTypeData={listIncidentTypeData}
                                        LoadIncidents={loadIncidents} 
                                        loadingPosts={loadingPosts} 
                                        onOpenCreatePost={onOpenCreatePost} 
                                        OpenFolders={OpenFolders} 
                                        openMessage={handleSelect} 
                                        reloadData={SearchPost} 
                                        setIsParentPagination={onParentPaginationChange} 
                                        setLoadingPosts={setLoadingPosts} 
                                        SetNotification={SetNotification} 
                                    ></AzurePost>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                <div className="container" id="secondDiv">
                                    <AzurePost 
                                        AfterRemoveRelateCrime={AfterRemoveRelateCrime} 
                                        DeleteIncident={toggleDelete} 
                                        IsAfterDelete={isAfterDelete} 
                                        Key={"Bolos"} 
                                        listagenciesid={agenciesuser} 
                                        listpost={listPostBolos} 
                                        listpostMap={listPostBolosMap}
                                        listIncidentTypeData={listIncidentTypeData}
                                        LoadIncidents={loadIncidents} 
                                        loadingPosts={loadingPosts} 
                                        onOpenCreatePost={onOpenCreatePost} 
                                        OpenFolders={OpenFolders} 
                                        openMessage={handleSelect} 
                                        reloadData={SearchPost} 
                                        setIsParentPagination={onParentPaginationChange} 
                                        setLoadingPosts={setLoadingPosts} 
                                        SetNotification={SetNotification} 
                                    ></AzurePost>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="third">
                                <div className="container" id="thirdDiv">
                                    <AzurePost 
                                        AfterRemoveRelateCrime={AfterRemoveRelateCrime} 
                                        DeleteIncident={toggleDelete} 
                                        IsAfterDelete={isAfterDelete} 
                                        Key={"AttempttoId"} 
                                        listagenciesid={agenciesuser} 
                                        listfolders={listfolders} 
                                        listpost={listPostAttempt} 
                                        listpostMap={listPostAttemptMap}
                                        listIncidentTypeData={listIncidentTypeData}
                                        LoadIncidents={loadIncidents} 
                                        loadingPosts={loadingPosts} 
                                        onOpenCreatePost={onOpenCreatePost} 
                                        OpenFolders={OpenFolders} 
                                        openMessage={handleSelect} 
                                        reloadData={SearchPost} 
                                        setIsParentPagination={onParentPaginationChange} 
                                        setLoadingPosts={setLoadingPosts} 
                                        SetNotification={SetNotification} 
                                    ></AzurePost>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="fourth">
                                <div className="container" id="fourthDiv">
                                    <AzurePost 
                                        AfterRemoveRelateCrime={AfterRemoveRelateCrime} 
                                        DeleteIncident={toggleDelete} 
                                        IsAfterDelete={isAfterDelete} 
                                        Key={"OfficerSafety"} 
                                        listagenciesid={agenciesuser} 
                                        listfolders={listfolders} 
                                        listpost={listPostOfficer} 
                                        listpostMap={listPostOfficerMap}
                                        listIncidentTypeData={listIncidentTypeData}
                                        LoadIncidents={loadIncidents} 
                                        loadingPosts={loadingPosts} 
                                        onOpenCreatePost={onOpenCreatePost} 
                                        OpenFolders={OpenFolders} 
                                        openMessage={handleSelect} 
                                        reloadData={SearchPost} 
                                        setIsParentPagination={onParentPaginationChange} 
                                        setLoadingPosts={setLoadingPosts} 
                                        SetNotification={SetNotification} 
                                    ></AzurePost>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="myPost">
                                <div className="container" id="myPostDiv">
                                    <AzurePost 
                                        AfterRemoveRelateCrime={AfterRemoveRelateCrime} 
                                        DeleteIncident={toggleDelete} 
                                        IsAfterDelete={isAfterDelete} 
                                        Key={"MyPost"} 
                                        listagenciesid={agenciesuser} 
                                        listfolders={listfolders} 
                                        listpost={myPosts} 
                                        listpostMap={myPostsMap}
                                        listIncidentTypeData={listIncidentTypeData}
                                        LoadIncidents={SearchMyPost} 
                                        loadingPosts={loadingPosts} 
                                        myPosts={true} 
                                        onOpenCreatePost={onOpenCreatePost} 
                                        OpenFolders={OpenFolders} 
                                        openMessage={handleSelect} 
                                        reloadData={SearchPost} 
                                        setIsParentPagination={onParentPaginationChange} 
                                        setLoadingPosts={setLoadingPosts} 
                                        SetNotification={SetNotification} 
                                    ></AzurePost>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="related" >
                                <div className="container" id="relatedDiv">
                                    <AzurePost 
                                        AfterRemoveRelateCrime={AfterRemoveRelateCrime} 
                                        DeleteIncident={toggleDelete} 
                                        IsAfterDelete={isAfterDelete} 
                                        Key={"related"} 
                                        keytype='related' 
                                        listagenciesid={agenciesuser} 
                                        listfolders={listfolders} 
                                        listpost={listPostRelated} 
                                        listpostMap={listPostRelatedMap}
                                        listIncidentTypeData={listIncidentTypeData}
                                        LoadIncidents={loadIncidents} 
                                        loadingPosts={loadingPosts} 
                                        onOpenCreatePost={onOpenCreatePost} 
                                        OpenFolders={OpenFolders} 
                                        openMessage={handleSelect} 
                                        reloadData={SearchPostRelated} 
                                        SearchPostRelated={SearchPostRelated} 
                                        setIsParentPagination={onParentPaginationChange} 
                                        setLoadingPosts={setLoadingPosts} 
                                        SetNotification={SetNotification} 
                                    ></AzurePost>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="myDepartment">
                                <div className="container" id="myDepartmentDiv">
                                    <AzurePost 
                                        AfterRemoveRelateCrime={AfterRemoveRelateCrime} 
                                        DeleteIncident={toggleDelete} 
                                        IsAfterDelete={isAfterDelete} 
                                        Key={"myDepartment"} 
                                        listagenciesid={agenciesuser} 
                                        listfolders={listfolders} 
                                        listpost={myDepartmentPosts} 
                                        listpostMap={myDepartmentPostsMap}
                                        listIncidentTypeData={listIncidentTypeData}
                                        LoadIncidents={SearchMyDepartmentPost} 
                                        loadingPosts={loadingPosts} 
                                        onOpenCreatePost={onOpenCreatePost} 
                                        OpenFolders={OpenFolders} 
                                        openMessage={handleSelect} 
                                        reloadData={SearchMyDepartmentPost} 
                                        setIsParentPagination={onParentPaginationChange} 
                                        setLoadingPosts={setLoadingPosts} 
                                        SetNotification={SetNotification} 
                                    ></AzurePost>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="incidentMapView">
                                <div className="container" id="incidentMapViewDiv">
                                    <AzureIncidentMapView 
                                        initialListData={listPostFeedMap}
                                    ></AzureIncidentMapView>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="five">
                                <div className="container">
                                    {/**Commented Temp**/}
                                    {/* <ChatPanel ChatMessageItem={chatMessage} /> */}
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>

                { isParentPagination === true ? 
                    (<Row>
                        <div className={isParentPaginationShown ? "" : "hidden"} >
                            <Pagination
                                totalRows={pageData.totalData}
                                pageChangeHandler={onCurrentPageChange}
                                rowsPerPage={7}
                            />
                        </div>
                    </Row>) : (<></>)
                }
              <br/>
             
            </Tab.Container>
            <Modal size="lg" isOpen={modalDelete.show}>
                <ModalHeader>
                    Delete Post
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <label >Are you sure you want to delete this post?</label>
                    </Row>

                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => DeletePost(modalDelete.id)}
                    >
                        Delete
                    </Button>
                    {' '}
                    <Button onClick={toggleDelete}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
            
             <AzureBookmarkModal ReloadRelated={ReloadRelated}  LoadIncidents={loadIncidents} AfterSaveRelateCrime={AfterSaveRelateCrime} OpenRelateCrime={OpenRelateCrime} closeFolders={closeFolders} show={folderModal} datamodel={folderModalData} />
            {/* ** Commented this since it is not being used
            <AddRelateCrimeModal ReloadRelated={ReloadRelated} save={saveRelateCrime} closeRelateCrime={closeRelateCrime} show={relateCrimeModal} data={{ data: relateCrimeData === undefined ? { id: 0 } : relateCrimeData, post: folderModalData }} />
             */}
            <Modal size="xl" isOpen={openCreatePost}>
                <AzurePostModal setOpenCreatePost={setOpenCreatePost} reloadData={SearchPost} setIsAfterCreatedPost={setIsAfterCreatedPost}/>
            </Modal>

            <Modal size="mb" isOpen={sendNotificationModal}>
                <ModalHeader toggle={closeNotificationModal} cssModule={{ 'modal-title': 'w-100 text-center' }}>

                            Get Notified

                </ModalHeader>
                <ModalBody>
                    <center>
                        <label>Get notified when the post is updated</label>
                        <hr/>
                        <br/>
                        <div className="row">
                            <div className="col-2 toggle-switch">
                                <div className="toggle-switch">
                                    <input id="email" type="checkbox" onChange={OnemailNotificationChange} checked={emailNotification} className="toggle-switch-1" />
                                    <label for="email"></label>
                                </div>
                            </div>
                            <div className="col-6">
                                <label className="labelText">Notify me by email</label>
                            </div>
                        </div>
                        <br/>
                        <div className="row">
                            <div className="col-2 toggle-switch">
                                <div className="toggle-switch">
                                    <input id="sms" type="checkbox" onChange={OnsmsNotificationChange} checked={smsNotification} className="toggle-switch-1" />
                                    <label for="sms"></label>
                                </div>
                            </div>
                            <div className="col-6">
                                <label className="labelText">Notify me by SMS</label>
                            </div>
                        </div>
                    </center>
                    <Row>
                    </Row>
                </ModalBody>
                <ModalFooter className="footerBlock" cssModule={{ 'modal-title': 'w-100 text-rigth' }}>

                    <button onClick={SendDataNotification} className="btn btn-primary">Ok</button>
                </ModalFooter>
            </Modal>
            <NotificationContainer />
        </>
    );
}


export default AzureHome;

