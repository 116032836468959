import React, {useEffect, useState, useCallback,useRef} from "react";
import Avatar from 'react-avatar';
import { Button } from "reactstrap";
import { Row, Col} from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Pagination from "../../../components/Common/pagination";
import Table from "../../../components/Common/table";

import {
    NoResultFoundContainer
} from "../../../components/Department/Department.Style";


import { UsedAppNBCContext } from "../../AppNBC.Provider";
import useFetchWithMsal from "../../hooks/useFetchWithMsal";
import { protectedEndPointAPI } from "../../Helpers/ProtectedCustomClass";

const AzureManageAdmins = (props) => {
    
    const appNBCContext = UsedAppNBCContext();
    // eslint-disable-next-line no-unused-vars
    const { error, execute } = useFetchWithMsal();

    const [selectedAdmins, setSelectedAdmins] = useState([]);
    const [loadingAdmins, setLoadingAdmins] = useState(false);

    function sleep(delay = 0) {
        return new Promise((resolve) => {
            setTimeout(resolve, delay);
        });
    }

    const getData = useCallback(async (pageNo = 1) => {
        if(appNBCContext.dbUserId !== null) {
            let _requestBody = {
                UserId: appNBCContext.dbUserId,
                IsSuperAdmin: appNBCContext.isSuperAdmin,
                IsAdmin: appNBCContext.isAdmin,
                Take: 15,
                Page: pageNo,
                AgencyId: props.Agency,
                IsAll: false
            }

            return execute("PUT", protectedEndPointAPI.agencies.user.getlist, _requestBody);
        }

    },[execute, appNBCContext,props.Agency])

    const manageAdmin = useCallback(async (userAgency) => {
        if(appNBCContext.dbUserId !== null) {
            let _requestBody = {
                UserId: appNBCContext.dbUserId,
                IsSuperAdmin: appNBCContext.isSuperAdmin,
                IsAdmin: appNBCContext.isAdmin,
                AgencyId: userAgency,
            }

            execute("POST", protectedEndPointAPI.agencies.user.update, _requestBody).then((response) => {
                var _data = response.data;
                if(_data !== null)  loadData();
            });
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[execute, appNBCContext]);

    const columns = [
        {
            Header: "",
            accessor: "UserName",
            Cell: cellInfo => <>
                <Row>
                    <Col lg={3}>
                        {cellInfo.data[cellInfo.row.index].path && <img className="picPreview" src={cellInfo.data[cellInfo.row.index].path} alt="" />}

                        {(cellInfo.data[cellInfo.row.index].path === undefined || cellInfo.data[cellInfo.row.index].path === null ||  cellInfo.data[cellInfo.row.index].path === "") && <Avatar className="sb-avatar--textProfile" size="40" round="50%" name={cellInfo.data[cellInfo.row.index].userName}></Avatar>}
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                <label>
                                    {cellInfo.data[cellInfo.row.index].userName}
                                 </label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {cellInfo.data[cellInfo.row.index].division}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                </>
        },
        {
            Header: "",
            accessor: "isAdmin",
            Cell: cellInfo =>
                <>
                    {cellInfo.data[cellInfo.row.index].userId !== appNBCContext.dbUserId ? 
                        <div className="elementRight">
                            <Button onClick={() => { manageAdmin(cellInfo.data[cellInfo.row.index]) }} className="adminBtn">
                                Admin
                                <FontAwesomeIcon icon="fa-solid fa-xmark" />
                            </Button>
                        </div>
                    : 
                    <></>
                    }
                </>
        }
    ];

    const [pageData, setPageData] = useState({
        rowData: [],
        isLoading: false,
        totalPages: 0,
        totalData: 0,
    });

    const [currentPage, setCurrentPage] = useState(1);

    const loadData = () => {
        setPageData((prevState) => ({
            ...prevState,
            rowData: [],
            isLoading: true,
        }));
        getData(currentPage).then((info) => {
            const { totalPages, data, totalData } = info.data;
            setPageData({
                isLoading: false,
                rowData: data,
                totalPages,
                totalData,
            });
        });
    }

    useEffect(() => {
        loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const loading = open && options.length === 0;

    useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            await sleep(1e3); // For demo purposes.

            if (active)
            {
                if(appNBCContext.dbUserId !== null) {
                    let _requestBody = {
                        UserId: appNBCContext.dbUserId,
                        IsSuperAdmin: appNBCContext.isSuperAdmin,
                        IsAdmin: appNBCContext.isAdmin,
                        Take: 15,
                        Page: 1,
                        AgencyId: props.Agency,
                        IsAll: true
                    }
        
                    execute("PUT", protectedEndPointAPI.agencies.user.getlist, _requestBody).then((response) => {
                        var _data = response.data;
                        if(_data !== null) {
                            setOptions(_data.data);
                        }
                    });
                }
            }
        })();

        return () => {
            active = false;
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, setOptions]);

    const handleChange = (event, value) =>
    {
        setSelectedAdmins(value);
    }

    const autoC = useRef(null);

    const asignAdmins = useCallback(async () => {
        if(appNBCContext.dbUserId !== null) {
            let _requestBody = {
                UserId: appNBCContext.dbUserId,
                IsSuperAdmin: appNBCContext.isSuperAdmin,
                IsAdmin: appNBCContext.isAdmin,
                AgencyId: props.Agency,
                UserList: selectedAdmins
            }

            execute("POST", protectedEndPointAPI.agencies.user.updatelist, _requestBody)
            .then((response) => {
                //var _data = response.data;
                loadData();
                setLoadingAdmins(false);
                const ele = autoC.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
                if (ele) ele.click();
            });
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[execute, appNBCContext,selectedAdmins,props.Agency]);

    return <>
        <Row>
            <Col>
                <Autocomplete
                    disablePortal
                    ref={autoC}
                    multiple
                    id="asynchronous-demo"
                    open={open}
                    value={selectedAdmins}
                    onChange={handleChange}
                    onOpen={() => {
                        setOpen(true);
                    }}
                    onClose={() => {
                        setOpen(false);
                    }}
                    isOptionEqualToValue={
                        (option, value) =>
                            option.userId === value.userId
                    }
                    getOptionLabel={(option) => option.userName}
                    options={options}
                    loading={loading}
                    renderOption={(props, option) => (
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>

                            <Row>
                                <Col lg={4}>
                                    {option.path && <img className="picPreview" src={option.path} alt="" />}
                                    {(option.path === undefined || option.path === null || option.path === "") && <Avatar className="sb-avatar--textProfile" size="40" round="50%" name={option.userName}></Avatar>}
                                    
                                </Col>
                                <Col>
                                    <Row>
                                        <Col>
                                            <label>
                                                {option.userName}
                                            </label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {option.division}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Box>
                    )}
                    renderInput={(params) => (
                        <>
                            <TextField
                                {...params}
                                label="Search user..."
                                value={selectedAdmins}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        </>
                    )}
                />
            </Col>
            <Col lg={3}>
                <button disable={loadingAdmins} type="button" onClick={asignAdmins} className="btn btnAsign">
                    {loadingAdmins && (
                        <span className="spinner-border spinner-border-sm"></span>
                    )}
                    Assign

                </button>
            </Col>
        </Row>
        
        {!pageData.isLoading ? 
        (<>
           {
            pageData.rowData.length > 0 
            ? (<>
                <div id="tableWithoutHead">
                    <Table

                        columns={columns}
                        data={pageData.rowData}
                        isLoading={pageData.isLoading}
                    />
                </div>
                <Pagination
                    totalRows={pageData.totalData}
                    pageChangeHandler={setCurrentPage}
                    rowsPerPage={15}
                />
            </>) 
            : (<NoResultFoundContainer message={"No results found..."} />)
           }
        </>) 
        : 
        (<></>)
        }
    </>




}

export default AzureManageAdmins;