import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { Row, Col, Form } from 'reactstrap';
import Avatar from 'react-avatar';
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import "./AzureChatPanel.css";
import calendar from "../../../assets/calendar_full.png";
import locationpng from "../../../assets/location_full.png";
import nopreview from "../../../assets/nopreviewv3.png";
import addresspng from "../../../assets/office-on.png";
import phoneIcon from "../../../assets/Phone.svg";
import emailIcon from "../../../assets/Email.svg";
import type from "../../../assets/type_full.png";
import AzureChatWindow from "./AzureChatWindow";
import AzureChatList from "./AzureChatList";
import {LoaderUserChat,LoaderChatPanel,AnyMessageState} from '../../../components/Chat/StatePage';
import AzureChatNewModal from "./AzureChatNewModal";
import StatusBadge from '../../../components/Officer/StatusBadge';
import { UsedAppNBCContext } from '../../AppNBC.Provider';
import useFetchWithMsal from '../../hooks/useFetchWithMsal';
import { protectedEndPointAPI, protectedVariable } from '../../Helpers/ProtectedCustomClass';
import { StyledBadge } from "./AzureChatPanel.Style";
import AzureChatToolBar from './AzureChatToolBar';

const AzureChatPanel = (props) => {
    const appNBCContext = UsedAppNBCContext();
    // eslint-disable-next-line no-unused-vars
    const { error, execute } = useFetchWithMsal();
    let history = useHistory();

    const [isInitialLoadDone, setIsInitialLoadDone] = useState(false);
    //New Logic 
    const { ChatMessageItem } = props;
    const [ChatMessageItemId, setChatMessageItemId] = useState(0);
    const [userschat, setUsersChats] = useState([]);
    const [newchatselected, newsetChatSelected] = useState({});
    const [boardchatselected, setBoardChat] = useState({});
    const [userschatincident, setUsersChatIncident] = useState([]);
    const [newMessageKey, setNewMessageKey] = useState(0);
    if (ChatMessageItem !== undefined && ChatMessageItem.Chat_Id !== ChatMessageItemId) {
        setChatMessageItemId(0);
        ChatMessageItem.Chat_Id = 0;
    }
    //End logic
    const [isLoadingChatPanel, setIsLoadingChatPanel] = useState(true);
    // eslint-disable-next-line no-unused-vars
    const [currentChatId, setcurrentChatId] = useState();
    const chatSelected = useRef(() => undefined);
    const [chatList, setChatList] = useState([]);
    const [filter, setFilter] = useState("");
    const [isloadinguserchat, setIsLoadingUserChat] = useState(true);

    const [currentChat, setCurrentChat] = useState(history.location === undefined ? undefined : history.location.state);
    // eslint-disable-next-line no-unused-vars
    const [disableSend, setEnableSent] = useState(props.ChatMessageItem === undefined ? true : false);
    // eslint-disable-next-line no-unused-vars
    const [currentSend, setcurrentSend] = useState(props.location === undefined);
    const [showNewChat, setshowNewChat] = useState(false);
    const [isSelectNewChatPreview, setIsSelectNewChatPreview] = useState(false);


    const selectChat = useCallback(async (chatItem) => {
        if(appNBCContext.dbUserId !== null) {
            if (newchatselected === undefined || newchatselected === null  ) {
                newsetChatSelected(chatItem);
            }

            if (chatItem && chatItem.id) {
                let _requestBody = {
                    UserId: appNBCContext.dbUserId,
                    IsSuperAdmin: appNBCContext.isSuperAdmin,
                    IsAdmin: appNBCContext.isAdmin,
                    ChatId: chatItem.id
                }
                execute("PUT", protectedEndPointAPI.message.chat.getmessages, _requestBody).then((response) => {
                    var _data = response.data;
                    if(_data !== null) { 
                        setChatList(_data);
                    }
                });
            }
        }
    },[execute,appNBCContext,newchatselected]);

    const InitChatList = () => {
        setBoardChat({});
        newsetChatSelected({});
        setChatList([]);
    }

    const SelectNewChatPreview = (item) => {
        if (item && item.id >0) {
            setIsLoadingChatPanel(true);
            
            if (item.typechat === "user") {
                newsetChatSelected(item);
                selectChat(item);

            }
            setIsSelectNewChatPreview(true);
            setBoardChat(item);
            setCurrentChat(undefined);
            if (item.typechat === "incident") {
                setChatList([]);
                GetUsersIncident(item.incident);

            }
        }
       
        setIsLoadingChatPanel(false);
    }

    const SelectNewChatPreviewIncident = (item) => {
        setChatList([]);
        setIsLoadingChatPanel(true);
        if (item.typechat === "user") {
            var newitem = item;
            newsetChatSelected(newitem);
            selectChat(newitem);
            
        }
        setIsLoadingChatPanel(false);
       
    }

    const reviewAndSetUserChat = (data, chatuserId, incidentId) => {
        setIsLoadingChatPanel(true);
        var selectedchat = data.filter(({ userId, incident }) => userId === chatuserId && (incident === undefined || incident === null || incident ===0 ) );

        if (selectedchat !== undefined && selectedchat.length > 0 && (incidentId === null || incidentId === undefined || incidentId === "")) {

            newsetChatSelected(selectedchat[0]);
            selectChat(selectedchat[0]);

        }
        setIsLoadingChatPanel(false);
        return selectedchat;
    }

    const reviewAndSetIncidentChat = (data, incidentId) => {
        setIsLoadingChatPanel(true);
        var selectedIncident = data.filter(({ incident }) => incident === incidentId);

        if (selectedIncident !== undefined && selectedIncident.length > 0) {

            newsetChatSelected(selectedIncident[0]);
            selectChat(selectedIncident[0]);

        }
        setIsLoadingChatPanel(false);
        return selectedIncident;
    }

    const getChats = useCallback(async (incident, itemtoselect) => {
        if(appNBCContext.dbUserId !== null) {
            let loc = history.location === undefined ? undefined : history.location.state;
            const queryParams = new URLSearchParams(history.location.search);
            const chatuserId = queryParams.get('userId');
            const incidentChat = queryParams.get('incident');
            const chatMode = queryParams.get('mode');
            const incidentNum = loc !== undefined ? loc.incidentNum : "";
    
            const userFullName = loc !== undefined ? loc.userFullName : "-" ;
            const userEmail = loc !== undefined ? loc.email : "-" ;
            const userPhone = loc !== undefined ? loc.phone: "-" ;
            const userAddress = loc !== undefined ? loc.userAddress: "-" ;
            const isSharePost = loc !== undefined ? loc.isSharePost : false ;

            let _requestBody = {
                UserId: appNBCContext.dbUserId,
                IsSuperAdmin: appNBCContext.isSuperAdmin,
                IsAdmin: appNBCContext.isAdmin,
            }

            if ((incident === undefined || incident === null || incident === 0) ) {
                _requestBody = {
                    ..._requestBody
                    , Filter: filter
                }

                execute("PUT", protectedEndPointAPI.message.chat.list, _requestBody).then((response) => {
                    var _data = response.data;
                    if(_data !== null) { 
                        let itemToSelect = {};

                        if (itemtoselect !== undefined && itemtoselect.name !== null) {
                            itemToSelect = _data.filter((item) => {
                                return item.id === itemtoselect.id;
                            });

                            if (itemToSelect.length > 0) {
                                newsetChatSelected(itemToSelect[0]);
                            }

                        }
                        else if (incidentChat !== undefined && incidentChat !== null) {
                            if(chatMode !== undefined || chatMode !== null) {
                                if(chatMode === "share") {
                                    itemToSelect = _data.filter((item) => {
                                        return item.incident === parseInt(incidentChat) && item.chatToUserId === chatuserId;
                                    });
                                }
                            } else {
                                itemToSelect = _data.filter((item) => {
                                    return item.incident === parseInt(incidentChat);
                                });
                            }
                            if (itemToSelect.length > 0) {
                                if(isSharePost) {
                                    itemToSelect[0].userFullName =  userFullName;
                                    itemToSelect[0].userEmail =  userEmail;
                                    itemToSelect[0].userPhone =  userPhone;
                                    itemToSelect[0].userAddress =  userAddress;
                                    itemToSelect[0].isSharePost =  isSharePost;
                                }
                                
                                newsetChatSelected(itemToSelect[0]);
                            }
                            
                        }

                        if (_data !== undefined && _data.length >= 0 
                            && chatuserId !== undefined && chatuserId !== "" && chatuserId !== null) {
                            var anyUser = reviewAndSetUserChat(_data, chatuserId, incidentChat);
                            var anyIncident = reviewAndSetIncidentChat(_data, (incidentChat === undefined || incidentChat === null) ? "0" : incidentChat);
                            var typeChat = queryParams.get('typechat');
                            var item = {
                                to: [chatuserId],
                                id: 0,
                                name: typeChat === "user" ? queryParams.get('name') : incidentChat,
                                img: loc.urlPic && loc.urlPic !== null ? loc.urlPic : "",
                                typechat: typeChat,
                                type: queryParams.get('type'),
                                address: queryParams.get('address'),
                                incidentNum: incidentNum,
                                incidentId: (incidentChat === undefined || incidentChat === null) ? 0 : incidentChat,
                                userFullName: userFullName,
                                userEmail: userEmail,
                                userPhone: userPhone,
                                userAddress: userAddress,
                                isSharePost: isSharePost
                            };
        
                            if ((anyUser === undefined || anyUser === null || anyUser.length === 0)
                                || ((incidentChat !== "" && incidentChat !== null && incidentChat !== undefined) &&
                                    (anyIncident === undefined || anyIncident === null || anyIncident.length === 0))) {
                                newsetChatSelected(item);
                            }
        
                            if (
                                (itemToSelect === undefined || itemToSelect === null || Object.keys(itemToSelect).length === 0)
                                && (anyUser === undefined || anyUser === null || anyUser.length === 0)
                            ) {
                                _data.unshift(item);
        
                            }
                        }

                        if (itemToSelect && Object.keys(itemToSelect).length > 0) {
                            newsetChatSelected(itemToSelect[0]);
                        }

                        if (queryParams.has("userId")) {
                            queryParams.delete('userId');
                            queryParams.delete('name');
                            queryParams.delete('urlPic');
                            queryParams.delete('typechat');
                            queryParams.delete('type');
                            queryParams.delete('incident');
                            queryParams.delete('address');
                            history.replace({
                                search: queryParams.toString(),
                            })
                        }

                        if(_data.length > 0) {
                            // var sortedChats = _data.map(obj => { return { ...obj, chatDate: formatDate(obj.dateincident) } })
                            // .sort((a, b) => (Number(b.chatDate) - Number(a.chatDate)));
        
                            var sortedChats = _data.map(obj => { return { ...obj, chatDate: obj.dateincident } })
                            .reverse((a, b) => (Number(a.chatDate) - Number(b.chatDate)));
        
                            let tempChats = sortedChats;
                            let distinctChats = [];
        
                            let entityPrevious;
                          
                            for (var index = 0; index < tempChats.length; index++) {
                                let entityItem = tempChats[index];
                                if(index === 0 ) {
                                    entityPrevious = entityItem;
                                    distinctChats.push(entityItem);
                                } else {
                                if(entityItem.address === entityPrevious.address
                                   && entityItem.chatToUserId === entityPrevious.chatToUserId 
                                   && entityItem.incident === entityPrevious.incident 
                                   && entityItem.isSharePost === entityPrevious.isSharePost 
                                   && entityItem.type === entityPrevious.type 
                                   && entityItem.typechat === entityPrevious.typechat) {
                                        // nothing happened here
                                    } else {
                                        distinctChats.push(entityItem);
                                    }
                                }
                                entityPrevious = entityItem
                             }
        
                            //setUsersChats(sortedChats); //original
                            setUsersChats(distinctChats);
                        } else {
                            setUsersChats(_data);
                        }

                        setIsLoadingUserChat(false);
                        setIsLoadingChatPanel(false);

                        if (Object.keys(itemToSelect).length > 0) {
                            selectChat(itemToSelect[0]);
                        }
                   
                    }
                });
            } else {
                _requestBody = {
                    ..._requestBody
                    , Filter: filter
                    , IncidentId: (incident !== undefined && incident !== null) ? incident : parseInt(incidentChat)
                }

                execute("PUT", protectedEndPointAPI.message.chat.getgroupchat, _requestBody).then((response) => {
                    var _data = response.data;
                    if(_data !== null) { 
                        setUsersChatIncident(_data);

                        if (itemtoselect !== undefined && itemtoselect.name !== null) {

                            let itemToSelect = _data.filter((item) => {
                                return item.id === itemtoselect.id;
                            });
        
                            if (itemToSelect.length > 0) {
                                var _newItemToSelect = {...itemToSelect[0]
                                    , img: itemToSelect[0].img === null ? currentChat.urlPic !== undefined  ? currentChat.urlPic : "" : "" 
                                    , type: itemToSelect[0].type === null ? currentChat.type !== undefined  ? currentChat.type : "" : "" 
                                };
                                newsetChatSelected(_newItemToSelect);
                                selectChat(_newItemToSelect);
                            }
                        }
                    }
                });


            }



        }
    },[execute,appNBCContext,currentChat,filter])

    const GetUsersIncident = (id) => {
        getChats(id);
    }

    chatSelected.current = currentChatId;
    useEffect(() => {
        getChats();
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setUsersChats])

    const sendMessage = useCallback(async (user, message) => {
        if(appNBCContext.dbUserId !== null) {
            
            var chatId = ChatMessageItemId;
            setcurrentSend(chatId === undefined);
            var ramdonVal = Math.trunc(Math.random() * (10000 - 1 + 1));

            let _requestBody = {
                UserId: appNBCContext.dbUserId,
                IsSuperAdmin: appNBCContext.isSuperAdmin,
                IsAdmin: appNBCContext.isAdmin,
                OriginalViewModel: {
                    Message: message,
                    To: newchatselected.to === undefined ? [] : newchatselected.to,
                    From_Id: "",
                    Subject: "",
                    IncidentNumber: "",
                    Chat_Id: newchatselected.id,
                    IncidentId: parseInt(newchatselected.incidentId),
                    NewMessageKey: (newchatselected.id === undefined || newchatselected.id === null || newchatselected.id === 0 ? ramdonVal :0),
                    isSharePost: newchatselected.isSharePost === undefined ? false : newchatselected.isSharePost,
                }
            };

            setNewMessageKey(ramdonVal);

            execute("PUT", protectedEndPointAPI.message.chat.sendmessage, _requestBody)
                .then((response) => {
                var _data = response.data;
                if(_data !== null) { 
                    getChats();
                }
            });
        }

    },[execute,appNBCContext,newchatselected,ChatMessageItemId,getChats]);

    const sendMessageV2 = useCallback(async (user, message) => {
        if(appNBCContext.dbUserId !== null) {
            
            var chatId = ChatMessageItemId;
            setcurrentSend(chatId === undefined);
            var ramdonVal = Math.trunc(Math.random() * (10000 - 1 + 1));

            let _requestBody = {
                UserId: appNBCContext.dbUserId,
                IsSuperAdmin: appNBCContext.isSuperAdmin,
                IsAdmin: appNBCContext.isAdmin,
                OriginalViewModel: {
                    Message: message,
                    To: newchatselected.to === undefined ? [] : newchatselected.to,
                    From_Id: "",
                    Subject: "",
                    IncidentNumber: "",
                    Chat_Id: newchatselected.id,
                    IncidentId: parseInt(newchatselected.incidentId),
                    NewMessageKey: (newchatselected.id === undefined || newchatselected.id === null || newchatselected.id === 0 ? ramdonVal :0),
                    isSharePost: newchatselected.isSharePost === undefined ? false : newchatselected.isSharePost,
                }
            };

            setNewMessageKey(ramdonVal);

            execute("PUT", protectedEndPointAPI.message.chat.sendmessage, _requestBody)
                .then((response) => {

                var _data = response.data;
                if(_data !== null) { 
                    //getChats();
                    let _model = {...newchatselected};
                    if(newchatselected.id === 0) _model = {...newchatselected, id: _data};
                    newsetChatSelected({..._model});
                    selectChat(_model);
                }
            });
        }

    },[execute,appNBCContext,newchatselected,ChatMessageItemId,selectChat]);

    //== [Start] - Commented Temp / Temporarily remove this line of code ===============================
    // useEffect(() => {

    //     let connection = signalRserive.getHubConnection();
    //     if (connection !== undefined && connection !== null && connection._connectionStarted === true) {

    //         connection.off('ReceiveMessage');
    //         connection.on('ReceiveMessage', message => {

    //             getChats(message.incidentId, message);
    //         });
    //     }
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [newchatselected, newMessageKey]);
    //== [End] ==========================================================================================

    //== [Start] - Signal R===============================
    useEffect(() => {

        if (appNBCContext.signalRConnection !== undefined 
            && appNBCContext.signalRConnection  !== null 
            && appNBCContext.signalRConnection._connectionStarted === true) {

            appNBCContext.signalRConnection.off('ReceiveMessage');
            appNBCContext.signalRConnection.on('ReceiveMessage', message => {

                getChats(message.incidentId, message);
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appNBCContext, newchatselected, newMessageKey]);
    //== [End] ==========================================================================================


     const RenderTitle = useCallback(() => {
        return (
            <> 
                {currentChat === undefined ?  (<></>) 
                    : 
                    (
                        currentChat !== undefined && currentChat.incident === "" ?  (<></>) 
                        : 
                        (
                            <><strong> Incident #:   </strong><Link to={`/post/view?id=${currentChat.incident}&pagesource=${protectedVariable.PageSource.Message}`}>{currentChat.incidentNum}</Link></>
                        ) 
                    ) 
                }
            </>
        );

     },[currentChat]);


    const CloseNewChatModal = () => {
        setshowNewChat(false);
    }

    // eslint-disable-next-line no-unused-vars
    const [currentPage, setCurrentPage] = useState(1);

    // eslint-disable-next-line no-unused-vars
    const [pageModalUsersData, setModalUsersPageData] = useState({
        rowData: [],
        isLoading: false,
        totalPages: 0,
        totalData: 0,
    });

    const CreateNewChat = (item) =>
    {
        newsetChatSelected({});
        setChatList([]);
        if (item) {
           
            const queryParams = new URLSearchParams(history.location.search);

            queryParams.append('userId', item.id);
            queryParams.append('name', item.fullName);
            //    queryParams.delete('urlPic');
            queryParams.append('typechat', "user");
            queryParams.append('type', (item.division ? item.division.name : ""));
            queryParams.append('address', (item.address ? item.address.address1 : ""));
            history.replace({
                pathname: `/message`,
                search: queryParams.toString(),
                state: { urlPic: item.picture && item.picture !== null ? item.picture.path : "" }
            });
            CloseNewChatModal();
            getChats();
        }
    }

    return (
        <>
            <Row>
                <Col md="3" class="ChatListPanel">
                    {(boardchatselected.typechat === "user" || boardchatselected.typechat == null) &&
                        <>
                            <Row className="rowchat">
                                <label className="Chatlbl col-9">
                                    Chats
                            </label>
                                {/** Commented this temporarily since there's an error occur **/}
                                {/* <button onClick={newchatClick} className="btn btn-plus col-3">+</button> */}
                            </Row>
                            {/* <Row>
                                <input type="text" className=" ChatInputSearch" placeholder="Search chat..." />
                            </Row> */}

                            <Row class="ChatList">
                                {isloadinguserchat &&
                                    <>
                                        <LoaderUserChat />
                                    </>
                                }
                                {!isloadinguserchat &&
                                    <>
                                        {userschat.map((item) => (
                                            <Row key={item.id} className={`chatitemlist ${newchatselected.id === item.id ? "chatselected" : ""} `} onClick={() => SelectNewChatPreview(item)}>
                                                <Col md="4">
                                                    {item.img === "" && item.typechat === "incident" &&

                                                    <div class="image-cropper">
                                                        <img src={nopreview} aria-hidden alt="Card image cap" class="rounded" />
                                                    </div>

                                                    }

                                                    {item.img === "" && item.typechat === "user" &&
                                                        <StyledBadge
                                                            className="badgeuser"
                                                            overlap="circular"
                                                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                            variant="dot"
                                                        >
                                                            <Avatar name={item.name} size="50" round />
                                                        </StyledBadge>
                                                    }

                                                    {
                                                        item.img !== "" && item.typechat === "incident" &&



                                                        <Avatar src={item.img} size="50" round />


                                                    }
                                                    {item.img !== "" && item.typechat === "user" &&


                                                        <StyledBadge
                                                            overlap="circular"
                                                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                            variant="dot"
                                                        >
                                                            {appNBCContext.isFileEncryptionEnable ? 
                                                            (<Avatar src={`data:image/jpeg;base64,${item.img}`} size="50" round />) 
                                                            : 
                                                            (<Avatar src={item.img} size="50" round />) }
                                                            
                                                        </StyledBadge>

                                                    }
                                                </Col>
                                                <Col md="8">
                                                    {item.typechat === "user" &&
                                                        <>
                                                            <Row>
                                                                <Col>
                                                                    <label className="lblitemname">
                                                                        {item.incident && item.incident > 0 ? "Incident: " : ""}
                                                                        {item.name}
                                                                    </label>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <label className="lblitemtype">
                                                                    {item.incident && item.incident > 0 ? "Type: " : ""}
                                                                    {item.type}
                                                                </label>
                                                            </Row>
                                                            <Row >
                                                                <Col md="12">
                                                                    <label className="lblitemtype txtblack">Location:</label><label className="lblitemtype"> {item.userAddress}</label>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <label className="lblitemmsg">{item.lastmessage}</label>
                                                            </Row>
                                                            {
                                                              item.isSharePost && (
                                                                <>
                                                                    <Row >
                                                                       
                                                                            <label className="lblitemtype txtblack">Shared To : </label>
                                                                       
                                                                    </Row>
                                                                    <Row >
                                                                     
                                                                            <label className="lblitemtype txtblack">{item.userFullName}</label>
                                                                       
                                                                    </Row>
                                                                    <Row >
                                                                        <Col md="12">
                                                                            <img alt="" src={phoneIcon} className="iconpngchat"></img> {item.userPhone && <label className="lblitemtype txtblack">{item.userPhone}</label>}{!item.userPhone && <label>-</label>}
                                                                        </Col>
                                                                    </Row>
                                                                    <Row >
                                                                        <Col md="12">
                                                                            <img alt="" src={emailIcon} className="iconpngchat"></img> {item.userEmail && <label className="lblitemtype txtblack">{item.userEmail}</label>}{!item.userEmail && <label>-</label>}
                                                                        </Col>
                                                                    </Row>
                                                                </>
                                                              )
                                                            }
                                                        </>
                                                    }
                                                    {item.typechat === "incident" &&

                                                        <>
                                                            <Row>
                                                                <label className="lblitemname"> Incident : {item.incidentNum}</label>
                                                            </Row>
                                                            <Row>
                                                                <Col md="12">
                                                                    <label className="lblitemtype txtblack">Type : </label><label className="lblitemtype">{item.type}</label>
                                                                </Col>
                                                            </Row>
                                                            <Row >
                                                                <Col md="12">
                                                                    <label className="lblitemtype txtblack">Location : </label><label className="lblitemtype"> {item.address}</label>
                                                                </Col>
                                                            </Row>

                                                            {
                                                              item.isSharePost && (
                                                                <>
                                                                    <Row >
                                                                        <Col md="12">
                                                                            <label className="lblitemtype txtblack">Shared To : </label>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row >
                                                                        <Col md="12">
                                                                            <label className="lblitemtype txtblack">{item.userFullName}</label>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row >
                                                                        <Col md="12">
                                                                            <img alt="" src={phoneIcon} className="iconpngchat"></img> {item.userPhone && <label className="lblitemtype txtblack">{item.userPhone}</label>}{!item.userPhone && <label>-</label>}
                                                                        </Col>
                                                                    </Row>
                                                                    <Row >
                                                                        <Col md="12">
                                                                            <img alt="" src={emailIcon} className="iconpngchat"></img> {item.userEmail && <label className="lblitemtype txtblack">{item.userEmail}</label>}{!item.userEmail && <label>-</label>}
                                                                        </Col>
                                                                    </Row>
                                                                </>
                                                              )
                                                            }


                                                        </>

                                                    }

                                                </Col>
                                            </Row>
                                        ))}
                                    </>
                                }
                            </Row>

                        </>
                    }
                    {boardchatselected.typechat === "incident" &&
                        <Row className="rowchat">
                            <Col md="12">
                            <label className="lblboardincident" onClick={() => InitChatList()}><FontAwesomeIcon icon={faArrowLeft} size="l" />&nbsp;</label>
                            <label onClick={() => InitChatList()} className="lblboardincident"> Incident: &nbsp; {boardchatselected.name}</label>
                                {boardchatselected.img !== "" &&



                                <Avatar src={boardchatselected.img} size="50" round />


                                }
                                {boardchatselected.img === "" &&

                                <div class="image-cropper">
                                <   img src={nopreview} aria-hidden alt="Card image cap" class="rounded" />
                                </div>

                                }
                            </Col>
                            <Col md="12">
                                <img alt="" className="pngic" src={type}></img>  <label className="lblboardinc"> {boardchatselected.type}</label>
                            </Col>
                            <Col md="12">
                                <img alt="" className="pngic" src={locationpng}></img>   <label className="lblboardinc">  {boardchatselected.address}</label>
                            </Col>
                            <Col md="12">
                                <img alt="" className="pngic" src={calendar}></img> <label className="lblboardinc">{boardchatselected.dateincident}</label>
                            </Col>
                            {/* <Row className="rowchat" md="12">
                                <input type="text" className=" ChatInputSearch" placeholder="Search chat..." />
                            </Row> */}
                            <Row>
                                <Col md="12">
                                <br />
                                    <label>Related Chats</label>
                                    <br />
                                </Col>
                            </Row>
                            <Col md="12">
                                {userschatincident.map((item) => (
                                    <Row className={`chatitemlist ${newchatselected.id === item.id ? "chatselected" : ""} `} onClick={() => SelectNewChatPreviewIncident(item)}>
                                        <Col md="4">
                                            {item.img === "" && item.typechat === "incident" &&

                                                <div class="image-cropper">
                                                    <img src={nopreview} aria-hidden alt="Card image cap" class="rounded" />
                                                </div>

                                            }
                                            {item.img === "" && item.typechat === "user" &&
                                                <StyledBadge
                                                    className="badgeuser"
                                                    overlap="circular"
                                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                    variant="dot"
                                                >
                                                    <Avatar name={item.name} size="50" round />
                                                </StyledBadge>
                                            }
                                            {item.img !== "" && item.typechat === "incident" &&



                                                <Avatar src={item.img} size="50" round />


                                            }
                                            {item.img !== "" && item.typechat === "user" &&


                                                <StyledBadge
                                                    overlap="circular"
                                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                    variant="dot"
                                                >
                                                    {appNBCContext.isFileEncryptionEnable ? 
                                                    <Avatar src={`data:image/jpeg;base64,${item.img}`} size="50" round /> 
                                                    : 
                                                    <Avatar src={item.img} size="50" round />}
                                                    
                                                </StyledBadge>

                                            }
                                        </Col>
                                        <Col md="8">
                                            {item.typechat === "user" &&
                                                <>
                                                    <Row>
                                                        <label className="lblitemname">{item.officername}</label>
                                                    </Row>
                                                    <Row>
                                                        <label className="lblitemtype">{item.type}</label>
                                                    </Row>
                                                    <Row>
                                                        <label className="lblitemmsg">{item.lastmessage}</label>
                                                    </Row>
                                                </>
                                            }
                                            {item.typechat === "incident" &&

                                                <>
                                                    <Row>
                                                        <label className="lblitemname"> Incident :{item.name}</label>
                                                    </Row>
                                                    <Row>
                                                        <Col md="12">
                                                            <label className="lblitemtype txtblack">Type:</label><label className="lblitemtype">{item.type}</label>
                                                        </Col>
                                                    </Row>
                                                    <Row >
                                                        <Col md="12">
                                                            <label className="lblitemtype txtblack">Location:</label><label className="lblitemtype"> {item.address}</label>
                                                        </Col>
                                                    </Row>
                                                    {
                                                              item.isSharePost && (
                                                                <>
                                                                    <Row >
                                                                        <Col md="12">
                                                                            <label className="lblitemtype txtblack">Shared To : </label>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row >
                                                                        <Col md="12">
                                                                            <label className="lblitemtype txtblack">{item.userFullName}</label>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row >
                                                                        <Col md="12">
                                                                            <img alt="" src={phoneIcon} className="iconpngchat"></img> {item.userPhone && <label className="lblitemtype txtblack">{item.userPhone}</label>}{!item.userPhone && <label>-</label>}
                                                                        </Col>
                                                                    </Row>
                                                                    <Row >
                                                                        <Col md="12">
                                                                            <img alt="" src={emailIcon} className="iconpngchat"></img> {item.userEmail && <label className="lblitemtype txtblack">{item.userEmail}</label>}{!item.userEmail && <label>-</label>}
                                                                        </Col>
                                                                    </Row>
                                                                </>
                                                              )
                                                            }
                                                </>

                                            }

                                        </Col>
                                    </Row>
                                ))}
                            </Col>
                        </Row>
                    }
                </Col>

                <Col md="9" className="chatboard">
                    <Row className="chatrowpreview">
                        <Col md="1">
                            {newchatselected.img === "" && newchatselected.typechat === "incident" &&

                            <div class="image-cropper">
                                <img src={nopreview} aria-hidden alt="Card image cap" class="rounded" />
                            </div>

                            }
                            {newchatselected.img === "" && newchatselected.typechat === "user" &&
                                <StyledBadge
                                    className="badgeuser"
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    variant="dot"
                                >
                                    <Avatar name={newchatselected.name} size="50" round />
                                </StyledBadge>
                            }
                            {newchatselected.img !== "" && newchatselected.typechat === "incident" &&



                                <Avatar src={newchatselected.img} size="50" round />


                            }
                            {newchatselected.img !== "" && newchatselected.typechat === "user" &&


                                <StyledBadge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    variant="dot"
                                >
                                    {appNBCContext.isFileEncryptionEnable ? 
                                    (<Avatar src={`data:image/jpeg;base64,${newchatselected.img}`} size="50" round />) : 
                                    (<Avatar src={newchatselected.img} size="50" round />) }
                                    
                                </StyledBadge>

                            }
                        </Col>
                        <Col md="8">
                            <Row>
                                <Col md="8">
                                    {(newchatselected.typechat === "user" && boardchatselected.typechat === "incident") &&
                                        <label className="titlechatprev">{newchatselected.officername}</label>
                                    }

                                    {(newchatselected.typechat === "user" && boardchatselected.typechat === undefined) &&
                                        <label className="titlechatprev">{newchatselected.name}</label>
                                    }
                                   
                                    {(newchatselected.typechat === "user" && boardchatselected.typechat === "user") &&
                                      <>
                                        <label className="titlechatprev">{newchatselected.name}&nbsp;</label>
                                        { newchatselected.incident === null && StatusBadge(newchatselected.appStatus === 1 ? 2: newchatselected.deleted ? 3 : 1)}

                                      </>
                                        
                                        
                                    }
                                    <label className="datachatpreview">&nbsp;{newchatselected.type}</label>
                                </Col>


                            </Row>
                            <Row>
                                <Col md="100">
                                    {(newchatselected.typechat === "user" && boardchatselected.typechat=== "incident" && !isSelectNewChatPreview) && 
                                        <div className="row">
                                            <Col md="2">
                                                    {
                                                        boardchatselected.img === "" ? 
                                                        <div class="image-cropper">
                                                             <img src={nopreview} aria-hidden alt="Card image cap" class="rounded" />
                                                         </div> 
                                                        :  
                                                        <Avatar src={boardchatselected.img} size="40" round />
                                                    }
                                            </Col>
                                            <Col md="6">
                                                <label className="titlechatprevx">Incident:  {newchatselected.name}</label>
                                            </Col>
                                        
                                        </div>
                                    }

                                    {
                                        //[Start] - Address
                                    }
                                    {(newchatselected.typechat === "user" && boardchatselected.typechat === "user" && newchatselected.incident === 0) && 
                                        <div className="row"> 
                                            <Col md="8">
                                                <img alt="" src={addresspng} className="iconpngchat"></img> {newchatselected.userAddress && <label className="datachatpreview">{newchatselected.userAddress}</label>}{!newchatselected.userAddress && <label>-</label>}
                                            </Col>
                                        </div>
                                    }
                                    {(newchatselected.typechat === "user" && boardchatselected.typechat === "user" && newchatselected.incident  > 0) && 
                                        <div className="row"> 
                                            <Col md="100">
                                                <img alt="" src={addresspng} className="iconpngchat"></img> {newchatselected.address && <label className="datachatpreview">{newchatselected.address}</label>}{!newchatselected.address && <label>-</label>}
                                            </Col>
                                        </div>
                                    }
                                    {(newchatselected.typechat === "user" && boardchatselected.typechat === undefined) && 
                                        <div className="row"> 
                                            <Col md="100">
                                                <img alt="" src={addresspng} className="iconpngchat"></img> {newchatselected.userAddress && <label className="datachatpreview">{newchatselected.userAddress}</label>}{!newchatselected.userAddress && <label>-</label>}
                                            </Col>
                                        </div>
                                    }
                                    {(newchatselected.typechat === "incident" && newchatselected.incident > 0 && boardchatselected.typechat === undefined) && 
                                        <div className="row"> 
                                            <Col md="100">
                                                <img alt="" src={addresspng} className="iconpngchat"></img> {newchatselected.address && <label className="datachatpreview">{newchatselected.address}</label>}{!newchatselected.address && <label>-</label>}
                                            </Col>
                                        </div>
                                    }
                                    {
                                        //[End] - Address
                                    }
                                    {
                                        //[Start] - Address of a specific user in a incident group chat
                                    }
                                    {(newchatselected.typechat === "user" && newchatselected.incident > 0 && boardchatselected.typechat === "incident" && boardchatselected.incident > 0) && 
                                        <div className="row"> 
                                            <Col md="100">
                                                <img alt="" src={addresspng} className="iconpngchat"></img> {newchatselected.userAddress && <label className="datachatpreview">{newchatselected.userAddress}</label>}{!newchatselected.userAddress && <label>-</label>}
                                            </Col>
                                        </div>
                                    }
                                    {
                                        //[End] - Address of a specific user in a incident group chat
                                    }

                                    {
                                        //[Start] - Phone
                                    }
                                    {(newchatselected.typechat === "user" && boardchatselected.typechat === "user" && newchatselected.incident === 0) && 
                                         <div className="row"> 
                                            <Col md="8">
                                                <img alt="" src={phoneIcon} className="iconpngchat"></img> {newchatselected.userPhone && <label className="datachatpreview">{newchatselected.userPhone}</label>}{!newchatselected.userPhone && <label>-</label>}
                                            </Col>
                                        </div>
                                    }
                                      {(newchatselected.typechat === "user" && boardchatselected.typechat === undefined) && 
                                         <div className="row"> 
                                            <Col md="8">
                                                <img alt="" src={phoneIcon} className="iconpngchat"></img> {newchatselected.userPhone && <label className="datachatpreview">{newchatselected.userPhone}</label>}{!newchatselected.userPhone && <label>-</label>}
                                            </Col>
                                        </div>
                                    }
                                    {
                                        //[End] - Phone
                                    }
                                    {
                                        //[Start] - Phone of a specific user in a incident group chat
                                    }
                                     {(newchatselected.typechat === "user" && newchatselected.incident > 0 && boardchatselected.typechat === "incident" && boardchatselected.incident > 0) && 
                                        <div className="row"> 
                                            <Col md="100">
                                            <img alt="" src={phoneIcon} className="iconpngchat"></img> {newchatselected.userPhone && <label className="datachatpreview">{newchatselected.userPhone}</label>}{!newchatselected.userPhone && <label>-</label>}
                                            </Col>
                                        </div>
                                    }
                                    {
                                        //[End] - Phone of a specific user in a incident group chat
                                    }

                                    {
                                        //[Start] - Email
                                    }
                                    {(newchatselected.typechat === "user" && boardchatselected.typechat === "user" && newchatselected.incident === 0) && 
                                        <div className="row"> 
                                            <Col md="8">
                                                <img alt="" src={emailIcon} className="iconpngchat"></img> {newchatselected.userEmail && <label className="datachatpreview">{newchatselected.userEmail}</label>}{!newchatselected.userEmail && <label>-</label>}
                                            </Col>
                                        </div>
                                    }
                                    {(newchatselected.typechat === "user" && boardchatselected.typechat === undefined) && 
                                        <div className="row"> 
                                            <Col md="8">
                                                <img alt="" src={emailIcon} className="iconpngchat"></img> {newchatselected.userEmail && <label className="datachatpreview">{newchatselected.userEmail}</label>}{!newchatselected.userEmail && <label>-</label>}
                                            </Col>
                                        </div>
                                    }
                                    {
                                        //[End] - Email
                                    } 
                                    {
                                        //[Start] - Email of a specific user in a incident group chat
                                    }
                                     {(newchatselected.typechat === "user" && newchatselected.incident > 0 && boardchatselected.typechat === "incident" && boardchatselected.incident > 0) && 
                                        <div className="row"> 
                                            <Col md="100">
                                            <img alt="" src={emailIcon} className="iconpngchat"></img> {newchatselected.userEmail && <label className="datachatpreview">{newchatselected.userEmail}</label>}{!newchatselected.userEmail && <label>-</label>}
                                            </Col>
                                        </div>
                                    }
                                    {
                                        //[End] - Email of a specific user in a incident group chat
                                    }   
                                                                   
                                </Col>

                            </Row>

                        </Col>
                        <Col md="2">
                        </Col>
                    </Row>
                    {isLoadingChatPanel &&
                        <>
                            <LoaderChatPanel />
                        </>

                    }
                    {!isloadinguserchat && !isLoadingChatPanel && userschat.length === 0 &&
                        <>
                            <AnyMessageState />
                        </>
                    }
                    {!isLoadingChatPanel &&
                        <Row>
                            <Col md="12" className="ChatPanel">
                                <Form>


                                    <Row>
                                        {newchatselected.id != null &&
                                            <AzureChatList getMessages={selectChat} Chats={chatList} />
                                        }
                                    </Row>



                                </Form>
                            </Col>
                            <Col md="12" className="divSend">
                                <Row>
                                    <span className="LabelRigth">
                                        <RenderTitle />
                                    </span>
                                </Row>

                                <Row>
                                    {newchatselected.id != null && ChatMessageItem &&
                                        <AzureChatWindow className="viewMessages" chat={ChatMessageItem.chat} />
                                    }
                                </Row>
                                <Row>
                                    {newchatselected.id != null &&
                                        <AzureChatToolBar DisableSend={disableSend} sendMessage={sendMessageV2} />
                                    }
                                </Row>

                            </Col>
                        </Row>
                    }
                </Col>
            </Row>
            <AzureChatNewModal CreateNewChat={CreateNewChat} setCurrentPage={setCurrentPage}  data={pageModalUsersData} show={showNewChat} CloseNewChatModal={CloseNewChatModal}/>
        </>
       
    );
}

export default AzureChatPanel;