import AzureChatToolBarApp from "./AzureChatToolBar";
import AzureChatMessageBarApp from "./AzureChatMessageBar";
import AzureChatWindowApp from "./AzureChatWindow";
import AzureChatListApp from "./AzureChatList";
import AzureChatNewModalApp from "./AzureChatNewModal";
import AzureChatPanelApp from "./AzureChatPanel"

export const AzureChatToolBar = () => {
    return (<AzureChatToolBarApp />);
}

export const AzureChatMessageBar = () => {
    return (<AzureChatMessageBarApp />);
}

export const AzureChatWindow = () => {
    return (<AzureChatWindowApp />);
}

export const AzureChatList = () => {
    return (<AzureChatListApp />);
}

export const AzureChatNewModal = () => {
    return (<AzureChatNewModalApp />);
}

export const AzureChatPanel = () => {
    return (<AzureChatPanelApp />);
}

