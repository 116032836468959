import React, {useEffect,useState} from "react";
import {  useMsal } from "@azure/msal-react";
import './AzureLogin.css';
import nbcLogo from "../../../assets/v2/auth_logo.png";
import microsoftLogo from "../../../assets/microsoft-logo.png";
import { loginRequest } from "../../../authConfig";
import IdleTimer from "../../../services/IdleTimer";


const AzureLogin = () => {
    const { instance, inProgress } = useMsal();
    const [isTimeout, setIsTimeout] = useState(false);


    // const handleLoginPopUp = (event) => {


    //     event.preventDefault(); // 👈️ prevent page refresh
    
    //   /**
    //    * When using popup and silent APIs, we recommend setting the redirectUri to a blank page or a page 
    //    * that does not implement MSAL. Keep in mind that all redirect routes must be registered with the application
    //    * For more information, please follow this link: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/login-user.md#redirecturi-considerations 
    //    */
    
    //     instance.loginPopup({
    //       ...loginRequest,
    //       redirectUri: '/'
    //   }).catch((error) => console.log(error));
    // }  

    const handleLoginRedirect = (event) => {


        
    
      /**
       * When using popup and silent APIs, we recommend setting the redirectUri to a blank page or a page 
       * that does not implement MSAL. Keep in mind that all redirect routes must be registered with the application
       * For more information, please follow this link: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/login-user.md#redirecturi-considerations 
       */
    
        instance.loginRedirect({
          ...loginRequest,
          redirectUri: '/'
      }).catch((error) => console.log(error));
      event.preventDefault(); // 👈️ prevent page refresh

    }  

    
    useEffect(() => {
        if(inProgress === "none") {
            const timer = new IdleTimer({
                timeout: 60, //expire after 4 hours
                onTimeout: () => {
                   console.log("onTimeout....")
                },
                onExpired: () => {
                    // do something if expired on load
                    this.cleanUp();
                    console.log("onExpired....")
                    
                }
            });
    
            return () => {
                timer.cleanUp();
            };

        }
        
    }, [inProgress]);
  

    return (
        <div className="loginMain">
            <div className="login-left-pane">
                <div className="login-logo">
                    <img className="login-logo-img" alt="" src={nbcLogo} />
                </div>
                <div className="login-form">
                    <h1 className="login-welcome"> Welcome </h1> <br/><br/>
                    <p className="login-welcome-label"> Welcome back! Please use your Microsoft account to log-in.</p>

                    <div className="form-group button">
                        <button onClick={handleLoginRedirect} className="btn btn-primary btn-block login-button">
                            <img src={microsoftLogo} width="20" height="20"  alt="Microsoft Logo" style={ {backgroundColor: "white" }} /> &nbsp;
                            <span>Sign In</span>
                        </button>
                    </div>

                    <p className="no-account-label"> Don't have an account? </p>
                    <p className="contact-supervisor-label"> Please contact your supervisor </p>
                    <p className="build-number-label"> Version 1.0 Build 202304182023 </p>


                   
                </div>
            </div>
            <div className="login-right-pane">
            </div>
        </div>
        );
};
 
export default AzureLogin;
 

