import React from "react";
import { Row, Col, Button } from "reactstrap";
import anyincident from "../../../../assets/any_incident.png";
import "./AzureState.css";
import { UsedAppNBCContext } from "../../../AppNBC.Provider";

const AzureAnyPost = (props) => {
    const appNBCContext = UsedAppNBCContext();

    return (
        <>
            <Row>
                <img src={anyincident} className="anypostimg" alt="" />
            </Row>
            <Row>
                <label className="lblnochats">There are no posts here</label>
            </Row>

            {
              (appNBCContext.dbUserId !== null) ? 
                (!appNBCContext.isSuperAdmin ? 
                    (
                    <>
                        <Row>
                            <label className="lblsecnochats">You can create your first post and join other agencies to see what they post</label>
                        </Row>
                        <Row>
                            <Col md="5">
                            </Col>
                            <button className="btn btn-primary btncreatechat" onClick={() => {props.onOpenCreatePost();}}>+ Create Post</button>
                        </Row>
                    </>
                    ) 
                    : (<></>)
                ) 
              : 
              (<></>)
            } 
        </>
    );
};
export default AzureAnyPost;
