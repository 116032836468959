import React, {useState} from 'react';
import { Row } from 'reactstrap';

function AzureChatToolBar (props) {

    const [user] = useState('');
    const [message, setMessage] = useState('');

    const onSubmit = (e) => {
        e.preventDefault();
        const isMessageProvided = message && message !== '';

        if (isMessageProvided) {
            props.sendMessage(user, message);
            setMessage("");
        }
    }
    
    const onMessageUpdate = (e) => {
        setMessage(e.target.value);
    }
    
    return (
        <Row>
            <form onSubmit={onSubmit}>
                <div className="custom-search">
                    <input value={message} onChange={onMessageUpdate} type="text" className="custom-search-input" />
                    <button className="custom-search-botton" type="submit" >Send</button>
                </div>
            </form>
        </Row>);
}

export default AzureChatToolBar;