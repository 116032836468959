import React from "react";
import { Row} from "reactstrap";
import loadingchatpanel from "../../../assets/chat_msg_load.png";
import "./Loader.css";
const LoaderUserChat = () => {
    return (
        <>
            <Row>
                <img src={loadingchatpanel} className="chatpanelloader" alt="" />
            </Row>
          
        </>
    );
};
export default LoaderUserChat;
