/* eslint-disable jsx-a11y/alt-text */
import { useState, useEffect, useCallback } from 'react';
import { NotificationManager } from 'react-notifications';
import { useHistory } from 'react-router-dom';
import {  Row, Col} from 'reactstrap';
import Avatar from 'react-avatar';
import { faBuilding, faCircle, faMessage, faPhone, faUserGroup, faAt } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import "./AzureProfile.css"
import userCard from "../../../assets/CardUser.svg";
import phone from "../../../assets/Phone.svg";
import email from "../../../assets/Email.svg";
import useFetchWithMsal from '../../hooks/useFetchWithMsal';
import { protectedEndPointAPI } from '../../Helpers/ProtectedCustomClass';

library.add(faUserGroup, faCircle, faBuilding, faMessage, faPhone, faAt)

const AzureProfileCard = (props) => {
    let history = useHistory();
    const { id } = props;
    const [profile, setProfile] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    // eslint-disable-next-line no-unused-vars
    const { error, execute } = useFetchWithMsal();

    const RedirectMessage = useCallback(() => {
        if (profile.statusApp != null && (profile.statusApp === "Suspended" || profile.statusApp === "Removed")){
            NotificationManager.info('Information', 'The account cannot be contacted because it was deleted or suspended.', 5000);
            return;
        }

        let img = profile.urlPic;
        if (props.Incident !== undefined && (props.Incident.filesIncidents != null && props.Incident.filesIncidents !== undefined && props.Incident.filesIncidents.length >0)) {
            img = props.Incident.filesIncidents[0].file.path;
        }

        let typechat = "incident";
        
        let varIsSharePost = false;
        varIsSharePost = props.Incident !== undefined;

        history.push({
            pathname: `/message`,
            search: `?userId=${profile.id}&name=${profile.name}&type=${props.Incident !== undefined ? props.Incident.type : profile.division}&typechat=${typechat}&incident=${props.Incident !== undefined ? props.Incident.id : ""}&address=${props.Incident !== undefined ? props.Incident.address : ""}`,
            state: {
                urlPic: img,
                userId: profile.id,
                name: profile.name,
                type: (props.Incident !== undefined ? props.Incident.type : profile.division),
                typechat: typechat,
                incident:props.Incident !== undefined ? props.Incident.id : "",
                incidentNum:props.Incident !== undefined ? props.Incident.incidentNum : "",
                address: props.Incident !== undefined ? props.Incident.address : "",
                isSharePost: varIsSharePost,
                userFullName: profile.name,
                userAddress: profile.address,
                phone: profile.showPhone ? (profile.phone === "" ? "-" : profile.phone) : "-",
                email: profile.showEmail ? (profile.email === "" ? "-" : profile.email) : "-",
            }

        });

    },[profile])

    const ShowPhone = useCallback(() => {
        return (
            <a href={"tel:" + profile.phone}>
                <Row>
                    <Col lg={1}>
                        <object data={phone} />
                    </Col>
                    <Col>
                        &nbsp; {profile.phone}
                    </Col>
                </Row>
            </a>
        );
    },[profile])

    const ShowEmail = useCallback(() => {
        return (
            <a href={"mailto:" + profile.email}>
                <Row>
                    <Col lg={1}>
                        <object data={email} />
                    </Col>
                    <Col>
                        &nbsp; {profile.email}
                    </Col>
                </Row>
            </a>
        );
    },[profile])

    const HidePhone = () => {
        return (
            <Row>
                <Col lg={1}>
                    <object data={phone} />
                </Col>
                <Col>
                    &nbsp; {"-"}
                </Col>
            </Row>
        );
    }

    const HideEmail = () => {
        return (
            <Row>
                <Col lg={1}>
                    <object data={email} />
                </Col>
                <Col>
                    &nbsp; {"-"}
                </Col>
            </Row>
        );
    }

    useEffect(() => {
        setIsLoading(true);

        execute("PUT", protectedEndPointAPI.profile.summary, id)
        .then((response) => {
            if(response.success) {
                if(response.data !== null) setProfile(response.data);
            }
        }).finally(() => {
            setIsLoading(false);
        });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {isLoading ? 
            (
            <div className="popupProfileContent">
                <div className="bodyProfilePopup">
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
            ) 
            : 
            (
            <div className="popupProfileContent">
                <div className="popupHeaderProfile">
                    <Row className="headerProfile">
                        <Col lg={3}>
                            {profile.urlPic === null 
                            ? (<Avatar className="sb-avatar--textProfile" size="40" round="50%" name={profile.name}></Avatar>) 
                            : (
                                profile.urlPic === "" ? (<Avatar className="sb-avatar--textProfile" size="40" round="50%" name={profile.name}></Avatar>) 
                                : 
                                (<img alt="" className="picUrlPreviewAdmin" src={profile.urlPic} />)
                            )}
                        </Col>
                        <Col lg={9}>
                            <Row>   
                                <label className="nameProfile">{profile.name}</label>
                            </Row>
                            <Row>
                                <label className="divisionProfile">{profile.division}</label>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <div className="bodyProfilePopup">
                    <Row>
                        <Row >
                            <Col lg={1}>
                                <object data={userCard} />
                            </Col>
                            <Col>
                                &nbsp; <label>{profile.address === "" ? "-" : profile.address }</label>
                            </Col>
                        </Row>
                        <br/><br/>
                        {
                            profile.showPhone ? 
                                (profile.phone === "" ? (<HidePhone />) : (<ShowPhone />)) 
                                : 
                                (<HidePhone />)
                        }
                        <br/><br/>
                        {
                            profile.showEmail ? 
                                (profile.email === "" ? (<HideEmail />) : (<ShowEmail />)) 
                                : 
                                (<HideEmail />)
                        }
                         <br/><br/>
                        <Row >
                            <Col>
                                {!(profile.statusApp === "Suspended" || profile.statusApp === "Removed" || profile.deleted === true) &&
                                    <button onClick={() => RedirectMessage()} className="btn btn-outline-primary btn-lg btn-bloc col-lg-12" disabled={profile.deleted}>Message</button>
                                }
                            </Col>
                        </Row>
                    </Row>
                </div>
            </div>
            
            )}
     
        </>
    );

}

export default AzureProfileCard;
