import dotsMore from "../../assets/dotsMore.png";
import React, {useCallback} from "react";
import { PopoverBody, UncontrolledPopover } from 'reactstrap';
import { faCalendarDays, faShield, faBars, faUserGroup, faClock, faLocationDot, faFolderOpen, faMagnifyingGlass, faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import iconpdf from "../../assets/pdficon.png";
import WordIcon from "../../assets/wordIcon";
import iconexcel from "../../assets/excelicon.png";
import iconpower from "../../assets/powericon.png";
import iconrarzip from "../../assets/iconrarzip.png";
import icontxt from "../../assets/txticon.png";
import iconnopreview from "../../assets/nopreview.svg";
import StatusBadge from "../Officer/StatusBadge";
import AzurePostOptions from "../../v2/components/AzurePost/AzurePost.Options";
import AzureProfileCard from "../../v2/components/AzureProfile/AzureProfileCard";
import { UsedAppNBCContext } from "../../v2/AppNBC.Provider";

library.add(faCalendarDays, faShield, faBars, faUserGroup, faClock, faLocationDot, faFolderOpen, faMagnifyingGlass, faAngleDown);

const IncidentCard = (props) =>
{
    const appNBCContext = UsedAppNBCContext();

    const renderSwitchImage = useCallback((item) => {
        if(appNBCContext.isFileEncryptionEnable) {
            if(item.fileBase64String !== "") {
                return <img key={item.id} className="card-img-top" 
                            src={`data:image/jpeg;base64,${item.fileBase64String}`} 
                            aria-hidden alt="Card image cap" />
            } else {
                return <img  src={iconnopreview} aria-hidden alt="Card image cap" />
            }
           
        } else {
            if(item.url !== "") {
                return <img key={item.id} className="card-img-top" 
                            src={item.url} 
                            aria-hidden alt="Card image cap" />
            } else {
                return <img  src={iconnopreview} aria-hidden alt="Card image cap" />
            }
           
        }
    },[appNBCContext])

    const renderSwitchFileIcon = (item) => {
        switch (item.fileType) {
            case "application/pdf": 
                return iconpdf;
            case "officedocument.word":
                return WordIcon;
            case "officedocument.spreadsheetml":
                return iconexcel;
            case "officedocument.presentationm":
                return iconpower;
            case "text/plain":
             return   icontxt;
            case "application/x-zip-compressed":
                return iconrarzip;
            default:
                return  iconnopreview;
        }
    }

    return <>
        <div key={props.item.id} className="row">
            <div className="col-12">
                <div key={props.item.id} className="card cardpost">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="container-img" style={{paddingLeft: "20px"}}>
                                {props.item.azureIncidentImages !== undefined && props.item.azureIncidentImages.length > 0  
                                    ?  (renderSwitchImage(props.item.azureIncidentImages[0]))
                                    : 
                                    (<img  src={iconnopreview} aria-hidden alt="Card image cap" />)
                                }
                            </div>
                        </div>
                        <div className="col-md-9 marginPost">
                            <div className="row">
                                <div className="col-11">
                                    <label className="textBlue" >{props.item.agencyName}</label>
                                </div>
                                <div className="col-1">
                                    <button id={`${props.Key}${props.item.id}`} className="btn btn-default btnMore" style={{border: "none"}}><img src={dotsMore} alt="more" /></button>
                                    <UncontrolledPopover trigger="legacy" placement="bottom" target={`${props.Key}${props.item.id}`} >
                                        <PopoverBody>
                                            <AzurePostOptions DeleteIncident={props.DeleteIncident} LoadIncidents={props.LoadIncidents} OpenFolders={props.OpenFolders} data={props.item} OpenSharePost={(event) => props.OpenSharePost(props.item)} OpenReportPost={(event) => props.OpenReportPost(props.item)} SetNotification={props.SetNotification} ></AzurePostOptions>
                                        </PopoverBody>
                                    </UncontrolledPopover>
                                </div>
                            </div>
                            <div className="row">
                                <label className="incidenTitle">Incident: {props.item.incidentNum}</label>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <FontAwesomeIcon className="svgIconGrey" icon={faClock} />
                                    &nbsp;
                                    <span className="cardSpan">
                                        {props.item.date2}
                                    </span>
                                    &nbsp;&nbsp;&nbsp;
                                    <FontAwesomeIcon className="svgIconGrey" icon={faLocationDot} />
                                    &nbsp;
                                    <span className="cardSpan">
                                        {props.item.address}
                                    </span>
                                    &nbsp;&nbsp;&nbsp;
                                    <FontAwesomeIcon className="svgIconGrey" icon={faFolderOpen} />
                                    &nbsp;
                                    <span className="cardSpan">
                                        {props.item.type}
                                    </span>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <p className="card-text">{props.item.description}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    {
                                        props.item.azureIncidentImages && props.item.azureIncidentImages.length > 1 && props.item.azureIncidentImages.slice(1).map((file) => {
                                            return (
                                                <div className="thumbNailImageIncident">
                                                    {renderSwitchImage(file)}
                                                </div>
                                            )
                                        })
                                    }

                                    {
                                        props.item.azureIncidentFiles && props.item.azureIncidentFiles.length > 1 && props.item.azureIncidentFiles.slice(1).map((file) => {
                                            return (
                                                <div className="thumbNailImageIncident">
                                                    <img key={file.id} className="thumbimg" src={renderSwitchFileIcon(file)} aria-hidden alt="Card image cap" />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-11">
                                    {
                                        props.item.myPost && props.item.myPost === true &&
                                        <>
                                            <label className="lblProfile">My Posts</label>
                                        </>
                                    }

                                    {
                                        props.item.myPost === false &&
                                        <>
                                            <label className="cardSpan">
                                                {props.item.division ? props.item.division + ":" : ""}&nbsp;
                                            </label>

                                            <label className="lblProfile" id={`PopoverLegacy${props.item.id}`} >
                                                {props.item.userName} &nbsp; {StatusBadge(props.item.user.deleted ? 3 : props.item.user.appStatus === 1 ? 2 : 1 )} &nbsp;<FontAwesomeIcon className="svgIconGrey" icon={faAngleDown} />
                                            </label>

                                            <UncontrolledPopover target={`PopoverLegacy${props.item.id}`}
                                                trigger="legacy"
                                                placement="top-start"
                                            >

                                                <PopoverBody>
                                                    <AzureProfileCard Incident={props.item} openMessage={props.openMessage} id={props.item.userId} ></AzureProfileCard>
                                                </PopoverBody>

                                            </UncontrolledPopover>
                                        </>
                                    }

                                </div>


                            </div>
                        </div>

                    </div>



                </div>
            </div>
        </div>
    </>

}

export default IncidentCard;