import {
    useState,
    useCallback,
    useEffect
} from 'react';

import { InteractionType } from '@azure/msal-browser';
import { useMsal, useMsalAuthentication } from "@azure/msal-react";
import { loginRequest } from '../../authConfig';

/**
 * Custom hook to call a web API using bearer token obtained from MSAL
 * @param {PopupRequest} msalRequest 
 * @returns 
 */
const useFetchWithMsal = () => {
    const { instance } = useMsal();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    const [accessToken, setAccessToken] = useState(null);

    const { result, error: msalError } = useMsalAuthentication(InteractionType.Redirect, {
        ...loginRequest,
        account: instance.getActiveAccount(),
        redirectUri: '/' //Modify this to the redirected page you prefer if a user is currently login
    });

    useEffect(() => {
       // console.log("useFetchWithMsal.result: ",result) //added temp
        if(accessToken == null) {
            if(result)  setAccessToken(result.accessToken);
        }
    },[result,accessToken]);

    /**
     * Execute a fetch request with the given options
     * @param {string} method: GET, POST, PUT, DELETE
     * @param {String} endpoint: The endpoint to call
     * @param {Object} data: The data to send to the endpoint, if any 
     * @returns JSON response
     */
    const execute = async (method, endpoint, data = null) => {
        if (msalError) {
            setError(msalError);
            return;
        }

        if(accessToken === null) {
            if(result) {
                const { accessToken } = result;
                setAccessToken(accessToken);
            }
        }

        //if (result) {
        if (instance.getActiveAccount() !== null) {

            if(instance.getActiveAccount().idToken !== null) {

                try {
                    let response = null;

                    const headers = new Headers();
                    //const bearer = `Bearer ${result.accessToken}`;
                    const bearer = `Bearer ${instance.getActiveAccount().idToken}`;
                    headers.append("Authorization", bearer);

                    if (data) headers.append('Content-Type', 'application/json');

                    let options = {
                        method: method,
                        headers: headers,
                        body: data ? JSON.stringify(data) : null,
                    };
                    
                    setIsLoading(true);
                    response = (await fetch(endpoint, options));

                    if ((response.status === 200 || response.status === 201)) {
                        let responseData = response;

                        try {
                            responseData = await response.json();
                        } catch (error) {
                            console.log(error);
                        } finally {
                            setData(responseData);
                            setIsLoading(false);
                            return responseData;
                        }
                    }

                    setIsLoading(false);
                    return response;
                } catch (e) {
                    setError(e);
                    setIsLoading(false);
                    throw e;
                }

            }
        }


    };

    /**
     * Execute a fetch request with the given options
     * @param {string} method: GET, POST, PUT, DELETE
     * @param {String} endpoint: The endpoint to call
     * @param {Object} data: The data to send to the endpoint, if any 
     * @returns JSON response
     */
    const executeFormData = async (method, endpoint, data = null) => {
        if (msalError) {
            setError(msalError);
            return;
        }

        if(accessToken === null) {
            if(result) {
                const { accessToken } = result;
                setAccessToken(accessToken);
            }
        }

        //if (result) {
        if (instance.getActiveAccount() !== null) {

            if(instance.getActiveAccount().idToken !== null) {

                try {
                    let response = null;

                    const headers = new Headers();
                    //const bearer = `Bearer ${result.accessToken}`;
                    const bearer = `Bearer ${instance.getActiveAccount().idToken}`;
                    headers.append("Authorization", bearer);

                    let options = {
                        method: method,
                        headers: headers,
                        body: data,
                    };

                    setIsLoading(true);
                    response = (await fetch(endpoint, options));

                    if ((response.status === 200 || response.status === 201)) {
                        let responseData = response;

                        try {
                            responseData = await response.json();
                        } catch (error) {
                            console.log(error);
                        } finally {
                            setData(responseData);
                            setIsLoading(false);
                            return responseData;
                        }
                    }

                    setIsLoading(false);
                    return response;
                } catch (e) {
                    setError(e);
                    setIsLoading(false);
                    throw e;
                }

            }
        }


    };

    /**
     * Execute a fetch request with the given options
     * @param {string} method: GET, POST, PUT, DELETE
     * @param {String} endpoint: The endpoint to call
     * @param {Object} data: The data to send to the endpoint, if any 
     * @returns JSON response
     */
    const executeBlob = async (method, endpoint, data = null) => {
        if (msalError) {
            setError(msalError);
            return;
        }

        //if (result) {
        if (instance.getActiveAccount() !== null) {

            if(instance.getActiveAccount().idToken !== null) {

                try {
                    let response = null;

                    const headers = new Headers();
                    //const bearer = `Bearer ${result.accessToken}`;
                    const bearer = `Bearer ${instance.getActiveAccount().idToken}`;
                    headers.append("Authorization", bearer);

                    if (data) headers.append('Content-Type', 'application/json');

                    let options = {
                        method: method,
                        headers: headers,
                        body: data ? JSON.stringify(data) : null,
                    };
                    
                    setIsLoading(true);
                    response = (await fetch(endpoint, options));

                    if ((response.status === 200 || response.status === 201)) {
                        let responseData = response;

                        try {
                            responseData = await response.blob();
                        } catch (error) {
                            console.log(error);
                        } finally {
                            setData(URL.createObjectURL(responseData));
                            setIsLoading(false);
                            return responseData;
                        }
                    }

                    setIsLoading(false);
                    return response;
                } catch (e) {
                    setError(e);
                    setIsLoading(false);
                    throw e;
                }

            }
        }


    };

    /**
     * Execute a fetch request with the given options
     * @param {string} method: GET, POST, PUT, DELETE
     * @param {String} endpoint: The endpoint to call
     * @param {Object} data: The data to send to the endpoint, if any 
     * @returns JSON response
     */
    const executeOnAccessToken = async (method, endpoint, accessToken, data = null) => {
        if (msalError) {
            setError(msalError);
            return;
        }


        try {
            let response = null;

            const headers = new Headers();
            const bearer = `Bearer ${accessToken}`;
            headers.append("Authorization", bearer);
            if (data) headers.append('Content-Type', 'application/json');

            let options = {
                method: method,
                headers: headers,
                body: data ? JSON.stringify(data) : null,
            };
            
            setIsLoading(true);
            response = (await fetch(endpoint, options));

            if ((response.status === 200 || response.status === 201)) {
                let responseData = response;

                try {
                    responseData = await response.json();
                } catch (error) {
                    console.log(error);
                } finally {
                    setData(responseData);
                    setIsLoading(false);
                    return responseData;
                }
            }

            setIsLoading(false);
            return response;
        } catch (e) {
            setError(e);
            setIsLoading(false);
            throw e;
        }


    };

    return {
        isLoading,
        error,
        data,
        accessToken,
        // to avoid infinite calls when inside a `useEffect`
        execute: useCallback(execute, [result, msalError]),
        executeFormData: useCallback(executeFormData, [result, msalError]), 
        executeOnAccessToken: useCallback(executeOnAccessToken, [result, msalError]), 
        executeBlob: useCallback(executeBlob, [result, msalError]),
    };
};

export default useFetchWithMsal;